import React from "react";
import NavbarComp from "../../Component/Navbar/NavbarComp";
import FooterComp from "../../Component/Footer/FooterComp";
import Cartdetail from "../../Component/CartPage/Cartdetail";

const YourOrderScreen = () => {
  return (
    <div>
      <div className="navbar">
        <NavbarComp />
      </div>
      <div className="comp">
        <Cartdetail />
      </div>
      <div className="footer">
        <FooterComp />
      </div>
    </div>
  );
};

export default YourOrderScreen;
