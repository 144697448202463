import React from "react";
import "../../CSS/bg.css";
const Policy = () => {
  return (
    <div className="bg1 forscroll">
      <div
        style={{
          marginLeft: "30px",
          marginRight: "20opx",
          fontFamily: "Roboto",
          marginBottom: "40px",
        }}
      >
        <div>
          <h1 style={{ color: "#BAFF39" }}>Privacy Policy</h1>

          <div>
            <p style={{ color: "white" }}>
              At Ticket Wizard Inc. we are committed to protecting the privacy
              of personal information of current and potential patrons, donors,
              current and potential grantees, employees, volunteers and other
              individuals. Protecting your privacy and the confidentiality of
              your personal information is fundamental to our relationship with
              you
            </p>
            <p style={{ color: "white" }}>
              This privacy statement informs you of our policy on privacy, and
              tells you about the ways we ensure your privacy and the
              confidentiality of your personal information are protected.
            </p>
            <p style={{ color: "white" }}>
              Consistent with the Municipal Freedom of Information and Privacy
              Act ("MFIPA"), our privacy policy consists of the following key
              principles:
            </p>
          </div>

          <ol>
            <li style={{ color: "white" }}>
              <a href="#1" style={{ color: "#abe09f" }}>
                Collecting and using information
              </a>
            </li>
            <li style={{ color: "white" }}>
              <a href="#2" style={{ color: "#abe09f" }}>
                Releasing information
              </a>
            </li>
            <li style={{ color: "white" }}>
              <a href="#3" style={{ color: "#abe09f" }}>
                Protecting information
              </a>
            </li>
            <li style={{ color: "white" }}>
              <a href="#4" style={{ color: "#abe09f" }}>
                Providing information and Access and Accuracy{" "}
              </a>
            </li>
            <li style={{ color: "white" }}>
              <a href="#5" style={{ color: "#abe09f" }}>
                Logging Practices
              </a>
            </li>
            <li style={{ color: "white" }}>
              <a href="#6" style={{ color: "#abe09f" }}>
                Cookie Practices{" "}
              </a>
            </li>
            <li style={{ color: "white" }}>
              <a href="#7" style={{ color: "#abe09f" }}>
                Respecting and Responding to your privacy concern
              </a>
            </li>
          </ol>
          <h3 id="1" style={{ color: "#BAFF39" }}>
            1. Collecting and Using Information
          </h3>

          <p style={{ color: "white" }}>
            Personal information collected may include:
          </p>

          <ul style={{ color: "white" }}>
            <li style={{ color: "white" }}>billing purposes</li>
            <li style={{ color: "white" }}>to mail tickets</li>
            <li style={{ color: "white" }}>
              to understand the needs and desires of patrons
            </li>
            <li style={{ color: "white" }}>
              to inform patrons about schedule changes, event cancellations,
              upcoming events and special offers
            </li>
            <li style={{ color: "white" }}>donor recruitment</li>
          </ul>
          <p style={{ color: "white" }}>
            Ticket Wizard Inc. does not sell, trade, or rent any personal
            information. Information about your personal preferences is used to
            help us understand the programming and services that may be
            attractive to our patrons. From time to time the Ticket Wizard Inc.
            may send you information from other Arts organizations that we
            believe may be of interest to you.
          </p>
          <p style={{ color: "white" }}>
            In summary, your information is collected in order for us to provide
            you with the level of service you have come to expect from Ticket
            Wizard Inc.
          </p>

          <h3 id="2" style={{ color: "#BAFF39" }}>
            2. Releasing Information
          </h3>
          <p style={{ color: "white" }}>
            Only authorized personnel have access to this information.
          </p>
          <p style={{ color: "white" }}>Your information is only shared:</p>
          <ul style={{ color: "white" }}>
            <li style={{ color: "white" }}>when we have your consent</li>
            <li style={{ color: "white" }}>
              with our suppliers or agents who assist us in serving you
            </li>
            <li style={{ color: "white" }}>
              where we are required or permitted to do so by law
            </li>
          </ul>
          <p style={{ color: "white" }}>
            A privacy protection clause is included in all contracts with any
            third parties to ensure that they too provide the same level of
            protection for your information as does the Ticket Wizard Inc.
          </p>
          <h3 id="3" style={{ color: "#BAFF39" }}>
            3. Protecting Information
          </h3>
          <p style={{ color: "white" }}>
            We will protect your personal information with appropriate
            safeguards and security measures. We will retain your personal
            information only for the time it is required for the permitted
            purposes.
          </p>
          <p style={{ color: "white" }}>
            As stated, all of the persons to whom we may provide personal
            information (for example our Mailing House), as part of their
            contracts with Ticket Wizard Inc., are bound to maintain your
            confidentiality and may not use the personal information for any
            unauthorized purpose.
          </p>
          <p style={{ color: "white" }}>
            Only authorized individuals have access to the information provided
            by our customers. The length of time we retain personal information
            varies depending on the purpose for which it was collected and used.
            When your personal information is no longer needed we have
            procedures to either destroy, delete, erase or convert it to an
            anonymous form.
          </p>
          <h3 id="4" style={{ color: "#BAFF39" }}>
            4. Providing Information Accuracy
          </h3>
          <p style={{ color: "white" }}>
            We will make every reasonable effort to keep your information
            accurate and up-to-date.
          </p>
          <h3 id="5" style={{ color: "#BAFF39" }}>
            5. Logging Practices
          </h3>
          <p style={{ color: "white" }}>
            Ticket Wizard Inc. logs HTTP requests to their Web server. These
            logs record the IP address of site visitors. An IP address is the
            number automatically assigned to the computer or to the ISP
            (Internet Service Provider) requesting an URL.
          </p>
          <p style={{ color: "white" }}>
            Our logging is passive and we don't use technologies such as
            &quot;cookies&quot; or &quot;web bugs&quot; to maintain any
            information on site visitors. Logged information, also known as
            clickstream data, <a name="cookie"></a>is recorded in
            non-identifiable form and is used by those under contract for Web
            site system administration. Log files are archived and used for
            systems analysis, maintenance and site evaluation.
          </p>
          <h3 id="6" style={{ color: "#BAFF39" }}>
            6. Cookie Practices
          </h3>
          <p style={{ color: "white" }}>
            Some Ticket Wizard Inc. web pages use cookies for one of the
            following three practices:
          </p>
          <ul style={{ color: "white" }}>
            <li style={{ color: "white" }}>
              Single-session cookies, which log users over a single session and
              do not maintain data over multiple sessions or visits,
            </li>
            <li style={{ color: "white" }}>
              Multi-session cookies for use in web analytics, which log users
              over multiple sessions purely to gather data to analyze web
              traffic statistics, and
            </li>
            <li style={{ color: "white" }}>
              Multi-session cookies for use as persistent identifiers
              (persistent cookies), which log users activities over multiple
              visits with the intent of remembering data, settings, or
              preferences unique to that visitor
            </li>
          </ul>
          <p style={{ color: "white" }}>
            Ticket Wizard Inc's website uses persistent cookies to improve
            users' experience by remembering settings related to functionality.
            Other multi-session and single-session cookies are used in
            combination with the website's analytics software for website
            improvements.
          </p>
          <p style={{ color: "white" }}>
            You have the ability to accept or decline cookies. All Web browsers
            automatically accept cookies, but you can modify your browser
            settings to decline cookies if you prefer. By declining cookies, use
            of interactive features or online services that depend on cookies
            will not function as intended. If you choose to accept cookies, all
            browsers offer the ability to later delete cookies that you have
            accepted. If you choose to delete cookies, any settings and
            preferences controlled by those cookies will be deleted and may need
            to be automatically recreated upon a return visit.
          </p>
          <h3 id="7" style={{ color: "#BAFF39" }}>
            7. Respecting and Responding to Your Privacy Concerns
          </h3>
          <p style={{ color: "white" }}>
            We will investigate and respond to your concerns about any aspect of
            our handling of your information.
          </p>
          <p style={{ color: "white" }}>
            If you have any questions or concerns about privacy, would like to
            request access to personal information our company has about you, or
            if you believe that any personal information we have about you is
            inaccurate or out of date please contact our Privacy Officer. You
            may also request that your personal information, if any, be removed
            from our mailing list at any time by contacting us
          </p>
          <p style={{ color: "white" }}>
            We reserve the right to change this policy statement at any time by
            posting a new privacy statement at this location. Please be sure to
            visit this site often to see if anything has changed. Any changes to
            this privacy statement will be in compliance with applicable privacy
            laws.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Policy;
