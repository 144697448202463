import { Grid, Paper, autocompleteClasses } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import "./Product.css";

import { Link, useNavigate } from "react-router-dom";
import { GetShow } from "../../API/DrawModel";
import { UserContext } from "../../Context/UserContext";

import "../../CSS/bg.css";
import style from "../../CSS/New.module.css";
import FooterComp from "../Footer/FooterComp";
import { IMG_URL, formatISOToCustom, formatISOToCustom_WOTime } from "../../MIS/Global";
import LocationPage from "./LocationPage";
import AceProduct from "./AceProduct";

const NewtestProduct = () => {
  const navigate = useNavigate();
  const [draw, setDraw] = useState([]);
  const [footer, setFooter] = useState("footer");
  const { getticket, getticket_dispatch } = useContext(UserContext);
  const [locationname, setLocationName] = useState(true);
  const [hour, setHour] = useState(new Date().getHours());
  const [minute, setMinute] = useState(new Date().getMinutes());
  const [todaysExp, setTodayExp] = useState([]);
  let tabledata = {};

  const todaydatetime = () => {
    let month = new Date().getMonth() + 1;
    if (month.toString().length <= 1) {
      month = `0${month}`;
    }
    let day = new Date().getDate();
    if (day.toString().length <= 1) {
      day = `0${day}`;
    }
    let hours = new Date().getHours();
    // if (day.toString().length <= 1) {
    //   day = `0${day}`;
    // }
    let minutes = new Date().getMinutes();
    let year = new Date().getFullYear();
    // year = Number(year) - Number(e.target.value);
    let edate = `${year}-${month}-${day} ${hours}:${minutes}`;
    return edate;
  };
  const getData = async () => {
    let customwhr = "";
    customwhr += ` and sale_start < '${todaydatetime()}'`;
    customwhr += ` and sale_end > '${todaydatetime()}'`;
    customwhr += ` and isstatus='Posted'`;
    // alert(customwhr)
    tabledata = await GetShow({ customwhr: customwhr });
    // alert(JSON.stringify(tabledata))
    // console.log(tabledata.data);
    setDraw(tabledata.data);
    let todayexpArray = [];
    tabledata?.data.forEach((element) => {
      
      let endDate = formatISOToCustom_WOTime(element?.sale_end);
     
      // console.log(endDate)
      let curDate =formatISOToCustom_WOTime(new Date());
      // let curDate = new Intl.DateTimeFormat("en-US", {
      //   day: "2-digit",
      //   month: "2-digit",
      //   year: "numeric",
      // }).format(new Date());
      // console.log(curDate)
      if (endDate === curDate) {
        let endDateTime = formatISOToCustom(element?.sale_end);
        let hourMinute =
          new Date(endDateTime).getHours() +
          ":" +
          new Date(endDateTime).getMinutes();
        todayexpArray.push(hourMinute);
      }
    });
    let count = tabledata.data.length;
    setTodayExp(todayexpArray);
    if (count <= 5) {
      setFooter("footer");
    } else {
      setFooter("footer1");
    }
  };
  useEffect(() => {
    getData();
  }, []);
  const showdate = (showdate, type) => {
    let ret = "";
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const month_names_short = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    let weekday = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    switch (type) {
      case "mname_full":
        break;
      case "mname_short":
        let month = new Intl.DateTimeFormat("defualt", {
          month: "short",
        }).format(new Date(showdate));
        ret = month;
        break;
      case "dayname_short":
        // let dayname = new Date(showdate).getDay();
        // ret = weekday[dayname];
        let dayname = new Intl.DateTimeFormat("defualt", {
          weekday: "short",
        }).format(new Date(showdate));
        ret = dayname;
        break;
      case "dayname_full":
        break;
      case "day":
        let day = new Date(showdate).getDate();
        if (day.toString().length <= 1) {
          day = `0${day}`;
        }
        ret = day;
        break;
      case "time":
        // let hours = new Date(showdate).getHours();
        // let minutes = new Date(showdate).getMinutes();
        let newTime = new Date(showdate).toLocaleTimeString("en-US");
        let hour = newTime.split(":")[0];
        let amPm = newTime.split(" ")[1];
        let seconds = newTime.split(":")[2].replace(amPm, "");
        let noSeconds = newTime.replace(":" + seconds, " ");
        if (parseInt(hour) < 9) {
          noSeconds = "0" + noSeconds;
        }
        ret = noSeconds;
        break;
      case "year":
        let year = new Date(showdate).getFullYear();
        ret = year;
        break;

      default:
        break;
    }

    // if (day.toString().length <= 1) {
    //   day = `0${day}`;
    // }

    // setShowtime(`${year}-${month}-${day} ${hours}:${minutes}`);
    return ret;
  };
  const [imageBlobs, setImageBlobs] = useState([]);

  useEffect(() => {
    if (todaysExp.length <= 0) {
      return;
    }
    const interval = setInterval(() => {
      const now = new Date();
      if (minute !== now.getMinutes()) {
        setHour(now.getHours());
        setMinute(now.getMinutes());
        let curTime = now.getHours() + ":" + now.getMinutes();
        const filteredRows = todaysExp.filter((row) => {
          return row.includes(curTime.toLowerCase());
        });
        // console.log(todaysExp);
        // console.log(filteredRows);
        if (filteredRows.length > 0) {
          // console.log("yes");
          getData();
        }
      }
    }, 1000);
    return () => clearInterval(interval); // Cleanup the interval on component unmount
  }, [minute, todaysExp]);

  return (
    <>
      {locationname == false ? (
        <LocationPage setLocationName={setLocationName} />
      ) : (
        <Grid
          container
          spacing={2}
          style={{
            flex: 1,
            minHeight: "100%",
            alignItems: "center",
            justifyContent: "space-evenly",
            backgroundColor: "#6E6E6E",
          }}
        >
          <Grid item md={12} xs={12}>
            <div className={style.head}>Active Draws</div>
            <hr />
          </Grid>
          {draw?.map((row, index) => {
            let drawdet = row.showdetail[0];
            let month_name = showdate(formatISOToCustom(row?.show_time), "mname_short");
            // alert(month_name);
            let day_name = showdate(formatISOToCustom(row.show_time), "dayname_short");
            let day = showdate(formatISOToCustom(row.show_time), "day");
            let time = showdate(formatISOToCustom(row?.show_time), "time");

            let count = draw.length;
            let md = "containerfirst";
            let imgcon = "image-container";

            if (count <= 5) {
              {
                /* md = "containerfirst1"; */
              }
              md = "containerfirst";
              imgcon = "image-container";
            }

            let imageUrl = IMG_URL + row.drawimg;
            return (
              <Grid item md={2.1} xs={12}>
                <Paper elevation={2} style={{ backgroundColor: "#383838" }}>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <div className="image-container">
                      <img src={IMG_URL + row.drawimg} />
                    </div>
                    <div className="sub_con">
                      <span className="sub_contsp">{drawdet.showname}</span>
                      <span className="overfolwtxt"></span>
                    </div>
                    <div className="datecon">
                      <span>
                        {month_name.toUpperCase()} {day}{" "}
                        {day_name.toUpperCase()}
                      </span>
                      <span style={{ paddingLeft: "15px" }}>{time}</span>
                    </div>
                    <Link className="lnk" to="/cartdetail">
                      <div
                        style={{
                          height: "35px",
                          width: "100%",
                          backgroundColor: "#BAFF39",
                          color: "black",
                          justifyContent: "end",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          getticket_dispatch({ type: "add", data: row });
                          navigate("/cartdetail");
                        }}
                      >
                        GET TICKETS
                      </div>
                    </Link>
                  </div>
                </Paper>
              </Grid>
            );
          })}
        
        </Grid>
      )}
    </>
  );
};

export default NewtestProduct;
