import { Button, Grid, styled, TextField } from "@mui/material";
import { fontSize } from "@mui/system";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import {
  GetLogin,
  Getlogindata,
  GetUserLogData,
  Sendotp_email,
  updatepassword,
} from "../../API/LoginModel";
import { alphanumeric, genotp, onlynumeric, tostmsg } from "../../MIS/Global";
import AlertMessageComp from "../AlertMessageCom/AlertMessageComp";
import ReactDOMServer from "react-dom/server";
import TestEmail from "./TestEmail";
import { PswStore } from "../../Reducer/ForgotPswReducer";
import { UserContext } from "../../Context/UserContext";
import "./forgotpass.css";
import { Style } from "@mui/icons-material";
import style from "../../CSS/New.module.css";

const CssTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: "white",
  },
  "& label": {
    color: "white",
  },
  "& fontColor": {
    color: "white",
  },
  "& .MuiInput-underline:before": {
    borderBottomColor: "white",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "white",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "white",
    },
    "&:hover fieldset": {
      borderColor: "white",
    },
    "&.Mui-focused fieldset": {
      borderColor: "white",
    },
  },
});

const ForgotPassCom = () => {
  const { pswinputdisplya, setPswinputdisply } = useContext(UserContext);
  const navigate = useNavigate();

  const [emailid, setEmailid] = useState("");
  const [paswd, setPaswd] = useState("");
  const [cpasswrd, setCpasswrd] = useState("");
  const [otp, setOtp] = useState();

  const [alertdata, setAlertdata] = React.useState({});
  const [alertopen, setAlertopen] = React.useState(false);

  const [pswverifyotp, setPswverifyotp] = useState("");

  let data = {
    emailid: emailid,
    paswd: paswd,
    cpasswrd: cpasswrd,
    otp: otp,
  };

  useEffect(() => {}, [pswinputdisplya.verifyotp]);

  return (
    <>
      <AlertMessageComp
        type={alertdata.type}
        msg={alertdata.msg}
        vertical={alertdata.vertical}
        horizontal={alertdata.horizontal}
        setOpen={setAlertopen}
        open={alertopen}
      />
      <div className="forgothead forscroll" style={{ marginTop: "-40px" }}>
        <Grid item xs={12} md={12}>
          <div className={style.head}>Reset Password</div>
          <hr />
        </Grid>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Grid container spacing={1} md={4} className="footercopm">
            {/* <h1 style={{color:"#BAFF39"}}>Reset Password</h1> */}
            <Grid
              item
              xs={12}
              ms={12}
              sx={{
                display: "flex",
                justifyContent: "center",
                fontSize: 28,
                // marginTop: -10,
                marginBottom: -4,
              }}
            ></Grid>
            <Grid
              item
              xs={12}
              md={12}
              sx={{
                display: "flex",
                justifyContent: "center",
                fontSize: "18",
              }}
            >
              <span style={{ marginTop: "45px", color: "white" }}>
                {" "}
                Please enter your email address and we will send one-time password
              </span>
            </Grid>
            <Grid
              item
              xs={12}
              md={12}
              sx={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              {/* <div style={{width:'40%',}}> */}
              <CssTextField
                fullWidth
                id="standard-basic"
                label="Email"
                variant="standard"
                sx={{ margin: "10px", input: { color: "white" } }}
                onChange={(e) => {
                  setEmailid(e.target.value);
                }}
              />
              {/* </div> */}
            </Grid>
            <Grid
              item
              xs={12}
              md={12}
              sx={{
                display: pswinputdisplya.sendotp,
                justifyContent: "center",
              }}
            >
              {/* <div style={{width:'40%',alignItems:'center'}}> */}
              <button
                variant="contained"
                type="button"
                className="btns"
                style={{backgroundColor:"#BAFF39"}}
                onClick={() => {
                  (async () => {
                    try {
                      if (emailid.length <= 0) {
                        return { msg: "Enter Email", code: 100 };
                      }
                      const regex =
                        /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
                      if (regex.test(data.emailid) === false) {
                        return { msg: "Enter a valid email", code: 100 };
                      }
                      const emailduplicate = await GetUserLogData(data);
                      // alert(JSON.stringify(emailduplicate));
                      if (emailduplicate.code == 100) {
                        // alert()
                        let rettostmsg = tostmsg({
                          msg: "This Email is not registered",
                          code: 100,
                        });
                        setAlertdata(rettostmsg);
                        setAlertopen(rettostmsg.open);
                        return;
                      }
                      let eotp = genotp;
                      setPswverifyotp(eotp);
                      const htmlEmail = ReactDOMServer.renderToStaticMarkup(
                        <TestEmail type="" otp={eotp} />
                      );
                      // htmlEmail= htmlEmail.replace(/\\/g, "");
                      let edata = {
                        emailid: emailid,
                        paswd: paswd,
                        cpasswrd: cpasswrd,
                        htmlmsg: `${htmlEmail.toString()}`,
                      };

                      const login = await Sendotp_email(edata);
                      let rettostmsg = tostmsg(login);
                      setAlertdata(rettostmsg);
                      setAlertopen(rettostmsg.open);
                      if (login.code == 100) {
                      }

                      if (login.code == 200) {
                        setTimeout(() => {
                          PswStore(pswinputdisplya, { type: "sendotp" });
                          navigate("/forgotpass");
                        });
                      }
                      // handleSave(area);
                    } catch (e) {}
                  })();
                }}
              >
                Send
              </button>
              {/* </div> */}
            </Grid>
            <Grid
              item
              xs={12}
              md={12}
              sx={{
                display: pswinputdisplya.otp,
                justifyContent: "center",
              }}
            >
              {/* <div style={{width:'40%',}}> */}
              <CssTextField
                fullWidth
                id="standard-basic"
                label="OTP"
                variant="standard"
                sx={{
                  margin: "10px",
                  input: { color: "white" },
                  borderBottom: "1px solid white",
                }}
                inputProps={{ maxLength: 6 }}
                onChange={async (e) => {
                  let retdt = await onlynumeric(e);
                  setOtp(retdt);
                }}
              />
              {/* </div> */}
            </Grid>
            <Grid
              item
              xs={12}
              md={12}
              sx={{
                display: pswinputdisplya.verifyotp,
                justifyContent: "center",
                gap: "10px",
              }}
            >
              <Button
                variant="contained"
                type="button"
                style={{
                  borderRadius: "20px",
                  width: "200px",
                  justifyContent: "center",
                  marginBottom: "30px",
                  backgroundColor: "#BAFF39",
                  color: "black",
                }}
                onClick={() => {
                  (async () => {
                    try {
                      // alert(JSON.stringify(verifyotp.data))

                      if (pswverifyotp != otp) {
                        let rettostmsg = tostmsg({
                          msg: "Invalid OTP",
                          code: 100,
                        });
                        setAlertdata(rettostmsg);
                        setAlertopen(rettostmsg.open);
                        return;
                      } else {
                        setTimeout(() => {
                          PswStore(pswinputdisplya, { type: "verifyotp" });
                          navigate("/forgotpass");
                        });
                      }

                      // alert(JSON.stringify(emp));
                      // handleSave(area);
                    } catch (e) {}
                  })();
                }}
              >
                Verify OTP
              </Button>
              <Button
                variant="contained"
                type="button"
                style={{
                  borderRadius: "20px",
                  width: "200px",
                  justifyContent: "center",
                  marginBottom: "30px",
                  backgroundColor: "#BAFF39",
                  color: "black",
                }}
                onClick={() => {
                  (async () => {
                    try {
                      let eotp = genotp;
                      setPswverifyotp(eotp);
                      const htmlEmail = ReactDOMServer.renderToStaticMarkup(
                        <TestEmail type="" otp={eotp} />
                      );
                      // htmlEmail= htmlEmail.replace(/\\/g, "");
                      let edata = {
                        emailid: emailid,
                        paswd: paswd,
                        cpasswrd: cpasswrd,
                        htmlmsg: `${htmlEmail.toString()}`,
                      };

                      const login = await Sendotp_email(edata);
                      let rettostmsg = tostmsg(login);
                      setAlertdata(rettostmsg);
                      setAlertopen(rettostmsg.open);
                      if (login.code == 100) {
                      }

                      if (login.code == 200) {
                        setTimeout(() => {
                          // PswStore(pswinputdisplya,{type:"sendotp"})
                          // navigate("/forgotpass");
                        });
                      }
                      // handleSave(area);
                    } catch (e) {}
                  })();
                }}
              >
                Resend OTP
              </Button>
            </Grid>
            <Grid
              item
              xs={12}
              md={12}
              sx={{
                display: pswinputdisplya.paswd,
                justifyContent: "center",
              }}
            >
              {/* <div style={{width:'40%',}}> */}
              <CssTextField
                fullWidth
                id="standard-basic"
                label="New Password"
                type="password"
                variant="standard"
                sx={{ margin: "10px", input: { color: "white" } }}
                value={paswd}
                onChange={async (e) => {
                  let retdt = await alphanumeric(e);
                  setPaswd(retdt);
                }}
              />
              {/* </div> */}
            </Grid>
            <Grid
              item
              xs={12}
              md={12}
              sx={{
                display: pswinputdisplya.cpassword,
                justifyContent: "center",
              }}
            >
              {/* <div style={{width:'40%',}}> */}
              <CssTextField
                fullWidth
                id="standard-basic"
                type="password"
                label="Confirm Password"
                variant="standard"
                value={cpasswrd}
                sx={{ margin: "10px", input: { color: "white" } }}
                onChange={async (e) => {
                  let retdt = await alphanumeric(e);
                  setCpasswrd(retdt);
                }}
              />
              {/* </div> */}
            </Grid>
            <Grid
              item
              xs={12}
              md={12}
              sx={{
                display: pswinputdisplya.submit,
                justifyContent: "center",
              }}
            >
              <Button
                variant="contained"
                type="button"
                style={{
                  borderRadius: "20px",
                  width: "200px",
                  justifyContent: "center",
                  backgroundColor: "#BAFF39",
                  color: "black",
                  marginBottom: "10px",
                }}
                onClick={() => {
                  (async () => {
                    try {
                      const login = await updatepassword(data);
                      let rettostmsg = tostmsg(login);
                      setAlertdata(rettostmsg);
                      setAlertopen(rettostmsg.open);
                      if (login.code == 100) {
                      }

                      if (login.code == 200) {
                        // alert(login.msg);
                        setTimeout(() => {
                          navigate("/");
                        });
                      }
                      // alert(JSON.stringify(emp));
                      // handleSave(area);
                    } catch (e) {}
                  })();
                }}
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </div>
      </div>
    </>
  );
};

export default ForgotPassCom;
