import React from "react";
import NavbarComp from "../../Component/Navbar/NavbarComp";
import FooterComp from "../../Component/Footer/FooterComp";
import ContactusComp from "../../Component/ContactUs/ContactusComp";
import Cart from "../../Component/CartPage/Cart";
import CardDetail from "../../Component/CardDetail/CardDetail";
import NewtestProduct from "../../Component/Product/NewtestProduct";
import Aboutus from "../../Component/About Us/Aboutus";

const AboutUsScreen = () => {
  return (
    <div>
      <div className="navbar">
        <NavbarComp />
      </div>
      <div className="comp">
        <Aboutus />
      </div>

      <div className="footer">
        <FooterComp />
      </div>
    </div>
  );
};

export default AboutUsScreen;
