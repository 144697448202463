import React, { useContext, useEffect, useState } from "react";
import { Button, Grid } from "@mui/material";
import "./MyOrder.css";
import { UserContext } from "../../Context/UserContext";
import { GetOrder, Ticket_email } from "../../API/OrderModel";
import EmailMyorder from "../EmailMyorder/EmailMyorder";
import { useNavigate } from "react-router-dom";
import ReactDOM from "react-dom";
import Loader from "../loader/Loader";
import ReactDOMServer from "react-dom/server";
import AlertMessageComp from "../AlertMessageCom/AlertMessageComp";
import { formatDatetime_dmy, formatISOToCustom, getHourTime, tostmsg } from "../../MIS/Global";
import styled from "../../CSS/New.module.css";

const MyOrderHistory = () => {
  const [order, setOrder] = useState([]);
  const [hover, setHover] = useState("none");
  const [printdata, SetPrintdata] = useState({});
  const [alertdata, setAlertdata] = React.useState({});
  const [alertopen, setAlertopen] = React.useState(false);

  let tabledata = {};
  const navigate = useNavigate();
  const { logindata } = useContext(UserContext);
  let logid = sessionStorage.getItem("logid");
  // alert(JSON.stringify(logindata));
  if (logid.length <= 0) {
    window.location = "/";
  }
  const getdate = (eDate, istime) => {
    let month = new Date(eDate).getMonth() + 1;
    if (month.toString().length <= 1) {
      month = `0${month}`;
    }
    let day = new Date(eDate).getDate();
    if (day.toString().length <= 1) {
      day = `0${day}`;
    }
    let hours = new Date(eDate).getHours();
    // if (day.toString().length <= 1) {
    //   day = `0${day}`;
    // }
    let minutes = new Date(eDate).getMinutes();
    let year = new Date(eDate).getFullYear();
    // year = Number(year) - Number(e.target.value);
    let edate = `${day}/${month}/${year} ${hours}:${minutes}`;
    if (istime == 0) {
      edate = `${day}/${month}/${year}`;
    }

    return edate;
  };
  const ticketPrint = () => {
    var printContent = document.getElementById("printablediv");
    var windowUrl = "about:blank";
    var uniqueName = new Date();
    var windowName = "Print" + uniqueName.getTime();
    var params = [
      "height=" + window.innerHeight,
      "width=" + window.innerWidth,
      "fullscreen=yes", // only works in IE, but here for completeness
    ].join(",");
    var printWindow = window.open(
      windowUrl,
      windowName,
      params
      // 'width=300,height=400',
    );

    printWindow.document.write(printContent.innerHTML);
    printWindow.document.close();
    printWindow.focus();
    printWindow.print();
    printWindow.close();
    // window.close();
    //  document.body.innerHTML = originalContents;
    // navigate("/myorder");
  };
  const getshowtime = (eDate) => {
    const eDate1 = new Date(eDate); // Replace this with your date
    const day = String(eDate1.getDate()).padStart(2, '0');
    const month = String(eDate1.getMonth() + 1).padStart(2, '0');
    const year = eDate1.getFullYear();

    const formattedDate = `${day}/${month}/${year}`;
    let showdate = formattedDate;
    let showtome = new Intl.DateTimeFormat("defualt", {
      timeStyle: "short",
    }).format(new Date(eDate));
    return `${showdate} ${showtome}`;
  };
  const getData = async () => {
    let iscart = localStorage.getItem("iscart");
    let customwhr = "";
    if (iscart == null) {
      customwhr += ``;
    } else {
      customwhr += ` and iscart = '${iscart}'`;
    }
    customwhr += ` and uid = '${logid}'`;
    customwhr += " order by edate desc";
    tabledata = await GetOrder({ customwhr: customwhr });
    // alert(JSON.stringify(tabledata));
    if (tabledata.code == 300) {
      alert("Your order not found");
      navigate("/allproduct");
    } else {
      const sortedData = tabledata.data.sort((a, b) => b.orderid - a.orderid);
      setOrder(sortedData);
      
      SetPrintdata(tabledata.data[0]);
    }
  };
  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <div id="printablediv" style={{ display: "none" }}>
        <div
          style={{
            marginBottom: "10px",
            fontSize: "18px",
            fontWeight: "bold",
            backgroundColor: "04243f",
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div
            style={{
              backgroundColor: "#baff39",
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "10px",
              height: "20px",
            }}
          >
            <span style={{ fontSize: "20px" }}>AceChaser.com - A Division of Ticket Wizard</span>
            <p style={{ fontSize: "12px", display: "none" }}>
              PRESENT THIS TICKET AT THE EVENT ENTRANCE PRINTED OR ON YOUR
              MOBILE DEVICE
            </p>
          </div>
        </div>
        <span style={{ marginLeft: "10px" }}>
          Order No. #{printdata?.orderno}
        </span>
        <div
          style={{
            width: "100%",
            marginLeft: "10px",
            marginTop: "10px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            gap: "20%",
          }}
        >
          <div style={{ marginBottom: "10%" }}>
            <p>
              <span style={{ fontWeight: "bold" }}>Draw Committee Name:&nbsp;&nbsp;</span>{" "}
              <span>{printdata?.vendernm} </span>
            </p>
            <span style={{ fontWeight: "bold", marginTop: "-15px" }}>
              Draw Name:&nbsp;&nbsp;
            </span>
            <span>{printdata?.shownm}</span>
            <p>
              <span style={{ fontWeight: "bold" }}>Name:&nbsp;&nbsp;</span>
              <span>
                {printdata?.fname} {printdata?.lname}
              </span>
              <br />
              <span>
                {printdata?.address} <br />
              </span>
            </p>
          </div>
          <div style={{ marginRight: "15px", marginBottom: "5%" }}>
            <p>
              <span style={{ fontWeight: "bold" }}>Draw Date And Time:</span>
              <br />
              <span>
                   {/* formatDatetime_dmy(printdata?.showtime ? printdata?.showtime : new Date(),"dmy")  */}
                
                  {formatDatetime_dmy(printdata?.showtime ? printdata?.showtime : new Date(),"dd")}/{formatDatetime_dmy(printdata?.showtime ? printdata?.showtime : new Date(),"mm")}/{formatDatetime_dmy(printdata?.showtime ? printdata?.showtime : new Date(),"yyyy")}
                         &nbsp;&nbsp;{getHourTime(printdata?.showtime ? formatISOToCustom(printdata?.showtime) : new Date(),12)}
                        
                 
                <br />
                {/* <span style={{ fontWeight: "bold" }}>
                  {" "}
                  TICKET DETAILS:
                </span>{" "}
                <br />
                <span> General Seating</span>  */}
                <br />
                {/* - <br /> */}
                <span style={{ fontWeight: "bold" }}>Quantity And cost</span>
                <br />
                {printdata?.qty}-{printdata?.ticnm} - $
                {Number(printdata?.payment).toFixed(2)}
              </span>
            </p>
          </div>
          <div
            style={{
              height: "200px",
              width: "10%",
              marginTop: "10px",
              display: "none",
            }}
          ></div>
        </div>
        <div style={{ marginLeft: "10px", marginTop: "-05px" }}>
          <span style={{ fontSize: "12px", fontWeight: "bold" }}>
            Description:
          </span>
          <span style={{ fontSize: "12px" }}>
            {printdata?.desctext}
          </span>
        </div>
        <div style={{ marginLeft: "10px", }}>
          <span style={{ fontSize: "12px", fontWeight: "bold" }}>Rules:</span>
          <span style={{ fontSize: "12px" }}>
            All tickets must be purchased within the province of Prince Edward
            Island. When you purchase a ticket your IP address is recorded to be
            provided to the draw committee. In order to claim a cash prize, the
            winning ticket IP address will be validated
          </span>
        </div>
        
      </div>
      <AlertMessageComp
        type={alertdata.type}
        msg={alertdata.msg}
        vertical={alertdata.vertical}
        horizontal={alertdata.horizontal}
        setOpen={setAlertopen}
        open={alertopen}
      />
      <Grid
        container
        spacing={2}
        className="forscrolls"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "10px",
        }}
      >
        <Grid
          item
          md={12}
          xs={12}
          mt={-2}
          style={{ paddingLeft: "20px ", paddingTop: "20px" }}
        >
          <div className={styled.head}>Draw Ticket</div>
                 
          <hr style={{ width: "100%" }} />
        </Grid>

        {order.map((element) => {
          return (
            <Grid
              item
              md={8}
              xs={11}
              ml={1.5}
              className="ad click"
              marginTop={2}
              marginBottom={3}
              onMouseOver={() => {
                // alert();
                setHover("");
              }}
              onMouseOut={() => {
                setHover("none");
              }}
              mt={-0.5}
            >
              <div
                style={{
                  paddingBottom: "10px",
                  display: "flex",
                  justifyContent: "end",
                  float: "right",
                }}
              >
                <Button
                  className=""
                  size="medium"
                  variant="contained"
                  type="button"
                  onClick={() => {
                    SetPrintdata(element);
                    ReactDOM.render(
                      <Loader></Loader>,
                      document.getElementById("root1")
                    );
                    setTimeout(() => {
                      ticketPrint();
                      ReactDOM.render(
                        <Loader bal={false}></Loader>,
                        document.getElementById("root1")
                      );
                    }, 1000);
                  }}
                  style={{
                    marginRight: "10px",
                    width: "150px",
                    cursor: "pointer",
                    marginTop: "-10px",
                    backgroundColor: "#BAFF39",
                    color: "#000",
                    fontWeight: "500",
                    fontSize: "12px",
                  }}
                >
                  Print Tickets
                </Button>
                <Button
                  size="medium"
                  variant="contained"
                  style={{
                    float: "right",
                    width: "140px",
                    cursor: "pointer",
                    marginTop: "-10px",
                    backgroundColor: "#BAFF39",
                    color: "#000",
                    fontSize: "12px",
                    fontWeight: "500",
                  }}
                  type="button"
                  onClick={() => {
                    const htmlEmail = ReactDOMServer.renderToStaticMarkup(
                      <EmailMyorder data={element} />
                    );
                    let emaildata = {
                      // emailid: "noproblem.9981@gmail.com",
                      isprint:1,
                      emailid: logindata[0].emailid,
                      htmlmsg: `${htmlEmail.toString()}`,
                      orderno:element?.orderno,

                    };
                    const emailticket = Ticket_email(emaildata);
                    let rettostmsg = "";
                    if (emailticket.code == 100) {
                      rettostmsg = tostmsg({
                        msg: "Email sending failed. Please try again later.",
                        code: 100,
                      });
                    } else {
                      rettostmsg = tostmsg({
                        msg: `Ticket sent successfully to your registered email:${logindata[0]?.emailid}.`,
                        code: 200,
                      });
                    }
                    setAlertdata(rettostmsg);
                    setAlertopen(rettostmsg.open);
                  }}
                >
                  Email Tickets
                </Button>
              </div>
              <div
                className=""
                style={{
                  marginBottom: "10px",
                  fontSize: "18px",
                  fontWeight: "bold",
                  backgroundColor: "04243f",
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div className="ticketnav">
                  <span style={{ fontSize: "20px" }}>AceChaser.com - A Division of Ticket Wizard</span>
                  <p style={{ fontSize: "12px", display: "none" }}>
                    PRESENT THIS TICKET AT THE EVENT ENTRANCE PRINTED OR ON YOUR
                    MOBILE DEVICE
                  </p>
                </div>
              </div>
              <span style={{ marginLeft: "10px" }}>
                Order No. #{element.orderno}
              </span>
              <div
                className="ticketcon"
                style={{ marginLeft: "10px", marginTop: "-25px" }}
              >
                <div className="a1">
                  <p>
                    <span style={{ fontWeight: "bold" }}>
                      Draw Committee Name:&nbsp;&nbsp;
                    </span>
                    <span>{element.vendernm} </span>
                  </p>
                  <span style={{ fontWeight: "bold", marginTop: "-15px" }}>
                    Draw Name:&nbsp;&nbsp;
                  </span>
                  <span>{element.shownm}</span>
                  <p>
                    <span style={{ fontWeight: "bold" }}>Name:&nbsp;&nbsp;</span>
                    <span>
                      {element.fname} {element.lname}
                    </span>
                    <br />
                    <span>
                      {element.address} <br />
                    </span>
                  </p>
                </div>
                <div className="a1" style={{ marginRight: "15px" }}>
                  <p>
                    <span style={{ fontWeight: "bold" }}>
                      Draw Date And Time:
                    </span>
                    <br />
                    <span>
                      {" "}
                      {
                        `${formatDatetime_dmy(element.showtime,"dd")}/${formatDatetime_dmy(element.showtime,"mm")}/${formatDatetime_dmy(element.showtime,"yyyy")}
                         ${getHourTime(formatISOToCustom(element.showtime),12)}
                        `                        
                        }
                      <br />
                      <span style={{ fontWeight: "bold",display:"none" }}>
                        {" "}
                        TICKET DETAILS:
                      </span>{" "}
                      <br />
                      <span style={{display:"none"}}> General Seating</span> 
                      <br />
                      {/* - <br /> */}
                      <span style={{ fontWeight: "bold" }}>
                        Quantity And cost
                      </span>
                      <br />
                      {element.qty}-{element.ticnm} - $
                      {Number(element.payment).toFixed(2)}
                    </span>
                  </p>
                </div>
                <div
                  style={{
                    height: "200px",
                    width: "10%",
                    marginTop: "10px",
                    display: "none",
                  }}
                >
                  <span style={{ fontWeight: "bold" }}>
                    <img
                      className="mdimg"
                      style={{
                        height: "100%",
                        width: "100%",
                        ObjectFit: "cover",
                      }}
                      // src={barcode}
                      alt=""
                    />
                    <img
                      className="xsimg"
                      style={{
                        height: "60%",
                        width: "1000%",
                        ObjectFit: "cover",
                      }}
                      // src={barcode2}
                      alt=""
                    />
                  </span>
                </div>
              </div>
              <div style={{ marginLeft: "10px", marginTop: "-25px" }}>
                <span style={{ fontSize: "12px", fontWeight: "bold" }}>
                Rules:
                </span>
                <span style={{ fontSize: "12px" }}>
                  {element?.desctext}
                </span>
              </div>
              <div style={{ marginLeft: "10px" }}>
                <span style={{ fontSize: "12px", fontWeight: "bold",display:"none" }}>
                  Rules:
                </span>
                <span style={{ fontSize: "12px" }}>
                  All tickets must be purchased within the province of Prince
                  Edward Island. When you purchase a ticket your IP address is
                  recorded to be provided to the draw committee. In order to
                  claim a cash prize, the winning ticket IP address will be
                  validated
                </span>
              </div>
            </Grid>
          );
        })}
      </Grid>
    </>
  );
};

export default MyOrderHistory;
