import React from "react";
import NavbarComp from "../../Component/Navbar/NavbarComp";
import FooterComp from "../../Component/Footer/FooterComp";
import ContactusComp from "../../Component/ContactUs/ContactusComp";
import NewCheckout from "../../Component/CheckoutPage/NewCheckout";

const CheckoutPageScreen = () => {
  return (
    <div>
      <div className="navbar">
        <NavbarComp />
      </div>
      <div className="">
        <NewCheckout />
      </div>
      <div className="">
        <FooterComp />
      </div>
    </div>
  );
};

export default CheckoutPageScreen;
