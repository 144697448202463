import {
  Button,
  FormControlLabel,
  Grid,
  Paper,
  Radio,
  RadioGroup,
  TableContainer,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import "./CheckoutPage.css";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {
  alphanumeric,
  email_send,
  onlynumeric,
  tostmsg,
} from "../../MIS/Global";
import { Saveorder, savePayOrder } from "../../API/CheckoutModel";
import { useLocation, useNavigate } from "react-router-dom";
import AlertMessageComp from "../AlertMessageCom/AlertMessageComp";
import { UserContext } from "../../Context/UserContext";
import axios from "axios";
import ReactDOMServer from "react-dom/server";
import { Ticket_email } from "../../API/OrderModel";
import EmailMyorder from "../EmailMyorder/EmailMyorder";
import { calcCountAmount, DeleteCart, GetCart } from "../../API/CartModel";
import CartGrid from "../CartPage/CartGrid";
import { nanoid } from "nanoid";
import "../../CSS/bg.css";
// import "../../Component/CartPage/Cart.css";
import styled from "../../CSS/New.module.css";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { makeStyles } from "@material-ui/core";
import cardlogo from "../../Assets/Minimal-Credit-Card-Icons.jpg";
import ConfirmationDialog from "./ConfirmationDialog";

const NewCheckout = () => {
  const useStyles = makeStyles((theme) => ({
    smallDateTimePicker: {
      "& .MuiInputBase-input": {
        fontSize: "16px",
        fontWeight: "400 !important",
        width: "100%",
        height: "100%",
        padding: "10px",
        margin: 0,
      },
      "& .MuiInputLabel-root": {
        fontSize: "16px",
        fontWeight: "400 !important", // Adjust font size as needed
        display: "flex",
        alignItems: "center",
      },
    },
  }));
  const classes = useStyles();

  const [orderid, setOrderid] = useState(0);
  const [uidid, setUidid] = useState(0);
  const [edate, setEdate] = useState("");
  const [trstatus, setTrstatus] = useState("");
  const [trid, setTrid] = useState("");
  const [payment, setPayment] = useState("");
  const [showid, setShowid] = useState("");
  const [cardno, setCardno] = useState("");
  const [date, setDate] = useState("");
  const [ccv, setCcv] = useState("");
  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [country, setCountry] = useState("Canada");
  const [autocountry, setAutocountry] = useState("");
  const [pincode, setPincode] = useState("");
  const [phno, setPhno] = useState("");
  const [email, setEmail] = useState("");
  const [ip, setIP] = useState("");
  const [orderno, setOrderno] = useState("");
  const [totsub, setTotsub] = useState("0.00");
  const [totconvfee, setTotconvfee] = useState("0.00");
  const [totgrand, setTotgrand] = useState("0.00");
  const [isdrcr, setIsdrcr] = useState("-1");
  const [expdate, setExpdate] = useState("");

  const unqtrid = nanoid();
  const location = useLocation();
  const from = location?.state;
  const navigate = useNavigate();
  let genotp =
    Math.floor(Math.random() * (9 * Math.pow(10, 6 - 1))) + Math.pow(10, 6 - 1);
  const same_orderno =
    Math.floor(Math.random() * (9 * Math.pow(10, 6 - 1))) + Math.pow(10, 6 - 1);

  const {
    getticket,
    getticket_dispatch,
    getorder,
    setorder_dispatch,
    getcheckout,
    setcheckout_dispatch,
    logindata,
    stopTimer,
  } = useContext(UserContext);
  // alert(JSON.stringify(logindata));

  // alert(JSON.stringify(getticket))
  // alert(JSON.stringify(getorder))

  const [alertdata, setAlertdata] = React.useState({});
  const [alertopen, setAlertopen] = React.useState(false);

  const [province, setProvince] = useState([]);
  const [totdet, setTotdet] = useState();
  const [cdg, setCdg] = useState({open:false,msg:""});
  const [month, setMonth] = useState("");
  const [year, setYear] = useState("");
  const [yearid, setYearId] = useState("");
  const date1 = [
    { label: "Alberta", id: "Alberta" },
    { label: "British Columbia", id: "British Columbia" },
    { label: "Manitoba", id: "Manitoba" },
    { label: "New Brunswick", id: "New Brunswick" },
    { label: "Newfoundland and Labrador", id: "Newfoundland and Labrador" },
    { label: "Northwest Territories", id: "Northwest Territories" },
    { label: "Nova Scotia", id: "Nova Scotia" },
    { label: "Nunavut", id: "Nunavut" },
    { label: "Ontario", id: "Ontario" },
    { label: "Prince Edward Island", id: "Prince Edward Island" },
    { label: "Quebec", id: "Quebec" },
    { label: "Saskatchewan", id: "Saskatchewan" },
    { label: "Yukon", id: "Yukon" },
  ];
let monthList=[
  { label: "01", id: "01" },{ label: "02", id: "02" },{ label: "03", id: "03" },
  { label: "04", id: "04" },{ label: "05", id: "05" },{ label: "06", id: "06" },
  { label: "07", id: "07" },{ label: "08", id: "08" },{ label: "09", id: "09" },
  { label: "10", id: "10" },{ label: "11", id: "11" },{ label: "12", id: "12" },
]
const yearList = [
  { label: "2024", id: "24" },{ label: "2025", id: "25" },{ label: "2026", id: "26" },{ label: "2027", id: "27" },
  { label: "2028", id: "28" },{ label: "2029", id: "29" },{ label: "2030", id: "30" },
  { label: "2031", id: "31" },{ label: "2032", id: "32" },{ label: "2033", id: "33" },
  { label: "2034", id: "34" },{ label: "2035", id: "35" },{ label: "2036", id: "36" },
  { label: "2037", id: "37" },{ label: "2038", id: "38" },{ label: "2039", id: "39" },
  { label: "2040", id: "40" },{ label: "2041", id: "41" },{ label: "2042", id: "42" },
  { label: "2043", id: "43" },{ label: "2044", id: "44" },{ label: "2045", id: "45" },
  { label: "2046", id: "46" },{ label: "2047", id: "47" },{ label: "2048", id: "48" },
  { label: "2049", id: "49" },{ label: "2050", id: "50" }
];

  const date2 = [{ label: "Canada", id: "Canada" }];
  let logid = sessionStorage.getItem("logid");
  let tabledata = {};
  const getOData = async () => {
    let customwhr = "";
    tabledata = await GetCart({ uid: logid });
    if (tabledata.code == 300) {
      window.location = "/";
    }
    // alert(JSON.stringify(tabledata.data))
    // if (getticket.length <= 0) {
    //   window.location = "/";
    // }
    let subTot = 0;
    let subConvfee = 0;
    let subGrandTot = 0;
    tabledata.data.forEach((element) => {
      subTot = Number(subTot) + Number(element.orderdata?.subamount);
      subConvfee = Number(subConvfee) + Number(element.orderdata?.convfee);
      subGrandTot = Number(subGrandTot) + Number(element.orderdata?.gtotal);
    });
    // alert(subTot)
    setTotsub(subTot);
    setTotconvfee(subConvfee);
    setTotgrand(subGrandTot);
    setTotdet({
      subTot: subTot,
      subConvfee: subConvfee,
      subGrandTot: subGrandTot,
    });
    setorder_dispatch({
      type: "addArray",
      data: tabledata.data,
    });
  };
  useEffect(() => {
    getOData();
  }, []);

  const makeSaveData = (element) => {
    // alert(JSON.stringify(element));
    return {
      // orderid: orderid,
      uid: logid, //logindata[0].logid,
      edate: new Date(),
      trstatus: 0,
      trid: 0,
      payment: element.orderdata?.gtotal, // getorder[0].gtotal,
      showid: element.orderdata?.showid, // getticket[0].showid,
      cardno: cardno,
      date: date,
      ccv: ccv,
      fname: fname,
      lname: lname,
      address: address,
      city: city,
      state: state,
      country: country,
      pincode: pincode,
      phno: phno,
      email: email,
      ip: ip,
      shownm: element.orderdata?.shownm, // getticket[0].showdetail[0].showname,
      vendernm: element.orderdata?.vendernm, //getticket[0].showdetail[0].vendrname,
      eventnm: element.orderdata?.eventnm, //getticket[0].showdetail[0].eventname,
      venunm: element.orderdata?.venunm, //getticket[0].showdetail[0].venuname,
      showtime: element.orderdata?.showtime,
      orderno: same_orderno,
      conveniencefee: element.orderdata?.convfee, //getorder[0].convfee,
      price: element.orderdata?.price, //getorder[0].price,
      qty: element.orderdata?.qty, //getorder[0].qty,
      sale_end: element.orderdata?.showtime,
      iscart: unqtrid,
      ticnm: element.orderdata?.ticketname,
      autocountry: autocountry,
      desctext: element.orderdata?.desctext,
    };
  };

  //creating IP state

  //creating function to load ip address from the API
  const getData = async () => {
    const res = await axios.get("https://geolocation-db.com/json/");
    //  alert(JSON.stringify(res.data));
    setIP(res.data.IPv4);
    setAutocountry(res.data.country_name);
  };

  useEffect(() => {
    //passing getData method to the lifecycle method
    getData();
  }, []);
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth' // Optional: animated smooth scroll
    });
  }
  return (
    <>
      <AlertMessageComp
        type={alertdata.type}
        msg={alertdata.msg}
        vertical={alertdata.vertical}
        horizontal={alertdata.horizontal}
        setOpen={setAlertopen}
        open={alertopen}
      />
      <Grid container spacing={2} mt={1} className="forscroll">
        <Grid item md={4.5} xs={12} ml={1}>
          <div className={styled.head}>Checkout</div>
        </Grid>
        <Grid item md={7.4} xs={12}>
          <div className="row1">
            <span style={{ color: "#BAFF39" }} className="spanalign">
              Payment Details
            </span>
            <button
              type="button"
              className="btns"
              style={{ backgroundColor: "#BAFF39", cursor: "pointer" }}
              onClick={() => {
                navigate("/yourorder");
              }}
            >
              Back To Your Cart
            </button>
          </div>
        </Grid>
        <div style={{ width: "100%" }}>
          <hr />
        </div>
        <Grid container spacing={2} item md={5.5} xs={11} mt={1}>
          <Grid item md={12} xs={12} mt={-3} ml={2}>
            <Paper elevation={4}>
              <TableContainer sx={{ maxHeight: "100%" }}>
                <CartGrid data={getorder} totdet={totdet}></CartGrid>
              </TableContainer>
            </Paper>
          </Grid>
        </Grid>

        <Grid container spacing={2} item md={5.8} xs={12} m={1} pb={2}>
          <Grid item md={12} xs={12} className="ad" mt={-1.5}>
            <span
              style={{
                display: "block",
                marginBottom: "10px",
                fontSize: "18px",
                fontWeight: "bold",
              }}
            >
              Credit/Debit Card
            </span>
            <div>
              <img src={cardlogo} style={{width:'10%',height:"40px",objectFit:"contain"}} />
            </div>
            <div>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
              >
              <FormControlLabel
                  value="1"
                  control={
                    <Radio
                      onChange={() => {
                        setIsdrcr(1);
                      }}
                    />
                  }
                  label="Credit"
                />
                <FormControlLabel
                  value="0"
                  control={
                    <Radio
                      onChange={() => {
                        setIsdrcr(0);
                      }}
                    />
                  }
                  label="Debit"
                />
                
              </RadioGroup>
            </div>
            <TextField
              size="small"
              id="outlined-case no-input"
              fullWidth
              label="Enter Card Number"
              className="textfields"
              style={{ marginBottom: "10px", display: "block" }}
              value={cardno}
              inputProps={{ maxLength: 16 }}
              onChange={async (e) => {
                let retdt = await onlynumeric(e);
                setCardno(retdt);
              }}
            />         

            {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label={"Expiry Date"}
                views={["month", "year"]}
                className={classes.smallDateTimePicker}
                // defaultValue={dayjs()}
                // minDate={dayjs()}
                onChange={(e) => {
                  const selectedDayjs = dayjs(e);
                  setExpdate(
                    selectedDayjs.format("MM") +
                      "" +
                      selectedDayjs.format("YYYY").slice(-2)
                  );
                }}
              />
            </LocalizationProvider> */}
            <div style={{display:"flex",width:"100%",gap:"10px",marginBottom: "10px",}}>

            
            <Autocomplete
              disablePortal
              id="month"
              size="small"
              value={month}
              options={monthList}             
              onChange={(e, newValue) => setMonth(newValue?newValue.label:'')}
              renderInput={(params) => (
                <TextField {...params} label="Month" />
              )}
              sx={{width:"30%"}}
            />
             <Autocomplete
              disablePortal
              id="year"
              size="small"
              value={year}
              options={yearList}       

              onChange={(e, newValue) => {setYear(newValue?newValue.label:'');setYearId(newValue?newValue.id:'')}}
              renderInput={(params) => (
                <TextField {...params} label="Year" />
              )}
              sx={{width:"30%"}}
              
            />
            
            <TextField
              size="small"
              id="outlined-case no-input"
              fullWidth
              label="CVV"
              style={{
                width: "36%",
                marginBottom: "10px",
                marginLeft: "10px",
              }}
              value={ccv}
              inputProps={{ maxLength: 3 }}
              onChange={async (e) => {
                let retdt = await onlynumeric(e);
                setCcv(retdt);
              }}
            />
            </div>
          </Grid>
          <Grid item md={12} className="adc" marginTop={2}>
            {/* <Grid item xs={12} md={12}> */}
            <span
              style={{
                display: "block",
                marginBottom: "10px",
                fontSize: "18px",
                fontWeight: "bold",
              }}
            >
              Cardholders Billing Address
            </span>
            {/* </Grid> */}
            {/* <Grid item xs={6} md={6}> */}
            <TextField
              size="small"
              id="outlined-case no-input"
              fullWidth
              label="First Name"
              style={{
                width: "48%",
                marginBottom: "10px",
                marginRight: "10px",
              }}
              value={fname}
              inputProps={{ maxLength: 20 }}
              onChange={async (e) => {
                let retdt = await alphanumeric(e);
                setFname(retdt);
              }}
            />
            {/* </Grid> */}
            {/* <Grid item xs={6} md={6}> */}
            <TextField
              size="small"
              id="outlined-case no-input"
              fullWidth
              label="Last Name"
              style={{ width: "48%", marginBottom: "10px" }}
              value={lname}
              inputProps={{ width: "48%", maxLength: 20 }}
              onChange={async (e) => {
                let retdt = await alphanumeric(e);
                setLname(retdt);
              }}
            />
            {/* </Grid> */}
            <TextField
              size="small"
              id="outlined-case no-input"
              fullWidth
              label="Address"
              style={{
                width: "48%",
                marginBottom: "10px",
                marginRight: "10px",
              }}
              value={address}
              onChange={async (e) => {
                let retdt = await alphanumeric(e);
                setAddress(retdt);
              }}
            />
            <TextField
              size="small"
              id="outlined-case no-input"
              fullWidth
              label="City"
              style={{ width: "48%", marginBottom: "10px" }}
              value={city}
              inputProps={{ maxLength: 20 }}
              onChange={async (e) => {
                let retdt = await alphanumeric(e);
                setCity(retdt);
              }}
            />

            <Autocomplete
              disablePortal
              id="combo-box-demo"
              size="small"
              value={state}
              options={date1}
              sx={{
                width: "98%",
                outline: "white",
                marginBottom: "10px",
                // marginRight: "10px",
                display: "flex",
              }}
              onChange={(e, newValue) => setState(newValue.label)}
              renderInput={(params) => (
                <TextField {...params} label="Province" />
              )}
            />
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              size="small"
              value={country}
              options={date2}
              sx={{
                width: "98%",
                outline: "white",
                display: "flex",
                marginBottom: "10px",
              }}
              onChange={(e, newValue) => setCountry(newValue.label)}
              renderInput={(params) => (
                <TextField {...params} label="Country" />
              )}
            />
            <TextField
              size="small"
              id="outlined-case no-input"
              fullWidth
              label="Postal Code"
              style={{
                width: "48%",
                marginBottom: "10px",
                marginRight: "10px",
              }}
              value={pincode}
              inputProps={{ maxLength: 7 }}
              onChange={async (e) => {
                let retdt = await alphanumeric(e);
                setPincode(retdt);
              }}
            />
            <TextField
              size="small"
              id="outlined-case no-input"
              fullWidth
              label="Phone"
              style={{ width: "48%", marginBottom: "10px" }}
              value={phno}
              inputProps={{ maxLength: 10 }}
              onChange={async (e) => {
                let retdt = await onlynumeric(e);
                setPhno(retdt);
              }}
            />
            <TextField
              size="small"
              id="outlined-case no-input"
              fullWidth
              label="Email"
              style={{ width: "49%", marginBottom: "10px" }}
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />

            <button
              style={{ fontWeight: "bold", backgroundColor: "#BAFF39" }}
              className=" bt btns"
              onClick={() => {
                (async () => {
                  try {
                    let rettostmsg1 = "";
                    if (isdrcr == "-1") {                      
                      rettostmsg1 = tostmsg({
                        msg: "Please Select Card Type",
                        code: 100,
                      });
                      setAlertdata(rettostmsg1);
                      setAlertopen(rettostmsg1.open);
                      return;
                    }
                    if (cardno.length < 16) {
                      rettostmsg1 = tostmsg({
                        msg: "Please Enter Valid Card Number",
                        code: 100,
                      });
                      setAlertdata(rettostmsg1);
                      setAlertopen(rettostmsg1.open);
                      return;
                    }
                    if (month == "" || month == undefined) {
                      rettostmsg1 = tostmsg({
                        msg: "Please Enter Expiry Month & Year of Card",
                        code: 100,
                      });
                      setAlertdata(rettostmsg1);
                      setAlertopen(rettostmsg1.open);
                      return;
                    }
                    if (year == "" || year == undefined) {
                      rettostmsg1 = tostmsg({
                        msg: "Please Enter Expiry Month & Year of Card",
                        code: 100,
                      });
                      setAlertdata(rettostmsg1);
                      setAlertopen(rettostmsg1.open);
                      return;
                    }
                    if (ccv.length < 3) {
                      rettostmsg1 = tostmsg({
                        msg: "Please Enter Valid Card Verification Value (cvv)",
                        code: 100,
                      });
                      setAlertdata(rettostmsg1);
                      setAlertopen(rettostmsg1.open);
                      return;
                    }

                    if (fname.length <= 0) {
                      rettostmsg1 = tostmsg({
                        msg: "Please Enter First Name",
                        code: 100,
                      });
                      setAlertdata(rettostmsg1);
                      setAlertopen(rettostmsg1.open);
                      return;
                    }
                    if (lname.length <= 0) {
                      rettostmsg1 = tostmsg({
                        msg: "Please Enter Last Name",
                        code: 100,
                      });
                      setAlertdata(rettostmsg1);
                      setAlertopen(rettostmsg1.open);
                      return;
                    }
                    if (state.length <= 0) {
                      rettostmsg1 = tostmsg({
                        msg: "Please Enter Provience",
                        code: 100,
                      });
                      setAlertdata(rettostmsg1);
                      setAlertopen(rettostmsg1.open);
                      return;
                    }
                    if (country.length <= 0) {
                      rettostmsg1 = tostmsg({
                        msg: "Please Enter Country",
                        code: 100,
                      });
                      setAlertdata(rettostmsg1);
                      setAlertopen(rettostmsg1.open);
                      return;
                    }
                    if (email.length > 0) {
                      var emailregex = /\S+@\S+\.\S+/;
                      if (!emailregex.test(email)) {
                        rettostmsg1 = tostmsg({
                          msg: "Please Enter Valid Email Id",
                          code: 100,
                        });
                        setAlertdata(rettostmsg1);
                        setAlertopen(rettostmsg1.open);
                        return;
                      }
                    }
                    scrollToTop();
                    let payOrderData = {
                      isdrcr: isdrcr,
                      cdno: cardno,
                      expdate: month+""+yearid,
                      amount: Number(totgrand).toFixed(2),
                      cvv: ccv,
                      fname: fname,
                      lname: lname,
                    };

                    let payOrder = await savePayOrder(payOrderData);
                    // alert(JSON.stringify(payOrder))
                    if (payOrder?.code === 200) {
                      if (
                        payOrder?.data?.txn?.ssl_token_response !== "SUCCESS" ||
                        payOrder?.data?.txn?.ssl_token_response == undefined
                      ) {
                        setCdg({open:true,msg:"Payment transaction failed.Please try again!"});
                        return;
                        // let rettostmsg = tostmsg({
                        //   code: 100,
                        //   msg: "Payment transaction failed.Please try again",
                        // });
                        // setAlertdata(rettostmsg);
                        // setAlertopen(rettostmsg.open);
                      } else {
                        let Checkout = "";
                        for (const element of getorder) {
                          // alert(JSON.stringify(element))
                          let data = makeSaveData(element);
                          // alert(JSON.stringify(data))
                          Checkout = await Saveorder(data);
                          
                          const htmlEmail = ReactDOMServer.renderToStaticMarkup(
                            <EmailMyorder data={data} />
                          );
                          let emaildata = {
                            // emailid: email_send,
                            // emailid:"durgaprasadkadam@gmail.com",
                            isprint:0,
                            emailid: localStorage.getItem("lemail"),
                            htmlmsg: `${htmlEmail.toString()}`,
                            orderno:same_orderno,
                          };
                          const emailticket = Ticket_email(emaildata);
                          setcheckout_dispatch({ type: "add", data: data });
                        }
                        let rettostmsg = tostmsg(Checkout);
                        // setAlertdata(rettostmsg);
                        // setAlertopen(rettostmsg.open);
                        if (Checkout.code == 100) {
                        }
                        if (Checkout.code == 200) {
                          setCdg({open:true,msg:"Payment transaction saved successfully"});
                          // alert(login.msg);
                          setTimeout(() => {
                            // navigate("/myorder");
                            stopTimer();
                            localStorage.setItem("lastmin", 0);
                            localStorage.setItem("lastsec", 1);
                            localStorage.removeItem("lasttot");
                            localStorage.removeItem("lastcnt");
                            DeleteCart({ uid: logid });
                            calcCountAmount("cls", 0, 0);
                            localStorage.removeItem("iscart");

                            //  localStorage.setItem("iscart", unqtrid);

                            navigate("/myorderhistory");
                          }, 1000);
                        }
                      }
                      
                    } else {
                      setCdg({open:true,msg:"Payment transaction failed.Please try again!"});
                      return;
                      // let rettostmsg = tostmsg({
                      //   code: 100,
                      //   msg: "Payment transaction failed.Please try again",
                      // });
                      // setAlertdata(rettostmsg);
                      // setAlertopen(rettostmsg.open);
                    }
                  } catch (e) {}
                })();
              }}
            >
              Pay ${Number(totgrand).toFixed(2)}
            </button>
          </Grid>
        </Grid>
      </Grid>
      <ConfirmationDialog cdg={cdg} setCdg={setCdg} />
    </>
  );
};

export default NewCheckout;
