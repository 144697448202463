import React from "react";
import NavbarComp from "../../Component/Navbar/NavbarComp";
import ProductComp from "../../Component/Product/ProductComp";
import FooterComp from "../../Component/Footer/FooterComp";

const ProductScreen = () => {
  return (
    <div>
      <div className="navbar">
        <NavbarComp />
      </div>
      <div className="comp">
        <ProductComp />
      </div>
      <div className="footer">
        <FooterComp />
      </div>
    </div>
  );
};

export default ProductScreen;
