import React from "react";
import NavbarComp from "../../Component/Navbar/NavbarComp";
import FooterComp from "../../Component/Footer/FooterComp";
import ContactusComp from "../../Component/ContactUs/ContactusComp";
import Home from "../../Component/Home/Home";
import ProductComp from "../../Component/Product/ProductComp";
import "../../CSS/Base.css";

const HomeScreen = () => {
  return (
    <div>
      <div className="navbar">
        <NavbarComp />
      </div>
      <div className="comp">
        <Home />
      </div>
      <div className="footer">
        <FooterComp />
      </div>
    </div>
  );
};

export default HomeScreen;
