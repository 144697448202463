import {
  Box,
  Button,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import AlertMessageComp from "../AlertMessageCom/AlertMessageComp";
import { calcCountAmount, deletecart_Cartid } from "../../API/CartModel";
import { tostmsg } from "../../MIS/Global";
const CartGrid = (props) => {
  // alert(JSON.stringify(props.totdet));
  const [alertdata, setAlertdata] = React.useState({});
  const [alertopen, setAlertopen] = React.useState(false);
  const [deldisplay, setDesldispay] = useState("");
  useEffect(() => {
    let pathname = window.location.pathname;
    if (pathname != "/yourorder") {
      setDesldispay("none");
    }
    // alert(JSON.stringify(props));
  }, []);

  return (
    <>
      <AlertMessageComp
        type={alertdata.type}
        msg={alertdata.msg}
        vertical={alertdata.vertical}
        horizontal={alertdata.horizontal}
        setOpen={setAlertopen}
        open={alertopen}
      />
      <TableContainer
        className="tblcontainer forscroll"
        sx={{ maxHeight: "50vh", maxWidth: "100vw" }}
      >
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell
                sx={{
                  fontWeight: "bold",
                  backgroundColor: "#383838",
                  color: "white",
                }}
              >
                TICKET DETAILS &nbsp;&nbsp;
              </TableCell>
              <TableCell
                sx={{
                  fontWeight: "bold",
                  backgroundColor: "#383838",
                  color: "white",
                }}
              >
                QUANTITY
              </TableCell>
              <TableCell
                sx={{
                  fontWeight: "bold",
                  backgroundColor: "#383838",
                  color: "white",
                }}
              >
                PACKAGE
              </TableCell>
              <TableCell
                sx={{
                  fontWeight: "bold",
                  textAlign: "right",
                  backgroundColor: "#383838",
                  color: "white",
                }}
              >
                PRICE
              </TableCell>
              <TableCell
                sx={{
                  fontWeight: "bold",
                  textAlign: "left",
                  backgroundColor: "#383838",
                  color: "white",
                }}
              >
                TOTAL PRICE &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;
              </TableCell>

              <TableCell
                sx={{
                  fontWeight: "bold",
                  textAlign: "right",
                  display: `${deldisplay}`,
                  backgroundColor: "#383838",
                  color: "white",
                }}
              ></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.data?.map((element) => {
              return (
                <TableRow>
                  <TableCell>
                    <span style={{ fontWeight: "bold" }}>
                      {element.orderdata?.shownm}{" "}
                    </span>
                    {element.orderdata?.vendernm}, {element.orderdata?.venunm}
                  </TableCell>
                  <TableCell>{element.orderdata?.qty}</TableCell>
                  <TableCell>{element.orderdata?.ticketname}</TableCell>

                  <TableCell sx={{ textAlign: "right" }}>
                    ${Number(element.orderdata?.price).toFixed(2)}
                  </TableCell>
                  <TableCell sx={{ textAlign: "right" }}>
                    $
                    {Number(
                      element.orderdata?.qty * element.orderdata?.price
                    ).toFixed(2)}
                  </TableCell>
                  <TableCell
                    style={{ cursor: "pointer", display: `${deldisplay}` }}
                  >
                    <DeleteIcon
                      onClick={() => {
                        let amt = Number(
                          element.orderdata?.qty * element.orderdata?.price
                        ).toFixed(2);
                        deletecart_Cartid({ cartid: element.cartid });
                        let rettostmsg1 = tostmsg({
                          msg: "Delete order from cart sucessfully",
                          code: 200,
                        });
                        setAlertdata(rettostmsg1);
                        setAlertopen(rettostmsg1.open);
                        calcCountAmount("sub", 1, amt);
                        setTimeout(() => {
                          window.location.href = "/yourorder";
                        }, 1000);
                      }}
                    ></DeleteIcon>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
          <TableFooter
            sx={{ bottom: 0, position: "sticky", backgroundColor: "#fff" }}
          >
            <TableRow>
              <TableCell
                sx={{
                  // backgroundColor: "#fff",
                  fontWeight: "bold",
                  color: "#000",
                }}
              ></TableCell>
              <TableCell
                sx={{
                  // backgroundColor: "#fff",
                  fontWeight: "bold",
                  color: "#000",
                }}
              ></TableCell>
              <TableCell
                sx={{
                  // backgroundColor: "#fff",
                  fontWeight: "bold",
                  color: "#000",
                }}
              ></TableCell>
              <TableCell
                sx={{
                  // backgroundColor: "#fff",
                  fontWeight: "bold",
                  color: "#000",
                  fontSize: "16px",                  
                  display:"flex",
                  alignItems:"center"
                }}
                align="right"
              >
                Subtotal:
              </TableCell>
              <TableCell
                sx={{
                  // backgroundColor: "#fff",
                  color: "#000",
                  fontSize: "16px",
                  textAlign: "right",
                }}
              >
                ${Number(props.totdet?.subTot).toFixed(2)}
              </TableCell>
              <TableCell
                sx={{
                  // backgroundColor: "#fff",
                  color: "#000",
                  fontSize: "16px",
                  fontWeight: "bold",
                  textAlign: "right",
                  display: `${deldisplay}`,
                }}
              ></TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                sx={{
                  // backgroundColor: "#baff39",
                  fontWeight: "bold",
                  color: "#000",
                }}
              ></TableCell>
              <TableCell
                sx={{
                  // backgroundColor: "#baff39",
                  fontWeight: "bold",
                  color: "#000",
                }}
              ></TableCell>
              <TableCell
                sx={{
                  // backgroundColor: "#baff39",
                  fontWeight: "bold",
                  color: "#000",
                }}
              ></TableCell>
              <TableCell
              colSpan={2}
                sx={{
                  // backgroundColor: "#baff39",   
                  width:"150px",               
                  fontWeight: "bold",
                  color: "#000",
                  fontSize: "16px",
                  display:"flex",
                  justifyContent:"end",
                  alignItems:"center"
                }}
                align="right"
              >
                Convenience Feed:
              </TableCell>
              <TableCell
                sx={{
                  // backgroundColor: "#baff39",
                  color: "#000",
                  fontSize: "16px",
                  textAlign: "right",
                }}
              >
                ${Number(props.totdet?.subConvfee).toFixed(2)}
              </TableCell>
              <TableCell
                sx={{
                  // backgroundColor: "#baff39",
                  color: "#000",
                  fontSize: "16px",
                  fontWeight: "bold",
                  textAlign: "right",
                  display: `${deldisplay}`,
                }}
              ></TableCell>
            </TableRow>
            <TableRow sx={{}}>
              <TableCell
                sx={{
                  // backgroundColor: "#baff39",
                  fontWeight: "bold",
                  color: "#000",
                }}
              ></TableCell>
              <TableCell
                sx={{
                  // backgroundColor: "#baff39",
                  fontWeight: "bold",
                  color: "#000",
                }}
              ></TableCell>
              <TableCell
                sx={{
                  // backgroundColor: "#baff39",
                  fontWeight: "bold",
                  color: "#000",
                }}
              ></TableCell>
              <TableCell
                sx={{
                  // backgroundColor: "#baff39",
                  fontWeight: "bold",
                  color: "#000",
                  fontSize: "16px",
                  display:"flex",
                  alignItems:"center"
                }}
                align="right"
              >
                Grand Total:
              </TableCell>
              <TableCell
                sx={{
                  // backgroundColor: "#baff39",
                  color: "#000",
                  fontSize: "16px",
                  fontWeight: "bold",
                  textAlign: "right",
                }}
              >
                ${Number(props.totdet?.subGrandTot).toFixed(2)}
              </TableCell>
              <TableCell
                sx={{
                  // backgroundColor: "#baff39",
                  color: "#000",
                  fontSize: "16px",
                  fontWeight: "bold",
                  textAlign: "right",
                  display: `${deldisplay}`,
                }}
              ></TableCell>
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </>
  );
};

export default CartGrid;
