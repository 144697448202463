import { Grid } from "@mui/material";
import React from "react";
import style from "./Aboutus.module.css";

const Aboutus = () => {
  return (
    <div className="">
      <Grid container spacing={0} className={style.bg1}  >
        <Grid item xs={12} md={12}>
          <span style={{ color: "#BAFF39",fontSize:"32px" }}>How AceChaser.com works:</span>
        </Grid>
       
         <Grid item xs={12} md={12}>
          <span style={{ color: "white" }}>
           When you buy your tickets online AceChaser.com will send you an email with an Order Number on it. Depending on the committee, AceChaser.com will stop selling tickets online a few hours before the draw time.
          </span>
        </Grid>
        <Grid item xs={12} md={12}>
          <span style={{ color: "white" }}>
           Once the sales are stopped – We generate a report and send it to the committee. The Sales report provides the draw committee with the following information:
          </span>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              color: "white",
            }}
          >
            <span>* Order Number</span>
            <span>* First & Last Name</span>
            <span>* Email Address</span>
            <span>* Phone Number</span>
            <span>* Number of Tickets purchased.</span>
          </div>
        </Grid>
        <Grid item xs={12} md={12}>
          <p style={{ color: "white" }}>
           The committee will then assign a 50/50 ticket number to each ticket purchased online – then put the other half of the 50/50 ticket in the drum for the draw.
          </p>
          <p style={{ color: "white" }}>
           If the committee draws out your number – they will call you and let you know “How much you won” and “How you can pick up your money”
          </p>
          <p
            style={{
              fontWeight: "500",
              color: "white",
              marginTop: "-10px",
            }}
          >
            If you still have questions or need clarification, please click on{" "}
            <a href="/contact" style={{ color: "#99e6ff" }}>
              Contact Us
            </a>{" "}
            to send us an email.
          </p>
        </Grid>
      </Grid>
    </div>
  );
};

export default Aboutus;
