import React from "react";
import NavbarComp from "../../Component/Navbar/NavbarComp";
import FooterComp from "../../Component/Footer/FooterComp";
import GroupOrder from "../../Component/My Order/GroupOrder";

const GroupOrderScreen = () => {
  return (
    <div>
      <div className="navbar">
        <NavbarComp />
      </div>
      <div className="comp">
        <GroupOrder />
      </div>
      <div className="footer">
        <FooterComp />
      </div>
    </div>
  );
};

export default GroupOrderScreen;
