import logo from "./logo.svg";
import "./App.css";
import Home from "./Component/Home/Home";
import { Grid } from "@mui/material";
import HomeScreen from "./Screen/HomeScreen/HomeScreen";
import AnalyticsDashboard from "./Component/Demo/AnalyticsDashboard";

function App() {
  return (
    <>
      <Grid container spacing={2} >
        <Grid item md={12}>
          <HomeScreen />
          {/* <AnalyticsDashboard/> */}
        </Grid>
      </Grid>
    </>
  );
}

export default App;
