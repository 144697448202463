import { Button, Grid } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import "./Carddetail.css";
import CalendarTodaySharpIcon from "@mui/icons-material/CalendarTodaySharp";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";

import defimg from "../../Assets/default-image.jpg";
import { Link, useNavigate } from "react-router-dom";
import { UserContext } from "../../Context/UserContext";
import "../../CSS/bg.css";
import { IMG_URL, formatISOToCustom } from "../../MIS/Global";
const CardDetail = () => {
  const navigate = useNavigate();
  const { getticket, getticket_dispatch } = useContext(UserContext);
  // alert(JSON.stringify(getticket));

  if (getticket.length <= 0) {
    window.location = "/";
  }
  // alert(JSON.stringify(getticket));
  const [dates, setDate] = useState([]);
  const date = [
    {
      label: "-Other dates and times-",
    },
  ];
  const showdate = (showdate, type) => {
    let ret = "";
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const month_names_short = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    let weekday = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    switch (type) {
      case "mname_full":
        break;
      case "mname_short":
        let month = Intl.DateTimeFormat("defualt", { month: "short" }).format(
          new Date(showdate)
        );
        // ret = month_names_short[month]
        ret = month;
        break;
      case "dayname_short":
        // let dayname = new Date(showdate).getDay();
        let dayname = Intl.DateTimeFormat("defualt", {
          weekday: "short",
        }).format(new Date(showdate));
        ret = dayname;
        break;
      case "dayname_full":
        break;
      case "day":
        let day = new Date(showdate).getDate();
        if (day.toString().length <= 1) {
          day = `0${day}`;
        }
        ret = day;
        break;
      case "time":
        // let hours = new Date(showdate).getHours();
        // let minutes = new Date(showdate).getMinutes();
        let newTime = new Date(showdate).toLocaleTimeString("en-US");
        let hour = newTime.split(":")[0];
        let amPm = newTime.split(" ")[1];
        let seconds = newTime.split(":")[2].replace(amPm, "");
        let noSeconds = newTime.replace(":" + seconds, " ");
        if (parseInt(hour) < 9) {
          noSeconds = "0" + noSeconds;
        }
        ret = noSeconds;
        break;
      case "year":
        let year = new Date(showdate).getFullYear();
        ret = year;
        break;

      default:
        break;
    }

    // if (day.toString().length <= 1) {
    //   day = `0${day}`;
    // }

    // setShowtime(`${year}-${month}-${day} ${hours}:${minutes}`);
    return ret;
  };
  const [imageBlobs, setImageBlobs] = useState([]);

   
  return (
    <Grid
      container
      spacing={2}
      pl={1}
      mt={-4}
      className="maincon bg1 forscrolls"
      sx={{ backgroundColor: "#6E6E6E" }}
    >
      <Grid item md={6} xs={12} className="subcon" sx={{ marginTop: "2%" }}>
        
        <img
          src={getticket[0]?.drawimg == "-" ? defimg : IMG_URL+getticket[0]?.drawimg}
          className="imgcon"
        />{" "}
        {/* <img src={map} className="imgcon2" /> */}
      </Grid>
      <Grid item md={6} xs={12} className="subicon" sx={{ marginTop: "2%" }}>
        <h2 style={{ color: "#BAFF39", marginTop: "-10px" }}>
          {getticket[0]?.showdetail[0]?.showname}
        </h2>
        <div
          style={{
            display: "flex",
            justifyContent: "left",
            alignItems: "center",
            gap: "10px",
            marginTop: "-15px",
          }}
        >
          <CalendarTodaySharpIcon
            className="calicon"
            style={{ color: "#BAFF39" }}
          ></CalendarTodaySharpIcon>
          {/* let month_name = showdate(row.show_time, 'mname_short')
          let day_name = showdate(row.show_time, 'dayname_short')
          let day = showdate(row.show_time, 'day')
          let time = showdate(row.show_time, 'time') */}
          <p style={{ fontWeight: "500", fontSize: "17px", color: "#e6e6e6" }}>
            {showdate(formatISOToCustom(getticket[0]?.show_time), "mname_short").toUpperCase()}
            <span
              style={{
                color: "black",
                marginLeft: "5px",
                marginRight: "5px",
                color: "#e6e6e6",
              }}
            >
              {showdate(formatISOToCustom(getticket[0]?.show_time), "day")}
            </span>
            {showdate(formatISOToCustom(getticket[0]?.show_time), "dayname_short").toUpperCase()}
          </p>

          <p
            style={{
              fontWeight: "500",
              fontSize: "17px",
              marginLeft: "0px",
              color: "#e6e6e6",
            }}
          >
            {showdate(formatISOToCustom(getticket[0]?.show_time), "time")}
          </p>
        </div>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <span style={{ fontWeight: "bold", color: "#e6e6e6" }}>
            Committe : &nbsp; {getticket[0]?.showdetail[0]?.vendrname}
          </span>
          <span
            style={{ fontWeight: "bold", color: "#e6e6e6", display: "none" }}
          >
            Type : &nbsp; Single
          </span>
          <span style={{ fontWeight: "bold", color: "#e6e6e6" }}>
            Jackot Value : &nbsp; $
            {Number(getticket[0]?.showdetail[0]?.jackpotvalue).toLocaleString(
              "en-US",
              {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }
            )}
            {/* ${getticket[0]?.showdetail[0]?.jackpotvalue} */}
          </span>
          <span style={{ fontWeight: "bold", color: "#e6e6e6" }}>
            Cards Left : &nbsp;{getticket[0]?.showdetail[0]?.cardleft}
          </span>
          <div style={{ display: "flex" }}>
            <span style={{ fontWeight: "bold", color: "#e6e6e6" }}>
              Cost Per Ticket:
            </span>
            <span style={{ color: "#e6e6e6", marginLeft: "10px",textAlign:"justify" }}>
              {getticket[0]?.ticketdet.map((element, index) => {
                return (
                  <>
                    <div style={{display:"flex",justifyContent:"space-between",gap:"5px"}}>
                    <span >{element?.text}</span>
                    <span >-</span>
                    <span >${Number(element?.price).toFixed(2)}</span>
                    </div>                                                        
                  </>
                );
              })}
            </span>
          </div>
        </div>

        <div className="atodiv" style={{ display: "none", color: "#e6e6e6" }}>
          <p>Click below for more dates & times</p>
          <Autocomplete
            disablePortal
            id="combo"
            className="combobox"
            size="small"
            value={dates}
            options={date}
            onChange={(e, newValue) => setDate(newValue.label)}
            renderInput={(params) => (
              <TextField {...params} label="-Other dates and times-" />
            )}
          />
        </div>
        <span
          style={{ fontSize: "20px", fontWeight: "bold", color: "#BAFF39" }}
        >
          Description
          
        </span>
        <br />
        <span style={{ color: "#e6e6e6",whiteSpace: "pre-line"  }}>
          {getticket[0]?.descr?getticket[0]?.descr.replace(/#newline#/g, '\n') : ''}
          {/* Online tickets will be available until 5:00 pm the day of the draw.
          <br />
          You do not need to be onsite at the time of the draw - if you are not
          present a committee member will draw your card for you.
          <br /> You must be a resident of the province of Prince Edward Island
          to purchase a ticket online and win.
          <br /> Good Luck! */}
        </span>
        <Grid item xs={12} md={7} p={2}>
          <Link to="/cart" style={{ textDecoration: "none" }}>
            <Button
              variant="contained"
              fullWidth
              className="btnall"
              style={{
                padding: "12px 10px",
                backgroundColor: "#BAFF39",
                fontSize: "15px",
                fontWeight: "600",
                color: "black",
                height: "35px",
                cursor: "pointer",
              }}
            >
              Get Tickets
            </Button>
          </Link>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CardDetail;
