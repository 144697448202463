import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "./CSS/font.css";
import App from "./App";
import { render } from "react-dom";
import { BrowserRouter, Routes, Route, useParams } from "react-router-dom";
import ProductComp from "./Component/Product/ProductComp";
import SignUp from "./Component/Registration/SignUp";
import ContactusComp from "./Component/ContactUs/ContactusComp";
import Cart from "./Component/CartPage/Cart";
import CardDetail from "./Component/CardDetail/CardDetail";
import ProductScreen from "./Screen/ProductScreen/ProductScreen";
import ContactScreen from "./Screen/ContactScreen/ContactScreen";
import CartScreen from "./Screen/CartScrren/CartScreen";
import CartDetailScreen from "./Screen/CartDetailScreen/CartDetailScreen";
import CheckoutPageScreen from "./Screen/CheckoutPage/CheckoutPageScreen";
import MyOrderScreen from "./Screen/MyOrderScreen/MyOrderScreen";
import NewtestProduct from "./Component/Product/NewtestProduct";
import AllProductScreen from "./Screen/AllProductScreen/AllProductScreen";
import AboutUsScreen from "./Screen/AboutUsScreen/AboutUsScreen";
import UserContextProvider from "./Context/UserContext";
import MyOrderHistoryScreen from "./Screen/MyOrderScreen/MyOrderHistoryScreen";
import PrivacyScreen from "./Screen/PrivcyScreen/PrivacyScreen";
import TCScreen from "./Screen/TCScreen/TCScreen";
import ChangePasswordScreen from "./Screen/ChangePasswordScreen/ChangePasswordScreen";
import YourOrderScreen from "./Screen/CartScrren/YourOrderScreen";
import GroupOrderScreen from "./Screen/MyOrderScreen/GroupOrderScreen";
import WhoweareScreen from "./Screen/TCScreen/WhoweareScreen";
import ForgotPassScreen from "./Screen/Forgotpassword/ForgotPassScreen";
import "./CSS/Base.css";
import Achievement from "./Component/MIS_Demo/Achivements/Achievement";
 
const rootElement = document.getElementById("root");

render(
  <BrowserRouter>
    <UserContextProvider>
      <Routes>
        <Route path="/" element={<App />} />
        <Route path="/product" element={<ProductScreen />} />
        <Route path="/about" element={<AboutUsScreen />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/contact" element={<ContactScreen />} />
        <Route path="/cart" element={<CartScreen />} />
        <Route path="/cartdetail" element={<CartDetailScreen />} />
        <Route path="/checkout" element={<CheckoutPageScreen />} />
        <Route path="/yourorder" element={<YourOrderScreen />} />
        <Route path="/myorder" element={<MyOrderScreen />} />
        <Route path="/myorderhistory" element={<MyOrderHistoryScreen />} />
        <Route path="/orderdetail" element={<GroupOrderScreen />} />
        <Route path="/allproduct" element={<AllProductScreen />} />
        <Route path="/forgotpass" element={<ForgotPassScreen />} />
        <Route path="/policy" element={<PrivacyScreen />} />
        <Route path="/tc" element={<TCScreen />} />
        <Route path="/changepassword" element={<ChangePasswordScreen />} />
        <Route path="/whoweare" element={<WhoweareScreen />} />
        <Route path="/achiev" element={<Achievement />} />

       </Routes>
    </UserContextProvider>
  </BrowserRouter>,
  rootElement
);
