import axios from "axios";
import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react"; 
import "./Home.css";
import { DateTime } from 'luxon';
import aceImage from '../../Assets/ace.jpg'
const Home = () => {
  const [estTime, setEstTime] = useState(null);
  // const [currLocation, setCurrLocation] = useState({});
  // const requestGeolocationPermission = async () => {
  //   try {
  //     const permissionStatus = await navigator.permissions.query({ name: 'geolocation' });
      
  //     if (permissionStatus.state === 'granted') {
  //       navigator.geolocation.getCurrentPosition(
  //         (position) => {
  //           const { latitude, longitude } = position.coords;
  //           setCurrLocation({ latitude, longitude });
  //         },
  //         (error) => {
  //           console.error('Error getting current location:', error);
  //         }
  //       );
  //     } else{
  //       // Execute this block only when state is not 'prompt'
  //       navigator.geolocation.getCurrentPosition(
  //         (position) => {
  //           console.log('Geolocation permission granted by user');
  //           const { latitude, longitude } = position.coords;                      
  //           setCurrLocation({ latitude, longitude });
            
  //           // Perform actions with the granted permission and coordinates
  //         },
  //         (error) => {            
  //           // Show a notification for the error
  //           if (Notification.permission === 'granted') {
  //             new Notification('Error', { body: 'Error getting current location.' });
  //           }
  //         }
  //       );
  //     }  
      
  //   } catch (error) {      
  //     console.error('Error checking geolocation permission:', error);
      
  //   }
  // };
 
  // useEffect(() => {
  //   requestGeolocationPermission();
  // }, [])
  

  
  useEffect(() => {
    // Get the current date and time in UTC
    const utcNow = DateTime.utc();

    // Convert to Eastern Standard Time (EST)
    const estNow = utcNow.setZone('America/New_York');

    // Save the estNow variable or use it as needed
    setEstTime(estNow);
   }, [])
  return (
    <>
 
    <Grid container spacing={0} mt={-5} className="slidemaincontainer scroll">
      <Grid item md={6} xs={12} className="con1">
        <h1>Welcome to AceChaser.com </h1>        
        <p style={{ color: "white" }}>
          We are an Island owned company that is committed to helping local
          community organizations by supporting their fundraising efforts.
        </p>
        <span style={{ display: "" }}>
          Vision Statement <br />
          <p>
            To provide an online solution to meet the expanded needs to local
            communities.
          </p>
        </span>
        <span style={{ display: "" }}>
          Mission Statement <br />
          <p>
            We are committied to helping our local community organizations by
            providing their customers with expanded purchasing options.
          </p>
        </span>
      </Grid>
      <Grid item md={0.3} xs={0.1}></Grid>
      <Grid item md={5} xs={12} className="slidecontainer" >
          <img src={aceImage} alt="" srcset="" />
        {/* <div className="slidecontainer"></div> */}
      </Grid>
    </Grid>
 
    </>
  );
};

export default Home;
