export const GetOrderReducer = (order, action) => {
  // alert(JSON.stringify(order));
  switch (action.type) {
    case "add": {
      return [action.data];
    }
    case "addArray": {
      return action.data;
    }
    case "clear": {
      return [];
    }
    default: {
      throw Error("Unknown action: " + action.type);
    }
  }
};

export const GetCheckoutReducer = (checkout, action) => {
  switch (action.type) {
    case "add": {
      return [action.data];
    }
    case "clear": {
      return [];
    }
    default: {
      throw Error("Unknown action: " + action.type);
    }
  }
};
