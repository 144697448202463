import { BASE_URL } from "../MIS/Global";
import axios from "axios";
import React from "react";
import ReactDOM from "react-dom";
import Loader from "../Component/loader/Loader";
import { TypeSpecimenOutlined } from "@mui/icons-material";

export const calcCountAmount = (type, newcount, newamount) => {
  let count = localStorage.getItem("cart_count");
  let amount = localStorage.getItem("cart_amount");
  if (type == "new") {
    // alert(count +"---"+amount);
    if(Number(count)>0 || Number(amount)>0){return;}
    localStorage.setItem("cart_count", 0);
    localStorage.setItem("cart_amount", 0);
  } else if (type == "add") {

    let newcalc_cnt = Number(Number(count) + Number(newcount)).toFixed(0);
    let newcalc_amount = Number(Number(amount) + Number(newamount)).toFixed(2);
    // alert(newcalc_cnt +"###"+newcalc_amount);

    localStorage.setItem("cart_count", newcalc_cnt);
    localStorage.setItem("cart_amount", newcalc_amount);
  } else if (type == "sub") {
    let newcalc_cnt = Number(Number(count) - Number(newcount)).toFixed(0);
    if (newcalc_cnt < 0) {
      newcalc_cnt = newcalc_cnt * -1;
    }
    let newcalc_amount = Number(Number(amount) - Number(newamount)).toFixed(2);
    if (newcalc_amount < 0) {
      newcalc_amount = newcalc_amount * -1;
    }
    localStorage.setItem("cart_count", newcalc_cnt);
    localStorage.setItem("cart_amount", newcalc_amount);
  }else if (type == "cls") {
    localStorage.setItem("cart_count", 0);
    localStorage.setItem("cart_amount", 0);
  }
};

export const SaveCart = async (data) => {
  // alert(JSON.stringify(data));

  ReactDOM.render(<Loader></Loader>, document.getElementById("root1"));

  let url = "savecart";

  let ret = {};
  var config = {
    method: "post",
    url: BASE_URL + url,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };
  await axios(config)
    .then(function (response) {
      ReactDOM.render(
        <Loader bal={false}></Loader>,
        document.getElementById("root1")
      );
      ret = response.data;
      if (ret.code == 200) {
        ret = { msg: ret.msg, code: 200, data: ret.data };
        return ret;
      } else if (ret.code == 400) {
        ret = { msg: ret.msg, code: 400 };
        return ret;
      } else {
        // alert(JSON.stringify(ret));
        ret = { msg: "something went wrong! please try again1 ", code: 500 };

        return ret;
      }
    })
    .catch(function (error) {
      ReactDOM.render(
        <Loader bal={false}></Loader>,
        document.getElementById("root1")
      );
      ret = { msg: error, code: 500 };

      return ret;
    });

  return ret;
};

export const DeleteCart = async (data) => {
  // alert(JSON.stringify(data));
  ReactDOM.render(<Loader></Loader>, document.getElementById("root1"));

  let url = "deletecart";

  let ret = {};
  var config = {
    method: "post",
    url: BASE_URL + url,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };
  await axios(config)
    .then(function (response) {
      ReactDOM.render(
        <Loader bal={false}></Loader>,
        document.getElementById("root1")
      );
      ret = response.data;
      if (ret.code == 200) {
        ret = { msg: ret.msg, code: 200, data: ret.data };
        return ret;
      } else if (ret.code == 300) {
        ret = { msg: ret.msg, code: 300 };
        return ret;
      } else if (ret.code == 400) {
        ret = { msg: ret.msg, code: 400 };
        return ret;
      } else {
        ret = { msg: "something went wrong! please try again1 ", code: 500 };

        return ret;
      }
    })
    .catch(function (error) {
      ReactDOM.render(
        <Loader bal={false}></Loader>,
        document.getElementById("root1")
      );
      ret = { msg: error, code: 500 };

      return ret;
    });

  return ret;
};

export const deletecart_Cartid = async (data) => {
  // alert(JSON.stringify(data));

  ReactDOM.render(<Loader></Loader>, document.getElementById("root1"));

  let url = "deletecart_cartid";

  let ret = {};
  var config = {
    method: "post",
    url: BASE_URL + url,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };
  await axios(config)
    .then(function (response) {
      ReactDOM.render(
        <Loader bal={false}></Loader>,
        document.getElementById("root1")
      );
      ret = response.data;
      if (ret.code == 200) {
        ret = { msg: ret.msg, code: 200, data: ret.data };
        return ret;
      } else if (ret.code == 300) {
        ret = { msg: ret.msg, code: 300 };
        return ret;
      } else if (ret.code == 400) {
        ret = { msg: ret.msg, code: 400 };
        return ret;
      } else {
        ret = { msg: "something went wrong! please try again1 ", code: 500 };

        return ret;
      }
    })
    .catch(function (error) {
      ReactDOM.render(
        <Loader bal={false}></Loader>,
        document.getElementById("root1")
      );
      ret = { msg: error, code: 500 };

      return ret;
    });

  return ret;
};

export const GetCart = async (data) => {
  // alert(JSON.stringify(data));

  ReactDOM.render(<Loader></Loader>, document.getElementById("root1"));

  let url = "getcart";

  let ret = {};
  var config = {
    method: "post",
    url: BASE_URL + url,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };
  await axios(config)
    .then(function (response) {
      ReactDOM.render(
        <Loader bal={false}></Loader>,
        document.getElementById("root1")
      );
      ret = response.data;
      if (ret.code == 200) {
        ret = { msg: ret.msg, code: 200, data: ret.data };
        return ret;
      } else if (ret.code == 300) {
        ret = { msg: ret.msg, code: 300 };
        return ret;
      } else if (ret.code == 400) {
        ret = { msg: ret.msg, code: 400 };
        return ret;
      } else {
        ret = { msg: "something went wrong! please try again1 ", code: 500 };

        return ret;
      }
    })
    .catch(function (error) {
      ReactDOM.render(
        <Loader bal={false}></Loader>,
        document.getElementById("root1")
      );
      ret = { msg: error, code: 500 };

      return ret;
    });

  return ret;
};

export const GetCart_Timer = async (data) => {
  // alert(JSON.stringify(data));

  // ReactDOM.render(<Loader></Loader>, document.getElementById("root1"));

  let url = "getcart";

  let ret = {};
  var config = {
    method: "post",
    url: BASE_URL + url,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };
  await axios(config)
    .then(function (response) {
      ReactDOM.render(
        <Loader bal={false}></Loader>,
        document.getElementById("root1")
      );
      ret = response.data;
      if (ret.code == 200) {
        ret = { msg: ret.msg, code: 200, data: ret.data };
        return ret;
      } else if (ret.code == 300) {
        ret = { msg: ret.msg, code: 300 };
        return ret;
      } else if (ret.code == 400) {
        ret = { msg: ret.msg, code: 400 };
        return ret;
      } else {
        ret = { msg: "something went wrong! please try again1 ", code: 500 };

        return ret;
      }
    })
    .catch(function (error) {
      ReactDOM.render(
        <Loader bal={false}></Loader>,
        document.getElementById("root1")
      );
      ret = { msg: error, code: 500 };

      return ret;
    });

  return ret;
};
