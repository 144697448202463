import React from "react";
import styled from "../../CSS/New.module.css";
import "../../CSS/bg.css";
const Whoweare = () => {
  return (
    <div className="bg1 forscroll">
      <div style={{ paddingLeft: "10px" }}>
        <div style={{ marginBottom: "40px" }}>
          <div className={styled.head}>Who We Are</div>
          <hr />
          <span style={{ fontSize: "15px", fontWeight: "200", color: "white" }}>
            We are a small PEI owned and operated company that has been helping
            communities since 2017. Our goal is to help Island organizations,
            such as the Royal Canadian Legions, Lions Clubs, sporting groups,
            and other community centers raise money through charity events such
            as 50/50 draws like "Chase the Ace".
          </span>
        </div>
      </div>
    </div>
  );
};

export default Whoweare;
