import { Box, FormControlLabel, Grid, Modal, Typography } from "@mui/material";
import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import img from "../../Assets/card.jpg";
import "../Navbar/Navbar.css";
import {
  GetUserLogData,
  GetUserLogin,
  SaveLogin,
  Sendotp_email,
} from "../../API/LoginModel";
import { useNavigate } from "react-router-dom";
import AlertMessageComp from "../AlertMessageCom/AlertMessageComp";
import { genotp, onlynumeric, tostmsg } from "../../MIS/Global";
import SignupEmail from "./SignupEmail";
import ReactDOMServer from "react-dom/server";
import "../../CSS/bg.css";
import Checkbox from "@mui/material/Checkbox";
import "./SignUp.css";

const SignUp = () => {
  const [logid, setLogid] = useState();
  const [uname, setUname] = useState("");
  const [mob, setMob] = useState("-");
  const [emailid, setEmailid] = useState("");
  const [paswd, setPaswd] = useState("");
  const [role, setRole] = useState("user");
  const [isblock, setIsblock] = useState(0);
  const [btnname, setBtnname] = useState("Get Otp");
  const [verifyotp, setVerifyotp] = useState("");
  const [otp, setOtp] = useState("");
  const [checked, setChecked] = useState(false);
  const [disablechecked, setdisableChecked] = useState(false);

  const [modelOpen, setModelOpen] = React.useState(false);

  const handleModelOpen = () => setModelOpen(true);
  const handleClose = () => setModelOpen(false);

  const [paswddisplay, setPaswddisplay] = useState("none");
  const [votpdisplay, setVotpdisplay] = useState("none");

  const [alertdata, setAlertdata] = React.useState({});
  const [alertopen, setAlertopen] = React.useState(false);
  const navigate = useNavigate();

  let data = {
    uname: uname,
    mob: mob,
    emailid: emailid,
    paswd: paswd,
    role: role,
    isblock: isblock,
  };

  const handleChange2 = (event) => {
    // alert(event.target.checked)
    setChecked([event.target.checked, checked[1]]);
    setdisableChecked(true);
  };

  return (
    <>
      <AlertMessageComp
        type={alertdata.type}
        msg={alertdata.msg}
        vertical={alertdata.vertical}
        horizontal={alertdata.horizontal}
        setOpen={setAlertopen}
        open={alertopen}
      />
      <Grid container spacing={2} className="signhead forscroll">
        <Grid
          item
          xs={12}
          md={4.5}
          className="signrescon"
          sx={
            {
              // marginLeft:"15px"
              // position: "relative",
              // width: "100%",
              // height: "75.3vh",
            }
          }
        >
          {" "}
          <div>
            <span style={{ fontSize: 24, fontWeight: "bold", color: "white" }}>
              Sign Up
            </span>
            <div style={{ color: "white" }}>
              <p>
               You must be 18 years old to claim a cash prize. All tickets must be purchased within the province of the draw to be considered valid.
              </p>
              <p>See  <span
                style={{
                  fontSize: "14px",
                  color: "#2874f0",
                  cursor: "pointer",
                }}
                onClick={handleModelOpen}
              >
                <u>Terms and Conditions.</u>
              </span> for full details.</p>
              <p>AceChaser.com is a division of Ticket Wizard Inc.</p>
              <p style={{ fontSize: "12px" }}>
                Supporting local communities since 2016...
              </p>
            </div>
          </div>
          <div style={{ display: "none" }}>
            <img
              className="resimg"
              src="https://www.acechaser.com/resources/3495.jpg"
            />
          </div>
        </Grid>
        <Grid
          item
          xs={12}
          md={7.5}
          sx={
            {
              // backgroundColor: "#FFF",
              // opacity:0.2
            }
          }
        >
          <Grid item xs={12} md={12} p={2}>
            <TextField
              id="standard-basic"
              label="Please Please enter User Name"
              variant="standard"
              fullWidth
              value={uname}
              inputProps={{ maxLength: 100 }}
              onChange={async (e) => {
                setUname(e.target.value);
              }}
            />
          </Grid>
          <Grid item xs={12} md={12} p={2}>
            <TextField
              id="standard-basic"
              label="Please enter Email"
              variant="standard"
              fullWidth
              value={emailid}
              inputProps={{ maxLength: 100 }}
              onChange={async (e) => {
                setEmailid(e.target.value);
              }}
            />
          </Grid>
          <Grid item xs={12} md={12} p={2} display={paswddisplay}>
            <TextField
              label="Password"
              type="password"
              fullWidth
              variant="standard"
              value={paswd}
              inputProps={{ maxLength: 20 }}
              onChange={async (e) => {
                setPaswd(e.target.value);
              }}
            />
          </Grid>
          <Grid item xs={12} md={12} p={2} display={votpdisplay}>
            <TextField
              label="Temporary Password"
              type="text"
              fullWidth
              variant="standard"
              sx={{ marginBottom: "10px" }}
              value={otp}
              inputProps={{ maxLength: 6 }}
              onChange={async (e) => {
                let ret = onlynumeric(e.target.value);
                setOtp(e.target.value);
              }}
            />
            <br />
            <Button
              variant="contained"
              style={{
                backgroundColor: "#BAFF39",
                color: "black",
                fontSize: "15px",
                fontWeight: "600",
                cursor: "pointer",
              }}
              onClick={() => {
                (async () => {
                  try {
                    let rettostmsg1 = "";
                    if (uname.length <= 0) {
                      rettostmsg1 = tostmsg({
                        msg: "Please enter User Name",
                        code: 100,
                      });
                      setAlertdata(rettostmsg1);
                      setAlertopen(rettostmsg1.open);
                      return;
                    }
                    if (emailid.length <= 0) {
                      rettostmsg1 = tostmsg({
                        msg: "Please enter Email",
                        code: 100,
                      });
                      setAlertdata(rettostmsg1);
                      setAlertopen(rettostmsg1.open);
                      return;
                    }
                    const regex =
                      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
                    if (regex.test(emailid) === false) {
                      rettostmsg1 = tostmsg({
                        msg: "Please enter a valid email",
                        code: 100,
                      });
                      setAlertdata(rettostmsg1);
                      setAlertopen(rettostmsg1.open);
                      return;
                    }
                    const emailduplicate = await GetUserLogin(data);
                    // alert(JSON.stringify(user));

                    if (emailduplicate.code == 200) {
                      rettostmsg1 = tostmsg({
                        msg: "This Email ID already exists.",
                        code: 100,
                      });
                      setAlertdata(rettostmsg1);
                      setAlertopen(rettostmsg1.open);
                      return;
                    }

                    let eotp = genotp;
                    setVerifyotp(eotp);
                    const htmlEmail = ReactDOMServer.renderToStaticMarkup(
                      <SignupEmail type="" otp={eotp} />
                    );
                    // alert(eotp);

                    let emaildata = {
                      emailid: emailid,
                      htmlmsg: `${htmlEmail.toString()}`,
                    };
                    const emailotp = Sendotp_email(emaildata);
                    let rettostmsg = tostmsg(emailotp);
                    // alert(JSON.stringify(user));

                    if (emailotp.code == 100) {
                      rettostmsg = tostmsg({ msg: "Invalid Email", code: 100 });
                    } else {
                      rettostmsg = tostmsg({
                        msg: "Check your OTP on your email inbox/span",
                        code: 200,
                      });
                    }
                    setAlertdata(rettostmsg);
                    setAlertopen(rettostmsg.open);
                  } catch (e) {}
                })();
              }}
            >
              Resend Temporary Password
            </Button>
          </Grid>

          <Grid item xs={12} md={12} p={2} display="none">
            <p>
              By conyinuning you to TicketWizard{" "}
              <span style={{ color: "blue" }}>Terms of use</span>and
              <span style={{ color: "blue" }}> Privacy Policy.</span>
            </p>
          </Grid>
          <Grid item xs={12} md={12} p={2}>
            <Button
              variant="contained"
              fullWidth
              style={{
                padding: "12px 10px",
                backgroundColor: "#BAFF39",
                fontSize: "15px",
                fontWeight: "600",
                color: "black",
                cursor: "pointer",
              }}
              onClick={() => {
                (async () => {
                  try {
                    if (btnname == "Get Otp") {
                      let rettostmsg1 = "";
                      if (uname.length <= 0) {
                        rettostmsg1 = tostmsg({
                          msg: "Please enter User Name",
                          code: 100,
                        });
                        setAlertdata(rettostmsg1);
                        setAlertopen(rettostmsg1.open);
                        return;
                      }
                      if (emailid.length <= 0) {
                        rettostmsg1 = tostmsg({
                          msg: "Please enter Email",
                          code: 100,
                        });
                        setAlertdata(rettostmsg1);
                        setAlertopen(rettostmsg1.open);
                        return;
                      }
                      if (!checked) {
                        rettostmsg1 = tostmsg({
                          msg: "You must agree to the term first.",
                          code: 100,
                        });
                        setAlertdata(rettostmsg1);
                        setAlertopen(rettostmsg1.open);
                        return false;
                      }

                      const regex =
                        /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
                      if (regex.test(emailid) === false) {
                        rettostmsg1 = tostmsg({
                          msg: "Please enter a valid email",
                          code: 100,
                        });
                        setAlertdata(rettostmsg1);
                        setAlertopen(rettostmsg1.open);
                        return;
                      }
                      const emailduplicate = await GetUserLogin(data);
                      // alert(JSON.stringify(user));
                      if (emailduplicate.code == 200) {
                        rettostmsg1 = tostmsg({
                          msg: "This Email ID already exists.",
                          code: 100,
                        });
                        setAlertdata(rettostmsg1);
                        setAlertopen(rettostmsg1.open);
                        return;
                      }

                      let eotp = genotp;
                      setVerifyotp(eotp);
                      const htmlEmail = ReactDOMServer.renderToStaticMarkup(
                        <SignupEmail type="" otp={eotp} />
                      );
                      // alert(eotp);

                      let emaildata = {
                        emailid: emailid,
                        htmlmsg: `${htmlEmail.toString()}`,
                      };
                      const emailotp = Sendotp_email(emaildata);
                      let rettostmsg = tostmsg(emailotp);
                      // alert(JSON.stringify(user));

                      if (emailotp.code == 100) {
                        rettostmsg = tostmsg({
                          msg: "Invalid Email",
                          code: 100,
                        });
                      } else {
                        rettostmsg = tostmsg({
                          msg: "Check your OTP on your email inbox/span",
                          code: 200,
                        });
                      }
                      setAlertdata(rettostmsg);
                      setAlertopen(rettostmsg.open);
                      setVotpdisplay("");
                      setBtnname("Verify Temporary Password");
                      return;
                    } else if (btnname == "Verify Temporary Password") {
                      let rettostmsg = "";
                      if (otp == verifyotp) {
                        // rettostmsg = tostmsg({msg:"Invalid Email",code:100});
                        setVotpdisplay("none");
                        setPaswddisplay("");
                        setBtnname("Sign Up");
                      } else {
                        rettostmsg = tostmsg({ msg: "Invalid OTP", code: 100 });
                        setAlertdata(rettostmsg);
                        setAlertopen(rettostmsg.open);
                      }
                    } else {
                      if (paswd.length <= 5) {
                        let rettostmsg1 = tostmsg({
                          msg: "Password should be minimum 6 character",
                          code: 100,
                        });
                        setAlertdata(rettostmsg1);
                        setAlertopen(rettostmsg1.open);
                        return;
                      }
                      const user = await SaveLogin(data);
                      let rettostmsg = tostmsg(user);
                      // alert(JSON.stringify(user));
                      setAlertdata(rettostmsg);
                      setAlertopen(rettostmsg.open);
                      if (user.code == 100) {
                      }

                      if (user.code == 200) {
                        setTimeout(() => {
                          // window.location.reload()
                          window.location.href = "/allproduct";
                        });
                      }
                    }
                  } catch (e) {}
                })();
              }}
            >
              {btnname == "Get Otp" ? "Get Temporary Password" : btnname}
            </Button>
          </Grid>

          <Grid
            item
            xs={12}
            md={12}
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            <div>
              <Checkbox
                size="small"
                disabled={disablechecked}
                // value={check}
                style={{
                  color: "black",
                }}
                checked={checked[1]}
                indeterminate={checked[1]}
                onChange={handleChange2}
              />
            </div>
            <div>
              <span style={{ fontSize: "14px" }}>
              By continuing, you agree to abide by the terms and conditions of Acechaser.com. Please take a moment to review our{" "}
              </span>
              <span
                style={{
                  fontSize: "14px",
                  color: "#2874f0",
                  cursor: "pointer",
                }}
                onClick={handleModelOpen}
              >
                <u>terms and conditions.</u>
              </span>
            </div>
          </Grid>
        </Grid>
      </Grid>
      <Modal
        open={modelOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="smodelbox">
          {/* <Typography id="modal-modal-title" variant="h6" component="h2">
            <CloseIcon onClick={handleClose} style={{ float: "right" }} />
          </Typography> */}

          <Typography id="modal-modal-description">
            {/* < handleClose={handleClose} /> */}
            <Grid container spacing={2} handleClose={handleClose}>
              <Grid item xs={12} md={12}>
                <h2 style={{ color: "#BAFF39", textAlign: "center" }}>
                 Terms and Conditions{" "}
                </h2>
                <Grid item xs={12} md={12}>
                  <div style={{ marginLeft: "20px", color: "white" }}>
                    {" "}
                    All tickets must be purchased within the draw committee's province of Prince
                    Edward Island. When you purchase a ticket your IP address is
                    recorded to be provided to the draw committee. In order to
                    claim a cash prize, the winning ticket IP address will be
                    validated.
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Typography>
        </Box>
      </Modal>
    </>
  );
};

export default SignUp;
