import React, { useContext, useRef } from "react";
import { Grid } from "@mui/material";
import "./MyOrder.css";
import TextField from "@mui/material/TextField";
import TwitterIcon from "@mui/icons-material/Twitter";
import FacebookIcon from "@mui/icons-material/Facebook";
import YouTubeIcon from "@mui/icons-material/YouTube";
import { Link, useNavigate } from "react-router-dom";

import { UserContext } from "../../Context/UserContext";
import ReactToPrint from "react-to-print";
import EmailMyorder from "../EmailMyorder/EmailMyorder";
import ExpPrint from "./ExpPrint";
import { Ticket_email } from "../../API/OrderModel";
import AlertMessageComp from "../AlertMessageCom/AlertMessageComp";
import { tostmsg } from "../../MIS/Global";
import ReactDOMServer from "react-dom/server";
import Style from "../../CSS/New.module.css";

const MyOrder = () => {
  const {
    getticket,
    getticket_dispatch,
    getorder,
    setorder_dispatch,
    getcheckout,
    setcheckout_dispatch,
    logindata,
  } = useContext(UserContext);
  if (getcheckout.length <= 0) {
    window.location = "/";
  }
  const componentRef = useRef();
  const [alertdata, setAlertdata] = React.useState({});
  const [alertopen, setAlertopen] = React.useState(false);
  const navigate = useNavigate();

  const getdate = (eDate, istime) => {
    let month = new Date(eDate).getMonth() + 1;
    if (month.toString().length <= 1) {
      month = `0${month}`;
    }
    let day = new Date(eDate).getDate();
    if (day.toString().length <= 1) {
      day = `0${day}`;
    }
    let hours = new Date(eDate).getHours();
    // if (day.toString().length <= 1) {
    //   day = `0${day}`;
    // }
    let minutes = new Date(eDate).getMinutes();
    let year = new Date(eDate).getFullYear();
    // year = Number(year) - Number(e.target.value);
    let edate = `${day}/${month}/${year} ${hours}:${minutes}`;
    if (istime == 0) {
      edate = `${day}/${month}/${year}`;
    }

    return edate;
  };
  const ticketPrint = () => {
    var printContent = document.getElementById("printablediv");
    var windowUrl = "about:blank";
    var uniqueName = new Date();
    var windowName = "Print" + uniqueName.getTime();
    var params = [
      "height=" + window.innerHeight,
      "width=" + window.innerWidth,
      "fullscreen=yes", // only works in IE, but here for completeness
    ].join(",");
    var printWindow = window.open(
      windowUrl,
      windowName,
      params
      // 'width=300,height=400',
    );

    printWindow.document.write(printContent.innerHTML);
    printWindow.document.close();
    printWindow.focus();
    printWindow.print();
    printWindow.close();
    // window.close();
    //  document.body.innerHTML = originalContents;
    // navigate("/myorder");
  };
  const showdate = (showdate, type) => {
    let ret = "";
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const month_names_short = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    let weekday = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    switch (type) {
      case "mname_full":
        break;
      case "mname_short":
        // let month = new Date(showdate).getMonth() + 1
        let month = new Date(showdate).getMonth();
        if (month.toString().length <= 1) {
          month = `0${month}`;
        }

        ret = month_names_short[month];
        break;
      case "dayname_short":
        let dayname = new Date(showdate).getDay();
        ret = weekday[dayname];
        break;
      case "dayname_full":
        break;
      case "day":
        let day = new Date(showdate).getDate();
        if (day.toString().length <= 1) {
          day = `0${day}`;
        }
        ret = day;
        break;
      case "time":
        // let hours = new Date(showdate).getHours();
        // let minutes = new Date(showdate).getMinutes();
        let newTime = new Date(showdate).toLocaleTimeString("en-US");
        let hour = newTime.split(":")[0];
        let amPm = newTime.split(" ")[1];
        let seconds = newTime.split(":")[2].replace(amPm, "");
        let noSeconds = newTime.replace(":" + seconds, " ");
        if (parseInt(hour) < 9) {
          noSeconds = "0" + noSeconds;
        }
        ret = noSeconds;
        break;
      case "year":
        let year = new Date(showdate).getFullYear();
        ret = year;
        break;

      default:
        break;
    }

    // if (day.toString().length <= 1) {
    //   day = `0${day}`;
    // }

    // setShowtime(`${year}-${month}-${day} ${hours}:${minutes}`);
    return ret;
  };
  let month_name = showdate(getcheckout[0].showtime, "mname_short");
  let day_name = showdate(getcheckout[0].showtime, "dayname_short");
  let day = showdate(getcheckout[0].showtime, "day");
  let year = showdate(getcheckout[0].showtime, "year");
  let time = showdate(getcheckout[0].showtime, "time");
  return (
    <>
      <AlertMessageComp
        type={alertdata.type}
        msg={alertdata.msg}
        vertical={alertdata.vertical}
        horizontal={alertdata.horizontal}
        setOpen={setAlertopen}
        open={alertopen}
      />
      <div id="printablediv" style={{ display: "none" }} className="forscroll">
        <EmailMyorder data={getcheckout[0]} />
      </div>
      <Grid container spacing={2} p={4}>
        <Grid item md={3} className="azs">
          <Grid item md={12} marginBottom={2}>
            <div className="a">
              <p>My Account</p>
            </div>
          </Grid>
          <span
            style={{
              margin: "20px",
              padding: "10px",
              color: "green",
              fontWeight: "500",
              fontSize: "14px",
              cursor: "pointer",
            }}
            onClick={() => {
              navigate("/myorderhistory");
            }}
          >
            My Order History/Reprint Tickets
          </span>
          <Grid item md={12} marginBottom={2} marginTop={2}>
            <div
              className="a"
              style={{ cursor: "pointer" }}
              onClick={() => {
                navigate("/contact");
              }}
            >
              <p>Contact Us</p>
            </div>
          </Grid>
          <div
            style={{
              display: "none",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <TwitterIcon
              style={{
                marginRight: "20px",
                marginLeft: "20px",
                fontSize: "35px",
              }}
            />
            <FacebookIcon style={{ marginRight: "20px", fontSize: "35px" }} />
            <YouTubeIcon className="youtubeicon" style={{ fontSize: "35px" }} />
          </div>

          <span style={{ fontSize: "14px", marginTop: "15px" }}>
            <span
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              AceChaser.com
              <br />
              Div. Of Ticket Wizard Inc.
              <br />
              Summerside, PE
              <br />
              Canada
              <br />
              (902) 360-2024
            </span>
          </span>
        </Grid>

        <Grid item md={8} marginLeft={0} xs={12}>
          <h2>My Order #{getcheckout[0].orderno}</h2>
          <Grid item md={12} xs={12} className="add">
            <Grid container spacing={2}>
              <Grid item md={5} xs={12}>
                <span
                  style={{
                    marginBottom: "10px",
                    fontSize: "16px",
                  }}
                >
                  Order Date:{getdate(getcheckout[0].edate, 0)} <br />
                  Payment Method: Online
                  <br />
                  Billed To: <br />
                  {getcheckout[0].fname} {getcheckout[0].lname}
                  <br />
                  {getcheckout[0].address}
                  <br />
                  <br />
                  {getcheckout[0].phno}
                </span>
              </Grid>
              <Grid
                item
                md={7}
                xs={12}
                sx={{
                  display: "flex",
                  alignItems: "end",
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "end",
                    justifyContent: "space-between",
                    flexDirection: "column",
                    width: "70%",
                  }}
                >
                  <div>
                    <span
                      style={{
                        fontWeight: "bold",
                      }}
                    >
                      AMOUNT
                    </span>
                  </div>
                  <hr style={{ width: "100%" }} />
                  <div>
                    <span
                      style={{
                        fontWeight: "bold",
                      }}
                    >
                      CONVENIENCE FEE
                    </span>
                  </div>

                  <hr style={{ width: "100%" }} />
                  <div>
                    <span
                      style={{
                        fontWeight: "bold",
                      }}
                    >
                      TOTAL AMOUNT
                    </span>
                  </div>
                  <hr style={{ width: "100%" }} />
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "end",
                    justifyContent: "space-between",
                    width: "25%",
                  }}
                >
                  <div>
                    <span
                      style={{
                        fontWeight: "bold",
                      }}
                    >
                      ${Number(getcheckout[0].price).toFixed(2)}
                    </span>
                  </div>
                  <hr style={{ width: "100%" }} />
                  <div>
                    <span
                      style={{
                        fontWeight: "bold",
                      }}
                    >
                      ${Number(getcheckout[0].conveniencefee).toFixed(2)}
                    </span>
                  </div>
                  <hr style={{ width: "100%" }} />
                  <div>
                    <span
                      style={{
                        fontWeight: "bold",
                      }}
                    >
                      ${Number(getcheckout[0].payment).toFixed(2)}
                    </span>
                  </div>
                  <hr style={{ width: "100%" }} />
                </div>
              </Grid>
            </Grid>
          </Grid>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "20px",
            }}
          >
            <button
              className="btn1"
              type="button"
              onClick={() => {
                ticketPrint();
              }}
            >
              Print Tickets
            </button>
            <button
              className="btn1"
              type="button"
              onClick={() => {
                const htmlEmail = ReactDOMServer.renderToStaticMarkup(
                  <EmailMyorder data={getcheckout[0]} />
                );
                let emaildata = {
                  // emailid: getcheckout[0].email,
                  emailid: logindata[0].emailid,
                  htmlmsg: `${htmlEmail.toString()}`,
                };
                const emailticket = Ticket_email(emaildata);
                let rettostmsg = "";
                if (emailticket.code == 100) {
                  rettostmsg = tostmsg({
                    msg: "Email sending failed. Please try again later.",
                    code: 100,
                  });
                } else {
                  rettostmsg = tostmsg({
                    msg: "Ticket sent successfully on your registered email.",
                    code: 200,
                  });
                }
                setAlertdata(rettostmsg);
                setAlertopen(rettostmsg.open);
              }}
            >
              Email Tickets
            </button>
          </div>
          <Grid item md={12} xs={12} className="ad" marginTop={2}>
            <div
              style={{
                marginBottom: "10px",
                fontSize: "18px",
                fontWeight: "bold",
                backgroundColor: "04243f",
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div className="ticketnav">
                <span style={{ fontSize: "20px" }}>AceChaser</span>
                <p style={{ fontSize: "12px" }}>
                  PRESENT THIS TICKET AT THE EVENT ENTRANCE PRINTED OR ON YOUR
                  MOBILE DEVICE
                </p>
              </div>
            </div>
            <span>Order No. #{getcheckout[0].orderno}</span>
            <div className="ticketcon">
              <div className="a1">
                <h2>{getcheckout[0].shownm}</h2>
                <p>
                  <span style={{ fontWeight: "bold" }}>
                    {" "}
                    {getcheckout[0].fname} {getcheckout[0].lname}
                  </span>
                  <br />
                  <span>
                    {getcheckout[0].address} <br />
                  </span>
                </p>
              </div>
              <div className="a1">
                <p>
                  <span style={{ fontWeight: "bold" }}>
                    {" "}
                    {day_name.toUpperCase()} {day} {month_name.toUpperCase()}{" "}
                    {year}
                    <br />
                    {time}
                    <br />
                    TICKET DETAILS: <br />
                    General Seating <br />
                    - <br />
                    {getcheckout[0].qty} Ticket Pack $
                    {Number(getcheckout[0].payment).toFixed(2)}
                  </span>
                </p>
              </div>
              <div style={{ height: "200px", width: "10%", marginTop: "10px" }}>
                <span style={{ fontWeight: "bold" }}>
                  <img
                    className="mdimg"
                    style={{
                      height: "100%",
                      width: "100%",
                      ObjectFit: "cover",
                    }}
                    src={"barcode"}
                    alt=""
                  />
                  <img
                    className="xsimg"
                    style={{
                      height: "60%",
                      width: "1000%",
                      ObjectFit: "cover",
                    }}
                    src={"barcode2"}
                    alt=""
                  />
                </span>
              </div>
            </div>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default MyOrder;
