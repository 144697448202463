import { Autocomplete, Button, Grid, Paper } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
// import img from "../Assets/img1.webp";
import "./Cart.css";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import LoginComp from "../Login/LoginComp";
import CheckoutPage from "../CheckoutPage/CheckoutPage";
import { UserContext } from "../../Context/UserContext";
import { useInsertionEffect } from "react";
import { useNavigate } from "react-router-dom";
import { calcCountAmount, SaveCart } from "../../API/CartModel";
import { formatDatetime_dmy, formatISOToCustom, getHourTime, IMG_URL, tostmsg } from "../../MIS/Global";
import AlertMessageComp from "../AlertMessageCom/AlertMessageComp";
import defimg from "../../Assets/default-image.jpg";
import styled from "../../CSS/New.module.css";
const Cart = () => {
  const {
    getticket,
    getticket_dispatch,
    getorder,
    setorder_dispatch,
    logindata,
    paybtn,
    setPaybtn,
    cartcount_flag,
    setCartcount_flag,
    timeLeft,
    setTimeLeft,
    cartminutes,
    setCartMinutes,
    isRunning,
    setIsRunning,
    startTimer,
    stopTimer,
  } = useContext(UserContext);
  // alert(isRunning);
  // alert(JSON.stringify(getticket.length));
  if (getticket.length <= 0) {
    window.location = "/";
  }
  const [qty, setQty] = useState();
  const [price, setPrice] = useState();
  const [convfee, setConvfee] = useState();
  const [gtotal, setGtotal] = useState(0.0);
  const [qtylist, setQtylist] = useState([1, 2, 3, 4, 5, 6]);
  const [ticketname, setTicketName] = useState("Single Ticket");
  const [orderarray, setOrderarray] = useState();

  const [modelOpen, setModelOpen] = React.useState(false);
  const handleModelOpen = () => setModelOpen(true);
  const handleClose = () => setModelOpen(false);
  const [ordArray, setOrdArray] = useState({});
  const [imageBlobs, setImageBlobs] = useState([]);

  const handleClose1 = () => {
    setModelOpen(false);
    setModelOpen1(true);
  };
  const [modelOpen1, setModelOpen1] = React.useState(false);
  const handleModelOpen1 = () => setModelOpen1(true);
  const handleClose2 = () => setModelOpen1(false);
  const navigate = useNavigate();

  const [logincart, setLogincart] = useState();

  useEffect(() => {
    let arr = [];     
    qtylist.forEach((element, index) => {
      let tname = "";
      arr.push({
        label: element.toString(),
        id: element,
      });
    });

    setQtylist(arr);
  }, []);
  
  const Qty = [
    { label: "1", id: "1" },
    { label: "2", id: "2" },
    { label: "3", id: "3" },
    { label: "4", id: "4" },
    { label: "5", id: "5" },
    { label: "6", id: "6" },
    { label: "7", id: "7" },
    { label: "8", id: "8" },
    { label: "9", id: "9" },
    { label: "10", id: "10" },
    { label: "11", id: "11" },
    { label: "12", id: "12" },
    { label: "13", id: "13" },
    { label: "14", id: "14" },
    { label: "15", id: "15" },
    { label: "16", id: "16" },
    { label: "17", id: "17" },
    { label: "18", id: "18" },
    { label: "19", id: "19" },
    { label: "20", id: "20" },
  ];
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "white",
    pending: 4,
  };
  const [alertdata, setAlertdata] = React.useState({});
  const [alertopen, setAlertopen] = React.useState(false);
  const todaydatetime = () => {
    let month = new Date(getticket[0]?.show_time).getMonth() + 1;
    if (month.toString().length <= 1) {
      month = `0${month}`;
    }
    let day = new Date(getticket[0].show_time).getDate();
    if (day.toString().length <= 1) {
      day = `0${day}`;
    }
    let hours = new Date(getticket[0].show_time).getHours();
    // if (day.toString().length <= 1) {
    //   day = `0${day}`;
    // }
    let minutes = new Date(getticket[0].show_time).getMinutes();
    let year = new Date(getticket[0].show_time).getFullYear();
    // year = Number(year) - Number(e.target.value);
    // let edate = `${year}-${month}-${day} ${hours}:${minutes}`;
    let edate=getticket[0]?.show_time;
    // alert(edate)
    return edate;
  };
  const todaydatetime_edate = () => {
    let month = new Date().getMonth() + 1;
    if (month.toString().length <= 1) {
      month = `0${month}`;
    }
    let day = new Date().getDate();
    if (day.toString().length <= 1) {
      day = `0${day}`;
    }
    let hours = new Date().getHours();
    // if (day.toString().length <= 1) {
    //   day = `0${day}`;
    // }
    let minutes = new Date().getMinutes();
    let year = new Date().getFullYear();
    // year = Number(year) - Number(e.target.value);
    let edate = `${year}-${month}-${day} ${hours}:${minutes}`;
    // alert(edate)
    return edate;
  };
  const displaydate = (edate) => {
    let mname = new Intl.DateTimeFormat("defualt", { month: "short" }).format(
      new Date(edate)
    );
    let weekday = new Intl.DateTimeFormat("defualt", {
      weekday: "short",
    }).format(new Date(edate));
    let day = new Intl.DateTimeFormat("defualt", { day: "numeric" }).format(
      new Date(edate)
    );
    let year = new Intl.DateTimeFormat("defualt", { year: "numeric" }).format(
      new Date(edate)
    );
    let time = new Intl.DateTimeFormat("defualt", {
      timeStyle: "medium",
    }).format(new Date(edate));

    let showdate = `${mname} ${day} ${weekday} ${time} ${year}`;
    return showdate;
  };
  let logid = sessionStorage.getItem("logid");

  // useEffect(() => {
  //  SaveCart()
  // }, [])
  const handleKeyPress = (event) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);

    // Allow only numeric input (allow backspace and delete keys)
    if (!/^\d$/.test(keyValue) && keyCode !== 8 && keyCode !== 46) {
      event.preventDefault();
    }
  };
  
  return (
    <>
      <AlertMessageComp
        type={alertdata.type}
        msg={alertdata.msg}
        vertical={alertdata.vertical}
        horizontal={alertdata.horizontal}
        setOpen={setAlertopen}
        open={alertopen}
      />
      <Grid
        container
        spacing={2}
        className="forscrolls"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#6E6E6E",

          padding: "10px",
        }}
      >
        {" "}
        <Grid item md={2} xs={12}>
          <div className={styled.head}>Your Order</div>
        </Grid>
        <Grid item md={10} xs={12} className="btngcon">
          <div className="rw1">
            <button
              type="button"
              className="btns"
              style={{ backgroundColor: "#BAFF39", cursor: "pointer" }}
              onClick={() => {
                navigate("/allproduct");
              }}
            >
              Buy ticket for another draw
            </button>

            <button
              color="success"
              type="button"
              className="btns"
              style={{
                display: `${isRunning == true ? "" : "none"}`,
                backgroundColor: "#BAFF39",
                cursor: "pointer",
              }}
              onClick={() => {
                navigate("/yourorder");
              }}
            >
              Pay for tickets now
            </button>
          </div>
        </Grid>
        <div style={{ width: "100%" }}>
          <hr />
        </div>
        <Grid item container xs={12} md={6} mt={-3}>
          <Grid item md={12} xs={12}>
            <h2 style={{ marginLeft: "20px", color: "#e6e6e6" }}>
              {getticket[0]?.showdetail[0].showname} -{" "}
              {`${formatDatetime_dmy(getticket[0]?.show_time,"mmm")}
                ${formatDatetime_dmy(getticket[0]?.show_time,"dd")}
                ${formatDatetime_dmy(getticket[0]?.show_time,"dayname")}
                ${getHourTime(formatISOToCustom(getticket[0]?.show_time),12)}
                ${formatDatetime_dmy(formatISOToCustom(getticket[0]?.show_time),"yyyy")}
              `
                }
            </h2>
          </Grid>
          {getticket[0]?.ticketdet.map((element, index) => {
            return (
              <>
                <Paper
                  elevation={3}
                  sx={{
                    display: "flex",
                    // justifyContent: "center",
                    alignItems: "center",
                    padding: "15px",
                    margin: 1,
                    // gap:"5px"
                  }}
                >
                  <Grid
                    item
                    container
                    md={12}
                    xs={12}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Grid item xs={6} md={3} >

                      {/* <div height="100%"
                        width="100%"
                        style={{backgroundImage:`url(${IMG_URL+getticket[0]?.drawimg})`
                        height:"100%"
                        }}
                        >
                          AD
                      </div> */}

                      <img
                        src={
                          getticket[0]?.drawimg == "-"
                            ? defimg
                            : IMG_URL+getticket[0]?.drawimg
                        }
                        style={{ objectFit: "contain",aspectRatio:"3/2" }}
                        height="100%"
                        width="100%"
                      />
                      

                    </Grid>
                    <Grid
                      item
                      xs={6}
                      md={2.5}
                      sx={{ display: "flex", justifyContent: "center" }}
                    >
                      <span style={{fontWeight:"600"}}>{element.text}</span>
                    </Grid>
                    <Grid item xs={4} md={2}>
                      <span
                        style={{
                          fontWeight: "bold",
                          fontSize: "18px",
                          marginBottom: "10px",
                          marginTop: "10px",
                        }}
                      >
                        ${Number(element.price).toFixed(2)}
                      </span>
                    </Grid>
                    <Grid item xs={4} md={2}>
                      <Autocomplete
                        size="small"
                        freeSolo
                        disableClearable
                        value={qty}
                        options={Qty}
                        onChange={(e,newValue)=>{setQty(newValue?.label);}}
                        sx={{ width: "auto", marginTop: 1, marginBottom: 1 }}                                               
                        renderInput={(params) => (
                          <TextField {...params} label="Qty" 
                            onKeyPress={handleKeyPress}
                            onChange={(e)=>{setQty(e.target.value);}}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={4} md={2.5}>
                      <Button
                        variant="contained"
                        color="secondary"
                        style={{
                          // width: "180px",
                          float: "right",
                          backgroundColor: "#BAFF39",
                          cursor: "pointer",
                          border: "none",
                          fontSize: "18px",
                          color: "black",
                          height: "40px",
                        }}
                        onClick={() => {
                          (async () => {
                            try {
                              let logid = sessionStorage.getItem("logid");
                              // alert(logid);

                              let rettostmsg1 = "";
                              if (qty == undefined) {
                                rettostmsg1 = tostmsg({
                                  msg: "Please enter Quantity",
                                  code: 100,
                                });
                                setAlertdata(rettostmsg1);
                                setAlertopen(rettostmsg1.open);
                                return;
                              }
                              if (qty.length <= 0) {
                                rettostmsg1 = tostmsg({
                                  msg: "Please enter Quantity",
                                  code: 100,
                                });
                                setAlertdata(rettostmsg1);
                                setAlertopen(rettostmsg1.open);
                                return;
                              }
                              // let qtyArry = [];
                              // for (let index = 1; index <= qty; index++) {
                              //   qtyArry.push(index);
                              // }
                              let orderData = {};
                              let insdt = "";
                              // qtyArry.forEach(async (elementqty, index) => {
                              let isconv =
                                getticket[0]?.feestaxdet[0]?.tickettax;
                              // alert(isconv)
                              let calcConvFee = 0;
                              if (isconv == "yes") {
                                if(getticket[0]?.feestaxdet[0]?.additionalfee>0){
                                  let calcper =
                                  (Number(element.price) / Number(100)) *
                                  getticket[0]?.feestaxdet[0]?.additionalfee;
                                calcConvFee = (
                                  Number(qty) * Number(calcper)
                                ).toFixed(2);
                                }else{
                                calcConvFee = (
                                  Number(qty) *
                                  Number(getticket[0]?.feestaxdet[0]?.convfee1)
                                ).toFixed(2);}
                              } 
                              let calcAmount = (
                                Number(qty) * Number(element.price)
                              ).toFixed(2);
                              let calcgtotal = (
                                Number(calcAmount) + Number(calcConvFee)
                              ).toFixed(2);
                               let orderdata1 = {
                                price: element.price,
                                subamount: calcAmount,
                                qty: qty,
                                convfee: calcConvFee,
                                gtotal: calcgtotal,
                                ticketname: element.text,
                                shownm: getticket[0]?.showdetail[0]?.showname,
                                vendernm:
                                  getticket[0]?.showdetail[0]?.vendrname,
                                eventnm: getticket[0]?.showdetail[0]?.eventname,
                                venunm: getticket[0]?.showdetail[0]?.venuname,
                                showid: getticket[0].showid,
                                showtime: todaydatetime(),
                                desctext:getticket[0]?.showdetail[0]?.descrp
                              };

                              let data = {
                                orderdata: orderdata1,
                                uid: logid,
                                edate: todaydatetime_edate(),
                              };
                              setLogincart(data);
                              if (
                                logid == "" ||
                                logid == null ||
                                logid == undefined
                              ) {
                                handleModelOpen();
                                return;
                              }
                              orderData = await SaveCart(data);
                              // if (qtyArry.length - 1 == index) {
                              let rettostmsg = tostmsg(orderData);
                              // alert(JSON.stringify(orderData));
                              setAlertdata(rettostmsg);
                              setAlertopen(rettostmsg.open);
                              if (orderData.code == 100) {
                              }
                              if (orderData.code == 200) {
                                // setIsRunning(true);
                                if (isRunning == false) {
                                  // alert("45");
                                  localStorage.setItem("timerRunning", "true");
                                  localStorage.setItem("timerMinutes", "1");
                                  localStorage.setItem("timerSeconds", "0");
                                }
                                // setTimeout(() => {
                                setQty("");
                                startTimer();
                                calcCountAmount("add", qty, calcAmount);
                                // }, 1000);

                                
                              }
                              // }
                              // });
                            } catch (e) {}
                          })();
                        }}
                      >
                        Add
                      </Button>
                    </Grid>
                  </Grid>
                </Paper>
              </>
            );
          })}
          <Grid
            className="btngcon"
            item
            xs={12}
            md={isRunning == true ? 8.1 : 11.9}
            mb={0.5}
          >
            <button
              className="btns"
              variant="contained"
              onClick={() => {
                navigate("/");
              }}
              style={{
                marginBottom: "10px",
                backgroundColor: "#BAFF39",
                cursor: "pointer",
              }}
            >
              Back to home page
            </button>
          </Grid>
          <Grid
            item
            xs={12}
            md={3.8}
            className="btngcon mdm"
            mb={1.5}
            sx={{
              display: `${isRunning == true ? "" : "none"}`,
            }}
          >
            <button
              type="button "
              className="btns"
              style={{ backgroundColor: "#BAFF39", cursor: "pointer" }}
              onClick={() => {
                navigate("/yourorder");
              }}
            >
              Pay for tickets now
            </button>
          </Grid>
          <Grid item md={12} xs={12} mb={4}></Grid>
        </Grid>
        {/* <Grid item md={4} xs={12} margin={2} sx={{}}>
          <div className="paymentbox">
            <div
              style={{
                display: "flex",
                justifyContent: "space-around",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "start",
                  flexDirection: "column",
                }}

                // style={{ boxShadow: "2px 2px 2px 2px rgba(12,12,12,0.5)" }}
              >
                <span style={{ fontSize: "18px", marginBottom: "10px" }}>
                  TOTAL
                </span>
                <hr style={{ width: "100%", display: "none" }} />
                <span
                  style={{
                    fontSize: "18px",
                    marginBottom: "10px",
                    display: "none",
                  }}
                >
                  DISCOUNT (Enter coupon)
                </span>
                <hr style={{ width: "100%" }} />
                <span style={{ fontSize: "18px", marginBottom: "10px" }}>
                  CONVENIENCE FEE
                </span>
                <hr style={{ width: "100%", display: "none" }} />
                <span
                  style={{
                    fontSize: "18px",
                    marginBottom: "10px",
                    display: "none",
                  }}
                >
                  TAX
                </span>
                <hr style={{ width: "100%", display: "none" }} />
                <span
                  style={{
                    fontSize: "18px",
                    marginBottom: "10px",
                    display: "none",
                  }}
                >
                  DELIVERY
                </span>
                <hr style={{ width: "100%" }} />
                <span
                  style={{
                    fontSize: "18px",
                    marginBottom: "10px",
                    fontWeight: "bold",
                  }}
                >
                  GRAND TOTAL
                </span>
                <hr style={{ width: "100%" }} />
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "end",
                  flexDirection: "column",
                }}
              >
                <span
                  style={{
                    fontWeight: "bold",
                    fontSize: "18px",
                    marginBottom: "10px",
                  }}
                >
                  ${Number(price).toFixed(2)}
                </span>{" "}
                <hr style={{ width: "100%" }} />
                <span
                  style={{
                    fontWeight: "bold",
                    fontSize: "18px",
                    marginBottom: "10px",
                  }}
                >
                  ${Number(convfee).toFixed(2)}
                </span>
                <hr style={{ width: "100%" }} />
                <span
                  style={{
                    fontWeight: "bold",
                    fontSize: "18px",
                    marginBottom: "10px",
                  }}
                >
                  ${gtotal}
                </span>
              </div>
            </div>
          </div>
        </Grid>
        <Grid item md={2} xs={6} sx={{}}>
          <button
            style={{
              width: "150px",
              padding: "10px 10px",
              float: "right",
              backgroundColor: "#00a6ff",
              color: "white",
              cursor: "pointer",
              border: "none",
              fontSize: "18px",
              margin: "20px",
            }}
            onClick={() => {
              window.location.href = "/";
            }}
          >
            Clear Order
          </button>
        </Grid>
        <Grid item md={2} xs={6} sx={{}}>
          <button
            style={{
              width: "180px",
              padding: "10px 10px",
              float: "right",
              backgroundColor: "#00a6ff",
              color: "white",
              cursor: "pointer",
              border: "none",
              fontSize: "18px",
              margin: "20px",
            }}
            onClick={() => {
              let logid = sessionStorage.getItem("logid");
              if (logid == "") {
                handleModelOpen();
              } else {
                // alert(JSON.stringify(logindata));
                navigate("/checkout");
              }
            }}
          >
            Checkout
          </button>
        </Grid> */}
      </Grid>
      <Modal
        open={modelOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="muicont">
          {/* <Typography id="modal-modal-title" variant="h6" component="h2">
            <CloseIcon onClick={handleClose} style={{ float: "right" }} />
          </Typography> */}

          <Typography id="modal-modal-description">
            <LoginComp
              handleClose={handleClose}
              SaveCart={SaveCart}
              logincart={logincart}
            />
          </Typography>
        </Box>
      </Modal>
      <Modal
        open={modelOpen1}
        onClose={handleClose2}
        aria-labelledby="modal-modal-title1"
        aria-describedby="modal-modal-description1"
      >
        <Box style={style}>
          {/* <Typography id="modal-modal-title" variant="h6" component="h2">
         <CloseIcon onClick={handleClose} style={{ float: "right" }} />
       </Typography> */}
          <Typography id="modal-modal-description1">
            <CheckoutPage />
          </Typography>
        </Box>
      </Modal>
    </>
  );
};

export default Cart;
