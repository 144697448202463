// export const BASE_URL = "http://localhost:4000/api/";
// export const IMG_URL = "http://localhost:4000/";

// export const BASE_URL = "//acebk.playspgames.com/api/";
// export const IMG_URL = "https://acebk.playspgames.com/";

export const BASE_URL = "//acebackend.acechaser.ca/api/";
export const IMG_URL = "https://acebackend.acechaser.ca/";

export const genotp =
  Math.floor(Math.random() * (9 * Math.pow(10, 6 - 1))) + Math.pow(10, 6 - 1);
export const email_send = "durgaprasadkadam@gmail.com";
export const alphanumeric = async (e) => {
  return new Promise((resolve, reject) => {
    let re = /^[a-zA-Z0-9\b\s]+$/;
    if (e.target.value === "" || re.test(e.target.value)) {
      resolve(e.target.value);
      //setName(e.target.value);
    }
    // resolve("");
  });
};

export const onlynumeric = async (e) => {
  return new Promise((resolve, reject) => {
    let re = /^[0-9\b]+$/;
    if (e.target.value === "" || re.test(e.target.value)) {
      resolve(e.target.value);
      //setName(e.target.value);
    }
  });
};
export const onlydecimal = async (e) => {
  return new Promise((resolve, reject) => {
    let re = /^[0-9-.\b]+$/;
    if (e.target.value === "" || re.test(e.target.value)) {
      resolve(e.target.value);
      //setName(e.target.value);
    }
  });
};

export const bulkins_value = async (jsondata) => {
  let bulkins = "";
  jsondata.forEach((element) => {
    bulkins += `('${element}',`;
  });
  bulkins = bulkins.trimEnd(",");
  bulkins += bulkins + ")";
  return bulkins;
};

export const tostmsg = (data) => {
  if (data.code == 100) {
    return {
      type: "error",
      msg: data.msg,
      vertical: "top",
      horizontal: "center",
      open: true,
    };
  } else if (data.code == 200) {
    return {
      type: "success",
      msg: data.msg,
      vertical: "top",
      horizontal: "center",
      open: true,
    };
  } else if (data.code == 400) {
    return {
      type: "warning",
      msg: data.msg,
      width: "300px",
      height: "200px",
      vertical: "top",
      horizontal: "center",
      open: true,
    };
  } else if (data.code == 300) {
    return {
      type: "error",
      msg: data.msg,
      vertical: "top",
      horizontal: "center",
      open: true,
    };
  } else if (data.code == 500) {
    return {
      type: "error",
      msg: data.msg,
      vertical: "top",
      horizontal: "center",
      open: true,
    };
  }
};

export const getHourTime = (dateTime, ishour) => {
  let bal = false;
  if (ishour.toString() === "12".toString()) {
    bal = true;
  } else {
    bal = false;
  }
  let curDate = new Date(dateTime);
  const timeString = curDate.toLocaleTimeString("en-US", {
    hour: "numeric",
    minute: "numeric",
    hour12: bal,
  });
  return timeString;
};

export const formatDatetime_dmy = (date, pmday) => {
  const currentDate = new Date(date);
  let formattedDate="";
  if(pmday==="mm"){
    formattedDate = new Intl.DateTimeFormat('en-US', {
       month: '2-digit',
     }).format(currentDate);
  }else if(pmday==="mmm"){
    formattedDate = new Intl.DateTimeFormat('en-US', {      
      month: 'short',      
    }).format(currentDate);
  }else if(pmday==="dd"){
    formattedDate = new Intl.DateTimeFormat('en-US', {      
      day: '2-digit',      
    }).format(currentDate);
  }else if(pmday==="yyyy"){
    formattedDate = new Intl.DateTimeFormat('en-US', {      
      year: 'numeric',      
    }).format(currentDate);
  }else if(pmday==="dayname"){
    formattedDate = new Intl.DateTimeFormat('en-US', {      
      weekday: 'short'    
    }).format(currentDate);
  }else if(pmday==="dmy"){
    formattedDate = new Intl.DateTimeFormat('en-US', {      
      day:"2-digit",
      month:"2-digit",
      year: 'numeric'    
    }).format(currentDate);
    const [day, month, year] = formattedDate.split('/');
    formattedDate = `${day}/${month}/${year}`;
  }

  return formattedDate;
};

export const formatISOToCustom=(dateString)=> {
  // Parse the ISO string to a Date object
  const date = new Date(dateString);

  // Extract the components of the date
  const year = date.getUTCFullYear();
  const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Months are zero-based
  const day = String(date.getUTCDate()).padStart(2, '0');
  const hours = String(date.getUTCHours()).padStart(2, '0');
  const minutes = String(date.getUTCMinutes()).padStart(2, '0');
  const seconds = String(date.getUTCSeconds()).padStart(2, '0');

  // Combine the components into the desired format
  const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;

  return formattedDate;
}

export const formatISOToCustom_WOTime=(dateString)=> {
  // Parse the ISO string to a Date object
  const date = new Date(dateString);

  // Extract the components of the date
  const year = date.getUTCFullYear();
  const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Months are zero-based
  const day = String(date.getUTCDate()).padStart(2, '0');
  const hours = String(date.getUTCHours()).padStart(2, '0');
  const minutes = String(date.getUTCMinutes()).padStart(2, '0');
  const seconds = String(date.getUTCSeconds()).padStart(2, '0');

  // Combine the components into the desired format
  const formattedDate = `${year}-${month}-${day}`;

  return formattedDate;
}