import React from "react";

const Terms = () => {
  return (
    <div
      className="forscroll"
      style={{ marginLeft: "20px", marginRight: "10px", fontFamily: "Roboto" }}
    >
      <div style={{ marginBottom: "40px" }}>
        <h1 style={{ color: "rgb(153, 51, 255)" }}>Terms of services</h1>
        <h3>Ticket Delivery</h3>
        <p>
          All Print at Home tickets will be emailed directly to the ticket
          purchaser within 24 -36 hours. In the event that the ticket purchaser
          has selected delivery via Canada Post, tickets will be delivered with
          7-10 business days * All Canada Post deliveries are in accordance to
          published delivery policies *
        </p>
        <h3>Refund Policy</h3>
        <p>
          Unfortunately, your tickets are non-exchangeable and non-refundable.
        </p>
      </div>
    </div>
  );
};

export default Terms;
