import React, { createContext, useEffect, useState, useReducer } from "react";
import { useNavigate } from "react-router-dom";
import { Passwordstore } from "../Reducer/ForgotPswReducer";
import { GetTicketReducer } from "../Reducers/DrawReducer";
import { LoginReducer } from "../Reducers/LoginReducer";
import { GetCheckoutReducer, GetOrderReducer } from "../Reducers/OrderReducer";
import { TimerReducer } from "../Reducers/TimerReducer";
import { calcCountAmount, DeleteCart, GetCart } from "../../src/API/CartModel";

export const UserContext = createContext({});

export const UserContextProvider = ({ children }) => {
  const navigate = useNavigate();
  const [getticket, getticket_dispatch] = useReducer(GetTicketReducer, []);
  const [getorder, setorder_dispatch] = useReducer(GetOrderReducer, []);
  const [getcheckout, setcheckout_dispatch] = useReducer(
    GetCheckoutReducer,
    []
  );
  const [logindata, setlogindata_dispatch] = useReducer(LoginReducer, []);
  const [carttimer, setcarttimer_dispatch] = useReducer(TimerReducer, []);
  const [pswinputdisplya, setPswinputdisply] =
    Passwordstore.useState("pswinputdisplya");

  const [paybtn, setPaybtn] = useState("none");
  const [cartcount_flag, setCartcount_flag] = useState(false);
  const [timeLeft, setTimeLeft] = useState(0);
  let lastmin = localStorage.getItem("lastmin");
  let lastsec = localStorage.getItem("lastsec");
  // alert(lastsec)
  const [cartminutes, setCartMinutes] = useState(lastmin || 0);
  const [cartseconds, setCartSeconds] = useState(lastsec || 0);

  const [minutes, setMinutes] = useState(
    localStorage.getItem("timerMinutes") || 1
  );
  const [seconds, setSeconds] = useState(
    localStorage.getItem("timerSeconds") || 0
  );
  const [isRunning, setIsRunning] = useState(
    localStorage.getItem("timerRunning") == "false" ? false : true
  );
  const [storedValue, setStoredValue] = useState(
    localStorage.getItem("timerValue") || ""
  );

  useEffect(() => {
    // alert(localStorage.getItem("timerRunning"));
    // alert("###" + minutes);
    if (isRunning) {
      const interval = setInterval(() => {
        if (seconds > 0) {
          localStorage.setItem("timerSeconds", seconds - 1);
          setSeconds(seconds - 1);
        } else if (minutes > 0) {
          localStorage.setItem("timerSeconds", 59);
          localStorage.setItem("timerMinutes", minutes - 1);
          setMinutes(minutes - 1);
          setSeconds(59);
        } else {
          // alert("");
          let logid = sessionStorage.getItem("logid");
          clearInterval(interval);
          localStorage.setItem("timerRunning", "false");
          localStorage.setItem("timerMinutes", "10");
          localStorage.setItem("timerSeconds", "0");
          localStorage.setItem("lastmin", "0");
          localStorage.setItem("lastsec", "1");
          localStorage.removeItem("lasttot");
          localStorage.removeItem("lastcnt");
          DeleteCart({ uid: logid });
          calcCountAmount("cls", 0, 0);

          setIsRunning(false);
          setMinutes(1);
          setSeconds(0);
          window.location.reload();
        }
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [isRunning, minutes, seconds]);

  useEffect(() => {
    localStorage.setItem("timerValue", `${minutes}:${seconds}`);
  }, [minutes, seconds]);

  function startTimer() {
    localStorage.setItem("timerRunning", "true");
    setIsRunning(true);
  }

  function stopTimer() {
    let logid = sessionStorage.getItem("logid");
    localStorage.setItem("timerRunning", "false");
    localStorage.setItem("timerMinutes", "10");
    localStorage.setItem("timerSeconds", "0");
    localStorage.setItem("lastmin", "0");
    localStorage.setItem("lastsec", "1");
    localStorage.setItem("cart_count","0") ;
    localStorage.removeItem("lasttot");
    localStorage.removeItem("lastcnt");
    DeleteCart({ uid: logid });
    calcCountAmount("cls", 0, 0);

    setIsRunning(false);
    setMinutes(10);
    setSeconds(0);
    // Window.location.reload();
  }
  // if(lastmin!=null){
  //   setCartMinutes(lastmin);
  // }
  // if(lastsec!=null){
  //   setCartSeconds(lastsec);
  // }
  const value = {
    getticket,
    getticket_dispatch,
    getorder,
    setorder_dispatch,
    getcheckout,
    setcheckout_dispatch,
    logindata,
    setlogindata_dispatch,
    carttimer,
    setcarttimer_dispatch,
    pswinputdisplya,
    setPswinputdisply,
    paybtn,
    setPaybtn,
    cartcount_flag,
    setCartcount_flag,
    timeLeft,
    setTimeLeft,
    cartminutes,
    setCartMinutes,
    cartseconds,
    setCartSeconds,
    minutes,
    setMinutes,
    seconds,
    setSeconds,
    isRunning,
    setIsRunning,
    startTimer,
    stopTimer,
    storedValue,
    setStoredValue,
  };
  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

export default UserContextProvider;
