import { Box, Grid } from "@mui/material";
import React from "react";
import style from "../Achivements/achievement.module.css";
const Achievement = () => {
  return (
    <Box className={style.main}>
      <Grid container spacing={2}>
        
      </Grid>
    </Box>
  );
};

export default Achievement;
