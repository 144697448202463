import { Alert, Button, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import style from "./Location.module.css";

const LocationPage = (props) => {
  const [selectedbtn, setSelectedbtn] = useState(null);
  const [locationinfo, setLocationInfo] = useState(
    "Your location not enabled! Please make sure your location is enabled."
  );

  const handleDivClick = (index) => {
    setSelectedbtn(index === selectedbtn ? null : index);
  };
  const [currLocation, setCurrLocation] = useState({});
  const requestGeolocationPermission = async () => {
    setLocationInfo("");
    try {
      const permissionStatus = await navigator.permissions.query({
        name: "geolocation",
      });

      if (permissionStatus.state === "granted") {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const { latitude, longitude } = position.coords;
            setCurrLocation({ latitude, longitude });
            setLocationInfo("");
          },
          (error) => {
            console.error("Error getting current location:", error);
          }
        );
      } else {
        // Execute this block only when state is not 'prompt'
        navigator.geolocation.getCurrentPosition(
          (position) => {
            // console.log('Geolocation permission granted by user');
            const { latitude, longitude } = position.coords;
            setCurrLocation({ latitude, longitude });
            setLocationInfo("");
            // Perform actions with the granted permission and coordinates
          },
          (error) => {
            // Show a notification for the error
            if (Notification.permission === "granted") {
              new Notification("Error", {
                body: "Error getting current location.",
              });
            }
          }
        );
      }
    } catch (error) {
      console.error("Error checking geolocation permission:", error);
    }
  };
  useEffect(() => {
    requestGeolocationPermission();
  }, []);

  useEffect(() => {
    // if(locationinfo!=""){return;}
    const isCurrLocationEmpty = Object.keys(currLocation).length === 0;
    let latlong = {
      minLatitude: 15.602125,
      maxLatitude: 22.719568,
      minLongitude: 72.659669,
      maxLongitude: 80.859673,
    };
    let islocationPEL = false;
    if (isCurrLocationEmpty) {
      setLocationInfo(
        "Your location not enabled! Please make sure your location is enabled."
      );
      return;
    }
    if (selectedbtn === 0) {
      latlong = {
        minLatitude: 46.1784, // Replace with actual minimum latitude for Prince Edward Island
        maxLatitude: 47.0493, // Replace with actual maximum latitude for Prince Edward Island
        minLongitude: -64.5896, // Replace with actual minimum longitude for Prince Edward Island
        maxLongitude: -62.9922,
      };
      islocationPEL =
        currLocation.latitude >= latlong.minLatitude &&
        currLocation.latitude <= latlong.maxLatitude &&
        currLocation.longitude >= latlong.minLongitude &&
        currLocation.longitude <= latlong.maxLongitude;
      if (islocationPEL === true) {
        props?.setLocationName(true);
        setLocationInfo("");
      } else {
        props?.setLocationName(false);
        setLocationInfo("Your current location is outside Prince Edward Island");
      }
    } else if (selectedbtn === 1) {
      latlong = {
        minLatitude: 43.4114, // Replace with actual minimum latitude for Nova Scotia
        maxLatitude: 47.5651, // Replace with actual maximum latitude for Nova Scotia
        minLongitude: -66.3272, // Replace with actual minimum longitude for Nova Scotia
        maxLongitude: -59.7416, // R
      };
      islocationPEL =
        currLocation.latitude >= latlong.minLatitude &&
        currLocation.latitude <= latlong.maxLatitude &&
        currLocation.longitude >= latlong.minLongitude &&
        currLocation.longitude <= latlong.maxLongitude;
      if (islocationPEL === true) {
        props?.setLocationName(true);
        setLocationInfo("");
      } else {
        props?.setLocationName(false);
        setLocationInfo("Your current location is outside Nova Scotia");
      }
    } else if (selectedbtn === 2) {
      latlong = {
        minLatitude: 44.5876, // Replace with actual minimum latitude for New Brunswick
        maxLatitude: 48.0518, // Replace with actual maximum latitude for New Brunswick
        minLongitude: -69.0531, // Replace with actual minimum longitude for New Brunswick
        maxLongitude: -63.1107,
      };
      islocationPEL =
        currLocation.latitude >= latlong.minLatitude &&
        currLocation.latitude <= latlong.maxLatitude &&
        currLocation.longitude >= latlong.minLongitude &&
        currLocation.longitude <= latlong.maxLongitude;
      if (islocationPEL === true) {
        props?.setLocationName(true);
        setLocationInfo("");
      } else {
        props?.setLocationName(false);
        setLocationInfo("Your current location is outside New Brunswick");
      }
    } else if (selectedbtn === 3) {
      latlong = {
        minLatitude: 46.6034, // Replace with actual minimum latitude for Newfoundland and Labrador
        maxLatitude: 51.7092, // Replace with actual maximum latitude for Newfoundland and Labrador
        minLongitude: -60.2546, // Replace with actual minimum longitude for Newfoundland and Labrador
        maxLongitude: -52.7934,
      };
      islocationPEL =
        currLocation.latitude >= latlong.minLatitude &&
        currLocation.latitude <= latlong.maxLatitude &&
        currLocation.longitude >= latlong.minLongitude &&
        currLocation.longitude <= latlong.maxLongitude;
      if (islocationPEL === true) {
        props?.setLocationName(true);
        setLocationInfo("");
      } else {
        props?.setLocationName(false);
        setLocationInfo("Your current location is outside Newfoundland and Labrador");
      }
    } else {
      if (isCurrLocationEmpty) {
        setLocationInfo(
          "Your location not enabled! Please make sure your location is enabled."
        );
      } else {
        setLocationInfo("Please select your province.");
      }
    }
  }, [selectedbtn]);
  return (
    <div>
      <Grid container spacing={1}>
        <Grid item xs={12} md={12}>
          {/* <div className={style.locationhead}>Location</div> */}
          <Button type ="button" variant="contained" color="warning" onClick={(e)=>{props?.setLocationName(true)}}>SKIP</Button>
          <hr />
        </Grid>
        <Grid item xs={12} md={12} className={style.positionhead}>
          <div className={style.heading}>Click on your location</div>
        </Grid>
        <Grid
          item
          container
          xs={12}
          md={12}
          sx={{ display: `${locationinfo === "" ? "none" : ""}` }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Alert severity="warning" sx={{ fontWeight: "bold" }}>
              {locationinfo}
            </Alert>
          </div>
        </Grid>
        <Grid item xs={12} md={12} className={style.positionhead}>
          <div className={style.locationbox}>
            <div className={style.locationmain}>
              <div
                className={`${style.locationsub} ${
                  selectedbtn === 0 ? style.selected : ""
                }`}
                onClick={() => handleDivClick(0)}
              >
                <div className={style.boxheading}>Prince Edward Island</div>
              </div>
              <div
                className={`${style.locationsub} ${
                  selectedbtn === 1 ? style.selected : ""
                }`}
                onClick={() => handleDivClick(1)}
              >
                <div className={style.boxheading}>Nova Scotia</div>
              </div>
            </div>
            <div className={style.locationmain}>
              <div
                className={`${style.locationsub} ${
                  selectedbtn === 2 ? style.selected : ""
                }`}
                onClick={() => handleDivClick(2)}
              >
                <div className={style.boxheading}>New Brunswick</div>
              </div>
              <div
                className={`${style.locationsub} ${
                  selectedbtn === 3 ? style.selected : ""
                }`}
                onClick={() => handleDivClick(3)}
              >
                <div className={style.boxheading}>Newfoundland Labrador</div>
              </div>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default LocationPage;
