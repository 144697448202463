import React, { useEffect, useRef, useState } from "react";
import NavbarComp from "../../Component/Navbar/NavbarComp";
import FooterComp from "../../Component/Footer/FooterComp";
import ContactusComp from "../../Component/ContactUs/ContactusComp";
import Cart from "../../Component/CartPage/Cart";
import CardDetail from "../../Component/CardDetail/CardDetail";
import NewtestProduct from "../../Component/Product/NewtestProduct";
import style from './APScreen.module.css';
import { Grid } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import TwitterIcon from "@mui/icons-material/Twitter";
import FacebookIcon from "@mui/icons-material/Facebook";
import YouTubeIcon from "@mui/icons-material/YouTube";
const AllProductScreen = () => {
  const navigate = useNavigate();
   
  return (
    <>
      {/* <div className="navbar">
        <NavbarComp />
      </div>
      <div ref={divRef} style={{flex:1}}>
        <NewtestProduct />
      </div>
      <div style={{bottom:0,backgroundColor:"yellow",position:"unset",width:"100%",display:"flex",justifyContent:"center"}}>
          <h6> {heightPercentage}</h6>
      </div> */}
      <div className={style.container}>
       <header>
       <NavbarComp />
       </header>
      <main className={style.main_content}>
      <NewtestProduct />
      </main>
      <footer className={style.footer}>      
            <Grid
              container
              spacing={0}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
                backgroundColor: "#383838",
              }}
            >
              <Grid
                item
                xs={4}
                md={4}
                className="footcon"
                onClick={() => {
                  navigate("/about");
                }}
              >
                <span className="footcontext">How It Works</span>
              </Grid>
              <Grid
                item
                xs={4}
                md={4}
                className="footcon"
                onClick={() => {
                  navigate("/whoweare");
                }}
              >
                <span className="footcontext">Who We Are</span>
              </Grid>
              <Grid item xs={4} md={4} className="footcon">
                <Link
                  to="/"
                  style={{
                    textDecoration: "none",
                    cursor: "pointer",
                    color: "white",
                  }}
                >
                  <span className="footcontext"> Return to Homepage</span>
                </Link>
              </Grid>
              <Grid
                item
                xs={12}
                md={12}
                sx={{
                  // width: "100vw",
                  backgroundColor: "#383838",
                }}
              >
                <Grid
                  item
                  xs={12}
                  md={12}
                  className="footcon"
                  sx={{ display: "none" }}
                >
                  <Grid
                    item
                    xs={12}
                    md={12}
                    sx={{
                      padding: "40px",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <span>
                      <h3>What We Do</h3>
                    </span>
                    <span>For Fans</span>
                    <span>For Venues</span>
                    <span>For Artists</span>
                    <span>Product Introduction</span>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={12}
                    sx={{
                      padding: "40px",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <span>
                      <h3>Who We Are</h3>
                    </span>
                    <span>About Us</span>
                    <span>In the News</span>
                    <span>Careers</span>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={12}
                    sx={{
                      padding: "40px",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <span>
                      <h3>Contact With Us</h3>
                    </span>
                    {/* <span>Contact Us</span>
            <span>Twitter</span>
            <span>Facebook</span>
            <span>YouTube</span> */}
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "start",
                        alignItems: "center",
                      }}
                    >
                      <TwitterIcon
                        style={{
                          marginRight: "28px",
                          fontSize: "35px",
                          color: "#fff",
                        }}
                      />
                      <FacebookIcon
                        style={{
                          marginRight: "28px",
                          fontSize: "35px",
                          color: "#fff",
                        }}
                      />
                      <YouTubeIcon
                        style={{
                          marginRight: "30px",
                          fontSize: "35px",
                          color: "#fff",
                        }}
                      />
                    </div>
                  </Grid>
                </Grid>
                <Grid
                  className="footcon3"
                  item
                  xs={12}
                  md={12}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <span>
                    <span
                      style={{ fontSize: "14px", cursor: "pointer" }}
                      onClick={() => {
                        navigate("/policy");
                      }}
                    >
                      Privacy Policy
                    </span>
                    {/* &nbsp;|&nbsp; */}
                    {/* <span style={{ fontSize:"14px",cursor:"pointer"}} onClick={()=>{navigate("/tc")}}>Terms of Service</span> */}
                  </span>
                  <span style={{ fontSize: "14px" }}>
                    AceChaser.com All rights reserved.
                  </span>
                  <span
                    className="footerspoan"
                    style={{ color: "black", fontSize: "14px" }}
                  >
                    &nbsp; &nbsp; &nbsp; A division of Ticket Wizard Inc.&nbsp;
                    &nbsp; &nbsp;
                  </span>
                </Grid>
              </Grid>
            </Grid>
       </footer>
    </div>
    </>
  );
};

export default AllProductScreen;
