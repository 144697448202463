import React from "react";
import NavbarComp from "../../Component/Navbar/NavbarComp";
import FooterComp from "../../Component/Footer/FooterComp";
import ForgotPassCom from "../../Component/ForgotPass/ForgotPassCom";

const ForgotPassScreen = () => {
  return (
    <div>
      <div className="navbar">
        <NavbarComp />
      </div>
      <div className="comp">
        <ForgotPassCom />
      </div>
      <div className="footer">
        <FooterComp />
      </div>
    </div>
  );
};

export default ForgotPassScreen;
