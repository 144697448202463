import {
  Box,
  Button,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { GetOrderGroup } from "../../API/OrderModel";
import AlertMessageComp from "../AlertMessageCom/AlertMessageComp";
import "../../CSS/bg.css";
import Style from "../../CSS/New.module.css";
import { formatDatetime_dmy, formatISOToCustom, getHourTime, tostmsg } from "../../MIS/Global";

const GroupOrder = () => {
  const navigate = useNavigate();
  const [order, setOrder] = useState([]);
  const [hover, setHover] = useState("none");
  const [printdata, SetPrintdata] = useState([]);
  const [alertdata, setAlertdata] = React.useState({});
  const [alertopen, setAlertopen] = React.useState(false);
  let logid = sessionStorage.getItem("logid");
  // alert(JSON.stringify(logindata));
  if (logid.length <= 0) {
    window.location = "/";
  }
  let tabledata = {};
  const getData = async () => {
    let customwhr = "";
    customwhr += ` and uid = '${logid}'`;
    customwhr += " group by iscart,shownm order by edate desc";
    tabledata = await GetOrderGroup({ customwhr: customwhr });
    // alert(JSON.stringify(tabledata));

    if (tabledata.code == 300) {
      let rettostmsg = tostmsg({
        code: 400,
        msg: "Your order not found",
      });
      setAlertdata(rettostmsg);
      setAlertopen(rettostmsg.open);
      setTimeout(() => {
        navigate("/allproduct");
      }, 2000);
    } else {
      setOrder(tabledata.data);
      SetPrintdata(tabledata.data[0]);
    }
  };
  const odate = (edate) => {
    let option = {
      day: '2-digit', month: '2-digit', year: 'numeric'
    };
    const eDate1 = new Date(edate); // Replace this with your date
    const day = String(eDate1.getDate()).padStart(2, '0');
    const month = String(eDate1.getMonth() + 1).padStart(2, '0');
    const year = eDate1.getFullYear();
  
    const formattedDate = `${day}/${month}/${year}`
    let res_edate = formattedDate;
    return res_edate;
  };

  const showdate = (edate) => {
    let option = {
      day: '2-digit', month: '2-digit', year: 'numeric'
    };
    const eDate1 = new Date(edate); // Replace this with your date
    const day = String(eDate1.getDate()).padStart(2, '0');
    const month = String(eDate1.getMonth() + 1).padStart(2, '0');
    const year = eDate1.getFullYear();
  
    const formattedDate = `${day}/${month}/${year}`;
    let res_edate = formattedDate;
    let time_det = new Intl.DateTimeFormat("defualt", {
      timeStyle: "medium",
    }).format(new Date(edate));

    return res_edate + " " + time_det;
  };

  useEffect(() => {
    getData();
  }, []);
  return (
    <>
      <AlertMessageComp
        type={alertdata.type}
        msg={alertdata.msg}
        vertical={alertdata.vertical}
        horizontal={alertdata.horizontal}
        setOpen={setAlertopen}
        open={alertopen}
      />
      <Grid
        container
        spacing={2}
        sx={{ marginBottom: "20px" }}
        className="bg1 scroll2"
      >
        <Grid item md={12} xs={12}>
          <div className={Style.head}>My Order</div>
          <hr style={{ width: "100%" }} />
        </Grid>

        <Grid item xs={12} md={8}>
          <TableContainer
            className="tblcontainer "
            sx={{ maxHeight: "41vh", maxWidth: "100vw" }}
          >
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <TableCell
                    sx={{
                      backgroundColor: "#383838",
                      fontWeight: "bold",
                      color: "white",
                    }}
                  >
                    ORDER DATE
                  </TableCell>
                  <TableCell
                    sx={{
                      backgroundColor: "#383838",
                      fontWeight: "bold",
                      color: "white",
                    }}
                  >
                    ORDER DETAILS
                  </TableCell>
                  <TableCell
                    sx={{
                      backgroundColor: "#383838",
                      fontWeight: "bold",
                      color: "white",
                    }}
                  >
                    ORDER TOTAL
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody sx={{ backgroundColor: "white" }}>
                {order?.map((element) => {
                  return (
                    <TableRow>
                      <TableCell>{odate(element.edate)}</TableCell>
                      <TableCell>
                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <span style={{ fontWeight: "bold" }}>
                            {/* { showdate(element.showtime)} {element.shownm} */}
                           { `${formatDatetime_dmy(element?.showtime,"dd")}/${formatDatetime_dmy(element?.showtime,"mm")}/${formatDatetime_dmy(element?.showtime,"yyyy")}
                               ${getHourTime(formatISOToCustom(element?.showtime),12)}
                               ${element.shownm}
                            `}

                            <span style={{ fontWeight: "normal",display:"none" }}>
                              ({element.tickets} tickets)
                            </span>
                          </span>
                          <a
                            href="/myorderhistory"
                            onClick={() => {
                              localStorage.setItem("iscart", element.iscart);
                            }}
                          >
                            Details/Reprint or Resend Tickets
                          </a>
                        </div>
                      </TableCell>
                      <TableCell sx={{ textAlign: "right" }}>
                        ${Number(element.tot).toFixed(2)}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Grid item xs={12} md={4}>
          <Paper elevation={4}>
            <Grid
              item
              container
              xs={12}
              md={12}
              sx={{
                backgroundColor: "white",
                borderRadius: "8px",
                height: "auto",
                padding: "10px",
              }}
            >
              <Grid item xs={12} md={12} sx={{}}>
                <div
                  style={{
                    backgroundColor: "#383838",
                    color: "white",
                    height: "30px",
                    paddingLeft: "10px",
                    fontSize: 18,
                  }}
                >
                  My Account
                </div>
              </Grid>
              <Grid
                item
                xs={12}
                md={12}
                sx={{ display: "flex", marginTop: "10px", paddingLeft: "10px" }}
              >
                <div
                  style={{
                    color: "#000",
                    cursor: "pointer",
                    marginTop: "-10px",
                  }}
                  onClick={() => {
                    navigate("/changepassword");
                  }}
                >
                  Change Password
                </div>
              </Grid>
            </Grid>
            <Grid
              item
              container
              xs={12}
              md={12}
              sx={{
                backgroundColor: "white",
                borderRadius: "8px",
                padding: "10px",
                marginTop: "20px",
              }}
            >
              <Grid item xs={12} md={12}>
                <div
                  style={{
                    backgroundColor: "#383838",
                    color: "white",
                    height: "30px",
                    paddingLeft: "10px",
                    marginTop: "-25px",
                    fontSize: 18,
                  }}
                >
                  Contact with us
                </div>
              </Grid>
              <Grid item xs={12} md={12}>
                <div
                  style={{
                    marginTop: "10px",
                    display: "flex",
                    flexDirection: "column",
                    paddingLeft: "20px",
                  }}
                >
                  <span>AceChaser.com</span>
                  <span>Div. Of Ticket Wizard Inc.</span>
                  <span>Summerside, PE</span>
                  <span>Canada</span>
                  <span>(902) 360-2024</span>
                </div>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};

export default GroupOrder;
