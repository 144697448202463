import { Button, Grid, Paper, styled, TextField } from "@mui/material";
import React, { useContext, useState } from "react";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import { useNavigate } from "react-router-dom";
import AlertMessageComp from "../AlertMessageCom/AlertMessageComp";
import { tostmsg } from "../../MIS/Global";
import { updatepassword } from "../../API/LoginModel";
import { UserContext } from "../../Context/UserContext";
import { DeleteCart } from "../../API/CartModel";
import "../../CSS/bg.css";
import style from "../../CSS/New.module.css";

const CssTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: "white",
  },
  "& label": {
    color: "white",
  },
  "& fontColor": {
    color: "white",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "white",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "white",
    },
    "&:hover fieldset": {
      borderColor: "white",
    },
    "&.Mui-focused fieldset": {
      borderColor: "white",
    },
  },
});

const ChangePswComp = () => {
  const [logid, setLogid] = useState(sessionStorage.getItem("logid"));
  const [paswd, setPaswd] = useState("");
  const [cpaswd, setCPaswd] = useState("");
  const navigate = useNavigate();
  const [alertdata, setAlertdata] = React.useState({});
  const [alertopen, setAlertopen] = React.useState(false);
  const { logindata, setlogindata_dispatch } = useContext(UserContext);
  // alert(JSON.stringify(logindata))
  let data = {
    logid: logid,
    paswd: paswd,
  };
  return (
    <div className="">
      <AlertMessageComp
        type={alertdata.type}
        msg={alertdata.msg}
        vertical={alertdata.vertical}
        horizontal={alertdata.horizontal}
        setOpen={setAlertopen}
        open={alertopen}
      />
      <Grid
        container
        spacing={1}
        sx={{
          display: "flex",

          marginTop: "15px",
        }}
        className="bg2"
      >
        
         <Grid
          item
          xs={12}
          md={12}
          sx={{ padding: "10px", marginBottom: "20px" }}
        >
          {/* <Paper elevation={4} sx={{padding:"10px"}}> */}
          <Grid item xs={12} md={12} mt={-2}>
          <div style={{display:"flex",justifyContent:"space-between"}}>
          <div className={style.head}>Change Password</div>
          <div style={{color:"#fff",fontSize:"14px",alignItems:"baseline"}}>
              {logindata?logindata[0]?.uname:""}, {logindata?logindata[0]?.emailid:""}
          </div>
          </div>
            
            <hr />
          </Grid>
          <Grid
            item
            xs={12}
            md={12}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              className="forwidth"
              style={{
                marginTop: "20px",
                // backgroundColor: "white",
                borderRadius: "8px",
                padding: "10px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <CssTextField
                fullWidth
                size="small"
                id="outlined-password-input"
                label="New Password"
                type="text"
                autoComplete="current-password"
                sx={{ input: { color: "white" } }}
                value={paswd}
                onChange={(e) => {
                  setPaswd(e.target.value);
                }}
              />
              <br />
              <CssTextField
                fullWidth
                size="small"
                id="outlined-password-input"
                label="Retype New Password"
                type="text"
                autoComplete="current-password"
                sx={{ input: { color: "white" } }}
                value={cpaswd}
                onChange={(e) => {
                  setCPaswd(e.target.value);
                }}
              />
            </div>
          </Grid>
          <Grid
            item
            xs={12}
            md={12}
            sx={{
              display: "flex",
              justifyContent: "center",
              marginTop: "20px",
            }}
          >
            <button
              className="btns"
              style={{backgroundColor:"#BAFF39",cursor:"pointer"}}
              variant="contained"
              onClick={async () => {
                let rettostmsg1 = "";
                if (paswd.length <= 0) {
                  rettostmsg1 = tostmsg({
                    msg: "Please enter password",
                    code: 100,
                  });
                  setAlertdata(rettostmsg1);
                  setAlertopen(rettostmsg1.open);
                  return;
                }
                if (cpaswd.length <= 0) {
                  rettostmsg1 = tostmsg({
                    msg: "Please enter retype password",
                    code: 100,
                  });
                  setAlertdata(rettostmsg1);
                  setAlertopen(rettostmsg1.open);
                  return;
                }
                if (cpaswd !== paswd) {
                  rettostmsg1 = tostmsg({
                    msg: "Password and retype password should be match",
                    code: 100,
                  });
                  setAlertdata(rettostmsg1);
                  setAlertopen(rettostmsg1.open);
                  return;
                }
                const login = await updatepassword(data);
                // alert(JSON.stringify(login))
                rettostmsg1 = tostmsg(login);
                if (login.code == 100) {
                  setAlertdata(rettostmsg1);
                  setAlertopen(rettostmsg1.open);
                }

                if (login.code == 200) {
                  rettostmsg1 = tostmsg({
                    code: 200,
                    msg: "Password updated successfully;please login again",
                  });
                  setAlertdata(rettostmsg1);
                  setAlertopen(rettostmsg1.open);

                  setPaswd("");
                  setCPaswd("");
                  setTimeout(() => {
                    DeleteCart({ uid: logid });
                    sessionStorage.setItem("logid", "");
                    navigate("/");
                  }, 1000);
                  // alert(login.msg);
                }
              }}
            >
              Change Password
            </button>
          </Grid>

          {/* </Paper> */}
        </Grid>
        {/* <Grid
            item
            xs={12}
            md={4}
            sx={{ padding: "10px", borderRight: "2px solid white" }}
          >
           
            <Grid
              item
              container
              xs={12}
              md={12}
              sx={{
                // backgroundColor: "white",
                borderRadius: "8px",

                padding: "10px",
              }}
            >
              <Grid item xs={12} md={12} sx={{}}>
                <div
                  style={{
                    // backgroundColor: "#BAFF39",
                    height: "30px",
                    paddingLeft: "10px",
                    fontSize: 18,
                    color: "#BAFF39",
                  }}
                >
                  My Account
                </div>
              </Grid>
              <Grid
                item
                xs={12}
                md={12}
                sx={{ display: "flex", marginTop: "30px" }}
              >
                <div style={{ color: "white" }}>
                  <ArrowRightIcon />
                </div>
                <div
                  style={{ color: "white", cursor: "pointer" }}
                  onClick={() => {
                  
                    navigate("/orderdetail");
                  }}
                >
                  My Order History/ Reprint Tickets
                </div>
              </Grid>
            </Grid>
            <Grid
              item
              container
              xs={12}
              md={12}
              sx={{
               
                borderRadius: "8px",
                padding: "10px",
                marginTop: "10px",
              }}
            >
              <Grid item xs={12} md={12}>
                <div
                  style={{
                   
                    height: "30px",
                    paddingLeft: "10px",
                    fontSize: 20,
                    color: "#BAFF39",
                  }}
                >
                  Contact with us
                </div>
              </Grid>
              <Grid item xs={12} md={12}>
                <div
                  style={{
                    marginTop: "10px",
                    display: "flex",
                    flexDirection: "column",
                    paddingLeft: "20px",
                    color: "white",
                  }}
                >
                  <span>AceChaser.com</span>
                  <span>Div. Of Ticket Wizard Inc.</span>
                  <span>Summerside, PE</span>
                  <span>Canada</span>
                  <p>(902) 360-2024</p>
                </div>
              </Grid>
            </Grid>
         
          </Grid> */}
      </Grid>
    </div>
  );
};

export default ChangePswComp;
