import { Grid } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import "./Product.css";

import { Link, useNavigate } from "react-router-dom";
import { GetShow } from "../../API/DrawModel";
import { UserContext } from "../../Context/UserContext";

const ProductComp = () => {
  const [draw, setDraw] = useState([]);
  const navigate = useNavigate();
  const { getticket, getticket_dispatch } = useContext(UserContext);

  let tabledata = {};
  const todaydatetime = () => {
    let month = new Date().getMonth() + 1;
    if (month.toString().length <= 1) {
      month = `0${month}`;
    }
    let day = new Date().getDate();
    if (day.toString().length <= 1) {
      day = `0${day}`;
    }
    let hours = new Date().getHours();
    // if (day.toString().length <= 1) {
    //   day = `0${day}`;
    // }
    let minutes = new Date().getMinutes();
    let year = new Date().getFullYear();
    // year = Number(year) - Number(e.target.value);
    let edate = `${year}-${month}-${day} ${hours}:${minutes}`;
    return edate;
  };
  const getData = async () => {
    let customwhr = "";
    customwhr += ` and sale_start <= '${todaydatetime()}'`;
    customwhr += ` and sale_end >= '${todaydatetime()}'`;
    customwhr += ` and isstatus='Posted'`;
    customwhr += " limit 4";
    tabledata = await GetShow({ customwhr: customwhr });
    setDraw(tabledata.data);
  };
  useEffect(() => {
    getData();
  }, []);
  const showdate = (showdate, type) => {
    let ret = "";
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const month_names_short = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    let weekday = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    switch (type) {
      case "mname_full":
        break;
      case "mname_short":
        // let month = new Date(showdate).getMonth() + 1
        let month = new Date(showdate).getMonth();
        if (month.toString().length <= 1) {
          month = `0${month}`;
        }

        ret = month_names_short[month];
        break;
      case "dayname_short":
        let dayname = new Date(showdate).getDay();
        ret = weekday[dayname];
        break;
      case "dayname_full":
        break;
      case "day":
        let day = new Date(showdate).getDate();
        if (day.toString().length <= 1) {
          day = `0${day}`;
        }
        ret = day;
        break;
      case "time":
        // let hours = new Date(showdate).getHours();
        // let minutes = new Date(showdate).getMinutes();
        let newTime = new Date(showdate).toLocaleTimeString("en-US");
        let hour = newTime.split(":")[0];
        let amPm = newTime.split(" ")[1];
        let seconds = newTime.split(":")[2].replace(amPm, "");
        let noSeconds = newTime.replace(":" + seconds, " ");
        if (parseInt(hour) < 9) {
          noSeconds = "0" + noSeconds;
        }
        ret = noSeconds;
        break;
      case "year":
        let year = new Date(showdate).getFullYear();
        ret = year;
        break;

      default:
        break;
    }

    // if (day.toString().length <= 1) {
    //   day = `0${day}`;
    // }

    // setShowtime(`${year}-${month}-${day} ${hours}:${minutes}`);
    return ret;
  };
  return (
    <>
      <Grid container spacing={2} className="mainprodcon forscroll">
        <Grid item md={12} xs={12}>
          <h1>Available Draws</h1>
        </Grid>
        {draw?.map((row) => {
          let drawdet = row.showdetail[0];
          let month_name = showdate(row.show_time, "mname_short");
          let day_name = showdate(row.show_time, "dayname_short");
          let day = showdate(row.show_time, "day");
          let time = showdate(row.show_time, "time");

          // alert(JSON.stringify(drawdet));
          return (
            <Grid item md={3} xs={12}>
              <div className="containerfirst">
                <div className="main" style={{ position: "relative" }}>
                  <img
                    src={row.drawimg}
                    style={{
                      height: "100%",
                      width: "100%",
                      objectFit: "cover",
                    }}
                  ></img>
                  <div className="sub_img first"></div>

                  <div className="sub_con">
                    <span
                      style={{
                        fontWeight: "bold",
                        fontSize: "20px",
                        padding: "10px",
                        color: "#f75b5b",
                      }}
                    >
                      {drawdet.showname}
                    </span>
                    <span
                      style={{
                        fontWeight: "400",
                        fontSize: "16px",
                        paddingLeft: "10px",
                      }}
                    >
                      {/* Evangeline-Chase the Ace,Wellington */}
                      {drawdet.vendrname}-{drawdet.eventname},{" "}
                      {drawdet.venuname}
                    </span>
                  </div>
                  <div
                    className="datecon"
                    style={{
                      fontWeight: "500",
                      color: "white",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginBottom: "5px",
                      height: "35px",
                      width: "90%",
                      borderTopRightRadius: "15px",
                      borderBottomRightRadius: "15px",
                    }}
                  >
                    <span>
                      {month_name.toUpperCase()} {day} {day_name.toUpperCase()}
                    </span>
                    <span style={{ paddingLeft: "15px" }}>{time}</span>
                  </div>
                  {/* <Link
                    to="/cartdetail"
                    style={{
                      textDecoration: 'none',
                      fontWeight: '600',
                      color: 'white',
                    }}
                  > */}
                  <div
                    style={{
                      height: "50px",
                      width: "100%",
                      backgroundColor: "#00a6ff",
                      justifyContent: "end",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      getticket_dispatch({ type: "add", data: row });
                      navigate("/cartdetail");
                    }}
                  >
                    GET TICKETS
                  </div>
                  {/* </Link> */}
                </div>
              </div>
            </Grid>
          );
        })}
      </Grid>
    </>
  );
};

export default ProductComp;
