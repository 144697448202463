import React from "react";

const SignupEmail = (props) => {
  const bgcolor = {
    // backgroundColor:"red",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
  };
  const bgImg = {
    width: "100%",
    height: "40vh",
    "@media (max-width: 767px)": {
      height: "30vh",
    },
  };

  const box = {
    boxShadow: "1px 1px 1px 1px rgba(12,12,12,0.2)",
    borderRadius: "5px",
    padding: "20px",
    marginTop: "10px",
  };

  const titlecolor = {
    color: "black",
  };

  const heading = {
    color: "black",
    fontSize: 26,
  };
  const text = {
    color: "black",
    fontSize: 16,
  };
  const Msg = {
    color: "black",
    fontSize: 20,
    marginTop: "20px",
    display: "flex",
    flexDirection: "column",
  };
  const msg1 = {
    color: "black",
  };

  const fergotdata = {
    heading: "Forgot Password",
    otp: props.otp,
    img: "https://portal.redbacktech.com/Content/images/rb-forgot-password.png",
  };

  const welcomedata = {
    heading: "Welcome to knoxkart",
    otp: props.otp,
    img: "https://wallpaperaccess.com/full/2579694.jpg",
  };

  const data = props.type == "welcome" ? welcomedata : fergotdata;

  //{display:"flex",justifyContent:"center"}
  return (
    <>
      <div style={bgcolor} className="forscroll">
        <div style={box}>
          {/* <div style={{display:"flex",justifyContent:"center",color:"black",alignItems:"center"}}> */}
          <h1 style={titlecolor}>AceChaser.com</h1>
          <p style={{ fontSize: "12px" }}>A division of Ticket Wizard Inc.</p>
          {/* </div> */}
          {/* <div style={{display:"flex",justifyContent:"center",alignItems:"center"}} > */}
          <img src={data.img} style={bgImg}></img>
          {/* </div> */}
          {/* <div style={{display:"flex",justifyContent:"center",paddingTop:"20px",textAlign:"center"}}> */}
          <h2 style={heading}>{data.heading}</h2>
          {/* </div> */}
          <div style={{}}>
            <span style={text}>Please verify your Email</span>
          </div>
          <br />
          {/* <div> */}
          <span style={Msg}>
            <b>Here Is Your One Time Password</b>
          </span>
          <span style={msg1}>to validate your email address</span>
          {/* </div> */}
          {/* <div style={{display:"flex",justifyContent:"center",alignItems:"center",paddingTop:"15px"}}> */}
          <h1>{data.otp}</h1>
          {/* </div> */}
          {/* <div style={{display:"flex",justifyContent:"center",alignItems:"center"}}> */}
          {/* <img src="https://i2.wp.com/marsfallpodcast.com/wp-content/uploads/2017/09/facebook-logo-vector.png" style={{height:"5vh",width:"40px",objectFit:"fill",marginRight:"10px"}}></img>
        <img src="https://storage.googleapis.com/idx-acnt-gs.ihouseprd.com/AR472586/file_manager/excellent-new-instagram-logo-clipart-image.jpg" style={{height:"5vh",width:"40px",objectFit:"fill"}}></img> */}
          {/* </div> */}
        </div>
      </div>
    </>
  );
};

export default SignupEmail;
