import {
  Box,
  Button,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { calcCountAmount, DeleteCart, GetCart } from "../../API/CartModel";
import { UserContext } from "../../Context/UserContext";
import { tostmsg } from "../../MIS/Global";
import AlertMessageComp from "../AlertMessageCom/AlertMessageComp";
import CartGrid from "./CartGrid";
import "../../CSS/bg.css";
import styled from "../../CSS/New.module.css";

const Cartdetail = () => {
  const navigate = useNavigate();
  let genotp =
    Math.floor(Math.random() * (9 * Math.pow(10, 6 - 1))) + Math.pow(10, 6 - 1);
  const {
    getticket,
    getticket_dispatch,
    getorder,
    setorder_dispatch,
    getcheckout,
    setcheckout_dispatch,
    logindata,
    setCartMinutes,
    stopTimer,
  } = useContext(UserContext);
  const [alertdata, setAlertdata] = React.useState({});
  const [alertopen, setAlertopen] = React.useState(false);

  const [province, setProvince] = useState([]);

  const [totsub, setTotsub] = useState("0.00");
  const [totconvfee, setTotconvfee] = useState("0.00");
  const [totgrand, setTotgrand] = useState("0.00");
  const [totdet, setTotdet] = useState();

  let logid = sessionStorage.getItem("logid");
  let tabledata = {};
  const getOData = async () => {
    let customwhr = "";
    tabledata = await GetCart({ uid: logid });
    if (tabledata.code == 300) {
      window.location = "/";
    }
    // alert(JSON.stringify(tabledata.data))
    // if (getticket.length <= 0) {
    //   window.location = "/";
    // }
    let subTot = 0;
    let subConvfee = 0;
    let subGrandTot = 0;
    tabledata.data.forEach((element) => {
      subTot = Number(subTot) + Number(element.orderdata?.subamount);
      subConvfee = Number(subConvfee) + Number(element.orderdata?.convfee);
      subGrandTot = Number(subGrandTot) + Number(element.orderdata?.gtotal);
    });
    // alert(subTot)
    setTotsub(subTot);
    setTotconvfee(subConvfee);
    setTotgrand(subGrandTot);
    setTotdet({
      subTot: subTot,
      subConvfee: subConvfee,
      subGrandTot: subGrandTot,
    });
    setorder_dispatch({
      type: "addArray",
      data: tabledata.data,
    });
  };
  useEffect(() => {
    getOData();
  }, []);

  return (
    <>
      <AlertMessageComp
        type={alertdata.type}
        msg={alertdata.msg}
        vertical={alertdata.vertical}
        horizontal={alertdata.horizontal}
        setOpen={setAlertopen}
        open={alertopen}
      />
      <Grid
        container
        spacing={2}
        sx={{
          display: "flex",
          justifyContent: "center",
          backgroundColor: "#6E6E6E",
        }}
        className="bg1 forscrolls"
      >
        <Grid item xs={12} md={4}>
          <div className={styled.head}>Your Cart</div>
        </Grid>
        <Grid item md={6} xs={12} className="btngcon">
          {/* <div> */}
          <button
            variant="contained"
            className="btns btnml"
            type="button"
            style={{backgroundColor:"#BAFF39",cursor:"pointer"}}
            onClick={() => {
              navigate("/cart");
            }}
          >
            Back To Your Order
          </button>
          {/* </div> */}
        </Grid>
        <Grid item md={1.9} xs={12} className="btngcon">
          <button
            className="btns"
             style={{backgroundColor:"#BAFF39",cursor:"pointer"}}
            onClick={() => {
              setTimeout(() => {
                navigate("/checkout");
              }, 1000);
            }}
          >
            Proceed To Checkout
          </button>
        </Grid>

        <div style={{ width: "100%" }}>
          <hr />
        </div>
        <Grid item md={8} xs={12}>
          <Paper elevation={4}>
            <TableContainer sx={{ maxHeight: 600 }}>
              <CartGrid data={getorder} totdet={totdet}></CartGrid>
            </TableContainer>
          </Paper>
        </Grid>

        <Grid item md={6} xs={12} className="btngcon" mr={-2}>
          {/* <div> */}
          <button
            className="btns"
            style={{backgroundColor:"#BAFF39",cursor:"pointer"}}
            type="button"
            onClick={() => {
              navigate("/cart");
            }}
          >
            Back To Your Order
          </button>
          {/* </div> */}
        </Grid>
        <Grid item md={2} xs={12} className="btngcon" mr={-2}>
          <button            
            className="btns"
            style={{backgroundColor:"#BAFF39",cursor:"pointer"}}
            onClick={() => {
              setTimeout(() => {
                navigate("/checkout");
              }, 1000);
            }}
          >
            Proceed To Checkout
          </button>
        </Grid>
        <Grid
          item
          md={8}
          xs={12}
          sx={{ display: "flex", justifyContent: "end" }}
        >
          <span
            onClick={() => {
              // setCartMinutes(0);
              stopTimer();
              localStorage.setItem("lastmin", 0);
              localStorage.setItem("lastsec", 1);
              localStorage.removeItem("lasttot");
              localStorage.removeItem("lastcnt");
              DeleteCart({ uid: logid });
              calcCountAmount("cls", 0, 0);

              let rettostmsg1 = tostmsg({
                msg: "Your order is empty",
                code: 200,
              });
              setAlertdata(rettostmsg1);
              setAlertopen(rettostmsg1.open);
              setTimeout(() => {
                navigate("/allproduct");
              }, 1000);
            }}
            style={{ color: "white", cursor: "pointer", marginBottom: "20px" }}
          >
            <u>Clear Order</u>
          </span>
        </Grid>
      </Grid>
    </>
  );
};

export default Cartdetail;
