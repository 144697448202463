import { Grid, InputAdornment } from "@mui/material";
import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import img from "../../Assets/card.jpg";
import { Padding } from "@mui/icons-material";
import "../Navbar/Navbar.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import CheckoutPage from "../CheckoutPage/CheckoutPage";
import { alphanumeric, tostmsg } from "../../MIS/Global";
import { GetUserLogData, GetUserLogin } from "../../API/LoginModel";
import AlertMessageComp from "../AlertMessageCom/AlertMessageComp";
import { useContext } from "react";
import { UserContext } from "../../Context/UserContext";
import SignUp from "../Registration/SignUp";
import { GetOrder } from "../../API/OrderModel";
import { calcCountAmount, SaveCart } from "../../API/CartModel";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";

const LoginComp = (props) => {
  const [modelOpen, setModelOpen] = React.useState(false);
  const handleModelOpen = () => {
    setModelOpen(true);
  };
  const handleClose = () => setModelOpen(false);

  const [modelOpenTC, setModelOpenTC] = React.useState(false);
  const handleModelOpenTC = () => {
    setModelOpenTC(true);
  };
  const handleCloseTC = () => setModelOpenTC(false);
  const [logid, setLogid] = useState();
  const [uname, setUname] = useState("");
  const [mob, setMob] = useState("");
  const [emailid, setEmailid] = useState("");
  const [paswd, setPaswd] = useState("");

  const [role, setRole] = useState("user");
  const [isblock, setIsblock] = useState(0);

  const [alertdata, setAlertdata] = React.useState({});
  const [alertopen, setAlertopen] = React.useState(false);
  const [showpaswd, setShowpaswd] = React.useState(false);

  const {
    logindata,
    setlogindata_dispatch,
    cartcount_flag,
    setCartcount_flag,
    cartminutes,
    setCartMinutes,
    startTimer,
  } = useContext(UserContext);
  const navigate = useNavigate();
  const location = useLocation();

  let data = {
    emailid: emailid,
    paswd: paswd,
  };

  return (
    <>
      <AlertMessageComp
        type={alertdata.type}
        msg={alertdata.msg}
        vertical={alertdata.vertical}
        horizontal={alertdata.horizontal}
        setOpen={setAlertopen}
        open={alertopen}
      />
      <Grid
        container
        spacing={2}
        sx={{ padding: "10px", backgroundColor: "white" }}
      >
        <Grid
          item
          className="logcon"
          xs={12}
          md={4.5}
          sx={{
            backgroundColor: "#383838",
            position: "relative",
          }}
        >
          {" "}
          <h2 style={{ color: "white" }}>Sign In</h2>
          <span style={{ color: "white" }} className="resspan">
            <p>
            You must be 18 years old to claim a cash prize. All tickets must be purchased within the province of the draw to be considered valid.
            </p>
            <p>See  <span
                style={{
                  fontSize: "14px",
                  color: "#2874f0",
                  cursor: "pointer",
                }}
                onClick={handleModelOpenTC}
              >
                <u>Terms and Conditions.</u>
              </span> for full details.</p>
            <p>AceChaser.com is a division of Ticket Wizard Inc.</p>
            <p style={{ fontSize: "12px" }}>
              Supporting local communities since 2016...
            </p>
          </span>
          <div style={{ display: "none" }}>
            <img
              src="https://www.acechaser.com/resources/3495.jpg"
              className="resimg"
            />
          </div>
        </Grid>
        <Grid
          item
          xs={12}
          md={7.5}
          sx={{
            backgroundColor: "#FFF",
          }}
        >
          <Grid item xs={12} md={12} p={2}>
            <TextField
              id="standard-basic"
              label="Enter Email"
              variant="standard"
              fullWidth
              value={emailid}
              inputProps={{ maxLength: 100 }}
              onChange={async (e) => {
                setEmailid(e.target.value);
              }}
            />
          </Grid>
          <Grid item xs={12} md={12} p={2}>
            <TextField
              label="Password"
              type= {showpaswd==false?"password":"text"}
              fullWidth
              variant="standard"
              value={paswd}
              inputProps={{ maxLength: 10 }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start" >
                    <div style={{cursor:"pointer"}} onClick={()=>{setShowpaswd(!showpaswd)}}>
                    {showpaswd==false?<VisibilityOffIcon />:<VisibilityIcon />}
                    </div>

                  </InputAdornment>
                ),
              }}
              onChange={async (e) => {
                setPaswd(e.target.value);
              }}
            />
          </Grid>
          <Grid item xs={12} md={12} p={2}>
            <Link
              to="/"
              sx={{
                textDecoration: "none",
                color: "black",
                float: "right",
                fontWeight: "bold",
              }}
              onClick={() => {
                window.location.href = "/forgotpass";
              }}
            >
              <span>Forgot Password?</span>
            </Link>
          </Grid>

          <Grid item xs={12} md={12} p={2} display="none">
            <p>
              By conyinuning you to TicketWizard{" "}
              <span style={{ color: "blue" }}>Terms of use</span>and
              <span style={{ color: "blue" }}> Privacy Policy.</span>
            </p>
          </Grid>
          <Grid item xs={12} md={12} p={2}>
            <Button
              variant="contained"
              fullWidth
              style={{
                padding: "12px 10px",
                backgroundColor: "#BAFF39",
                fontSize: "15px",
                fontWeight: "600",
                color: "black",
                cursor: "pointer",
                height: "40px",
              }}
              onClick={() => {
                (async () => {
                  try {
                    let curUrl = location.pathname;
                    // alert(curUrl);
                    let rettostmsg1 = "";
                    if (emailid.length <= 0) {
                      rettostmsg1 = tostmsg({
                        msg: "Please enter Email",
                        code: 100,
                      });
                      setAlertdata(rettostmsg1);
                      setAlertopen(rettostmsg1.open);
                      return;
                    }
                    const regex =
                      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
                    if (regex.test(emailid) === false) {
                      rettostmsg1 = tostmsg({
                        msg: "Please enter a valid email",
                        code: 100,
                      });
                      setAlertdata(rettostmsg1);
                      setAlertopen(rettostmsg1.open);
                      return;
                    }
                    if (paswd.length <= 0) {
                      rettostmsg1 = tostmsg({
                        msg: "Please enter valid password",
                        code: 100,
                      });
                      setAlertdata(rettostmsg1);
                      setAlertopen(rettostmsg1.open);
                      return;
                    }
                    const userlogin = await GetUserLogData(data);
                    // alert(JSON.stringify(userlogin))
                    let rettostmsg = tostmsg(userlogin);
                    if (userlogin.msg == "Data not found") {
                      rettostmsg = tostmsg({
                        msg: "Invalid Emailid/Password",
                        code: 100,
                      });
                    }
                    if (userlogin.code == 200) {
                      rettostmsg = tostmsg({
                        msg: "Login Successfully",
                        code: 200,
                      });
                    }
                    setAlertdata(rettostmsg);
                    setAlertopen(rettostmsg.open);
                    if (userlogin.code == 100) {
                    }
                    if (userlogin.code == 200) {
                      // alert(login.msg);
                      let customwhr = "";
                      customwhr += ` and uid = '${userlogin.data[0].logid}'`;
                      customwhr += " order by edate desc";
                      // alert(customwhr)
                      let tabledata = await GetOrder({ customwhr: customwhr });
                      setTimeout(() => {
                        setlogindata_dispatch({
                          type: "add",
                          data: userlogin.data[0],
                        });
                        sessionStorage.setItem(
                          "logid",
                          userlogin.data[0].logid
                        );
                        localStorage.setItem("lemail",userlogin.data[0].emailid)
                        localStorage.setItem("logid",userlogin.data[0].logid)
                        if (curUrl == "/cart") {
                          //
                          if (props?.logincart) {
                            let odata = {
                              ...props?.logincart,
                              uid: userlogin.data[0].logid,
                            };

                            // alert(JSON.stringify(odata));
                            calcCountAmount(
                              "add",
                              odata?.orderdata?.qty,
                              odata?.orderdata?.subamount
                            );
                            startTimer();
                            // (async () => {
                            let orderData = SaveCart(odata);
                            setCartcount_flag(!cartcount_flag);

                            if (cartminutes <= 0) {
                              setCartMinutes(10);
                              localStorage.setItem("lastcnt", 0);
                            }

                            // });
                          }
                          props.handleClose(true);

                          // alert();
                          // navigate("/cart");

                          // window.location.reload();
                        } else {
                          // alert(JSON.stringify(tabledata))
                          if (tabledata.code == 300) {
                            props.handleClose(true);
                            navigate("/allproduct");
                          } else {
                            // navigate('/myorderhistory')
                            props.handleClose(true);
                            navigate("/allproduct");
                          }
                        }
                      });
                    }
                  } catch (e) {}
                })();
              }}
            >
              Sign In
            </Button>
          </Grid>
          <Grid item xs={12} md={12} p={2}>
            <span
              onClick={() => {
                handleModelOpen();
              }}
              style={{
                textDecoration: "none",
                cursor: "pointer",
                display: "none",
              }}
            >
              New to Acechaser.com? Create an account
            </span>
          </Grid>
        </Grid>
      </Grid>
      <Modal
        open={modelOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="modelbox">
          <SignUp />
          {/* <Typography id="modal-modal-description">
            <CheckoutPage />
          </Typography> */}
        </Box>
      </Modal>

      <Modal
        open={modelOpenTC}
        onClose={handleCloseTC}        
      >
        <Box className="smodelbox">
       

          <Typography id="modal-modal-description">
            
            <Grid container spacing={2} handleClose={handleCloseTC}>
              <Grid item xs={12} md={12}>
                <h2 style={{ color: "#BAFF39", textAlign: "center" }}>
                 Terms and Conditions{" "}
                </h2>
                <Grid item xs={12} md={12}>
                  <div style={{ marginLeft: "20px", color: "white" }}>
                    {" "}
                    All tickets must be purchased within the draw committee's province of Prince
                    Edward Island. When you purchase a ticket your IP address is
                    recorded to be provided to the draw committee. In order to
                    claim a cash prize, the winning ticket IP address will be
                    validated.
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Typography>
        </Box>
      </Modal>
    </>
  );
};

export default LoginComp;
