import React from "react";
import NavbarComp from "../../Component/Navbar/NavbarComp";
import FooterComp from "../../Component/Footer/FooterComp";
import ContactusComp from "../../Component/ContactUs/ContactusComp";

const ContactScreen = () => {
  return (
    <div>
      <div className="navbar">
        <NavbarComp />
      </div>
      <div className="comp">
        <ContactusComp />
      </div>
      <div className="footer">
        <FooterComp />
      </div>
    </div>
  );
};

export default ContactScreen;
