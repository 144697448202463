  
export const TimerReducer=(action)=> {
    switch (action.type) {
      case 'add': {
        return [
          action.data,
        ];
      }
      case 'clear': {
        return [];
      }      
      default: {
        throw Error('Unknown action: ' + action.type);
      }
    }
  }
   