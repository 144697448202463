import React, { useContext, useEffect, useState } from "react";
import { Badge, Grid, Tooltip } from "@mui/material";
import Modal from "@mui/material/Modal";
import LoginComp from "../Login/LoginComp";
import SignUp from "../Registration/SignUp";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { Link, useNavigate } from "react-router-dom";
import { UserContext } from "../../Context/UserContext";
import { GetUserLogin } from "../../API/LoginModel";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import {
  calcCountAmount,
  DeleteCart,
  GetCart,
  GetCart_Timer,
} from "../../API/CartModel";
import { tostmsg } from "../../MIS/Global";
import AlertMessageComp from "../AlertMessageCom/AlertMessageComp";
import "../Navbar/Navbar.css";
import { useBeforeunload } from 'react-beforeunload';


const NavbarComp = (props) => {


  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const {
    logindata,
    setlogindata_dispatch,
    paybtn,
    setPaybtn,
    cartcount_flag,
    setCartcount_flag,
    timeLeft,
    setTimeLeft,
    cartminutes,
    setCartMinutes,
    cartseconds,
    setCartSeconds,
    minutes,
    setMinutes,
    seconds,
    setSeconds,
    stopTimer,
    isRunning,
    setIsRunning,
  } = useContext(UserContext);
  const [timer, setTimer] = useState();
  const [cartcount, setCartCount] = useState(0);
  const [totamount, setTotamount] = useState(0);

  const navigate = useNavigate();

  let logid = sessionStorage.getItem("logid");
  let tabledata = {};
  const getOData = async () => {
    let lasttot = localStorage.getItem("lasttot");
    let lastcnt = localStorage.getItem("lastcnt");
    if (lastcnt != null) {
      setCartCount(lastcnt);
    }
    if (lasttot != null) {
      setTotamount(lasttot);
    }
    // alert(cartcount_flag);
    if (logid != "") {
      let customwhr = "";
      if (cartcount_flag == true) {
        // alert(cartcount_flag);
        tabledata = await GetCart_Timer({ uid: logid });
        if (tabledata.code == 300) {
          // setPaybtn("none");
          localStorage.removeItem("lastmin");
          localStorage.removeItem("lastsec");

          localStorage.removeItem("lasttot");
          localStorage.removeItem("lastcnt");
          if (paybtn == "") {
            setPaybtn("none");
          }
          setCartCount(0);
          setTimer("");
        } else {
          if (paybtn == "none") {
            setPaybtn("");
          }

          let subTot = 0;
          let subConvfee = 0;
          let subGrandTot = 0;
          // tabledata.data.forEach((element) => {
          //   subTot = Number(subTot) + Number(element.orderdata?.subamount);
          //   subConvfee =
          //     Number(subConvfee) + Number(element.orderdata?.convfee);
          //   subGrandTot =
          //     Number(subGrandTot) + Number(element.orderdata?.gtotal);
          // });
          setTotamount(subTot);
          setCartCount(tabledata.data.length);
          localStorage.setItem("lasttot", subTot);
          localStorage.setItem("lastcnt", tabledata.data.length);
          var Christmas = new Date(tabledata.data[0].edate);

          setCartcount_flag(false);
        }
      }
    }
  };

  useEffect(() => {
    getOData();
  }, [cartcount_flag]);

  useEffect(() => {
    calcCountAmount("new", 0, 0);
  }, []);
  useEffect(() => {
    let lastcnt = localStorage.getItem("lastcnt");
    if (lastcnt == null || lastcnt == undefined) {
      // alert(lastcnt)
      localStorage.removeItem("lastmin");
      localStorage.removeItem("lastsec");
      // localStorage.setItem("cart_count","0") ;
      let ms = `Reservation expires in ${0}:${0} minutes`;
      setTimer(ms);
      return;
    }
    const countdown = setInterval(() => {
      if (cartseconds === 0) {
        if (cartminutes === 0) {
          clearInterval(countdown);
        } else {
          setCartMinutes((prevMinutes) => prevMinutes - 1);
          setCartSeconds(59);
          localStorage.setItem("lastmin", cartminutes);
          localStorage.setItem("lastsec", cartseconds);
        }
      } else {
        setCartSeconds((prevSeconds) => prevSeconds - 1);
        localStorage.setItem("lastsec", cartseconds);
      }
    }, 1000);

    return () => {
      let ms = `Reservation expires in ${cartminutes}:${cartseconds} minutes`;
      if (cartseconds === 1 && cartminutes <= 0) {
        // clearInterval(countdown);
        localStorage.removeItem("lastmin");
        localStorage.removeItem("lastsec");
        localStorage.removeItem("lasttot");
        localStorage.removeItem("lastcnt");
        localStorage.setItem("cart_count","0") ;
        ms = `Reservation expires in ${0}:${0} minutes`;
        setTimer(ms);
        setTotamount("0.00");
        setCartCount(0);        
        DeleteCart({ uid: logid }); 
      } else {
        setTimer(ms);
      }
      clearInterval(countdown);

      // setCartMinutes(0);
    };
  }, [cartminutes, cartseconds]);
  

  const getData = async () => {
    let customwhr = "";
    // alert(logid);
    if (logid != "" && logid != undefined && logid != null) {
      tabledata = await GetUserLogin({ logid: logid });
      setlogindata_dispatch({
        type: "add",
        data: tabledata.data[0],
      });
    }
  };
  useEffect(() => {
    getData();
  }, []);

  const [language, setLanguage] = useState([]);
  const [modelOpen, setModelOpen] = React.useState(false);
  const [modelOpensignup, setModelOpensignup] = React.useState(false);
  const handleModelOpen = () => {
    // alert(logid)
    if (logid != "" && logid != undefined && logid != null) {
      sessionStorage.setItem("logid", "");
      // window.location.href="/";
      // navigate("/");
      localStorage.removeItem("lastmin");
      localStorage.removeItem("lastsec");

      localStorage.removeItem("lasttot");
      localStorage.removeItem("lastcnt");
      localStorage.removeItem("logid")
      localStorage.setItem("cart_count","0") ;
      calcCountAmount("cls", 0, 0);
      stopTimer();
      navigate("/allproduct");
      DeleteCart({ uid: logid });

      // setModelOpen(true)
    } else {
      setModelOpen(true);
    }
  };
  const handleModelOpensignup = () => setModelOpensignup(true);
  const handleClose = () => setModelOpen(false);
  const handleClosesignup = () => setModelOpensignup(false);

  const drawerWidth = 240;
  const navItems = ["Home", "How it Works", "Active Draws", "Contact Us"];
  const navItemsPath = ["/", "/about", "/allproduct", "/contact"];

  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  // useBeforeunload((event) => {
  //   if (!event.returnValue) {
  //     stopTimer();
  //   }
  // });
  useBeforeunload(() => {
    return stopTimer();;
  });
  
  const drawer = (
    <Box
      onClick={handleDrawerToggle}
      sx={{ textAlign: "center", backgroundColor: "#383838", height: "100vh" }}
    >
      <Link to="/" style={{ textDecoration: "none", display: "none" }}>
        <Typography variant="h6" sx={{ my: 2, color: "#fff" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "fit-content",
            }}
          >
            <span style={{ fontSize: "25px" }}>AceChaser.com</span>
            <div
              style={{
                fontSize: "14px",
                backgroundColor: "#BAFF39",

                color: "black",
                display: "flex",
                justifyContent: "center",
              }}
            >
              A division of Ticket Wizard Inc.
            </div>
          </div>
        </Typography>
      </Link>

      <Divider />
      <List>
        {navItems.map((item, index) => (
          <ListItem key={item} disablePadding>
            <Link to={navItemsPath[index]} style={{ textDecoration: "none" }}>
              <ListItemButton
                sx={{
                  textAlign: "center",
                  textTransform: "capitalize",
                  fontSize: "16px",
                }}
              >
                <ListItemText
                  className="navhover"
                  sx={{ color: "#fff" }}
                  primary={item}
                />
              </ListItemButton>
            </Link>
          </ListItem>
        ))}
        <ListItem disablePadding>
          <Link to="" style={{ textDecoration: "none" }}>
            <ListItemButton
              onClick={handleModelOpensignup}
              className="navhover"
              sx={{
                color: "#fff",
                textTransform: "capitalize",
                fontSize: "16px",
              }}
            >
              Sign Up
            </ListItemButton>
          </Link>
        </ListItem>
        <ListItem disablePadding>
          {/* <Link to="#" style={{ textDecoration: "none" }}> */}
          <ListItemButton
            onClick={() => {
              handleModelOpen();
            }}
            className="navhover"
            sx={{
              color: "#fff",
              textTransform: "capitalize",
              fontSize: "16px",
            }}
          >
            {logid == undefined || logid == "" ? "Sign In" : "Log Out"}
            {/* Sign In */}
          </ListItemButton>
          {/* </Link> */}
        </ListItem>
        <hr style={{ color: "#fff" }} />
        <Box
          sx={{
            display: logid !== null && logid !== "" ? "flex" : "none",
            flexDirection: "column",
            justifyContent: "start",
            alignItems: "flex-start",
            paddingLeft: "8px",
          }}
        >
          <Button
            onClick={() => {
              navigate("/changepassword");
            }}
            className="navhover"
            sx={{
              color: "#fff",
              textTransform: "capitalize",
              fontSize: "16px",
            }}
          >
            Change Password
          </Button>
          <Button
            onClick={() => {
              // navigate("/myorderhistory");
              navigate("/orderdetail");
            }}
            className="navhover"
            sx={{
              color: "#fff",
              textTransform: "capitalize",
              fontSize: "16px",
            }}
          >
            My Order History
          </Button>
          {/* <Button onClick={()=>{navigate("/checkout");}} sx={{ color: "#fff",textTransform:"capitalize",fontSize:"16px" }}>
                  <Badge badgeContent={4} color="secondary">
                    Cart <ShoppingCartIcon sx={{marginLeft:"5px",fontSize:"25px"}} color=""></ShoppingCartIcon>
                  </Badge>
               </Button> */}
        </Box>
      </List>
    </Box>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  const date = [
    {
      label: "Hindi",
    },
    {
      label: "English",
    },
  ];
  const [alertdata, setAlertdata] = React.useState({});
  const [alertopen, setAlertopen] = React.useState(false);
  return (
    <div>
      <AlertMessageComp
        type={alertdata.type}
        msg={alertdata.msg}
        vertical={alertdata.vertical}
        horizontal={alertdata.horizontal}
        setOpen={setAlertopen}
        open={alertopen}
      />
      <Box className="classnav">
        <AppBar
          component="nav"
          sx={{
            backgroundColor: "#383838",
            height: "90px",
            display: "flex",
          }}
        >
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2, display: { sm: "none" } }}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              variant="h6"
              component="div"
              sx={{ flexGrow: 1, display: { xs: "block", sm: "block" } }}
            >
              <Link to="/" style={{ textDecoration: "none", color: "white" }}>
                <div
                  style={{
                    flexDirection: "column",
                    display: "flex",
                    width: "fit-content",
                  }}
                >
                  <span style={{ fontSize: "35px" }}>AceChaser.com</span>
                  <span className="spanhead">
                    A division of Ticket Wizard Inc.
                  </span>
                </div>
              </Link>
            </Typography>
            <Box sx={{ display: { xs: "none", sm: "block" } }}>
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Box>
                  {navItems.map((item, index) => (
                    <Link
                      to={navItemsPath[index]}
                      style={{ textDecoration: "none" }}
                    >
                      <Button
                        key={item}
                        className="navhover"
                        sx={{
                          color: "#fff",
                          textTransform: "capitalize",
                          fontSize: "16px",
                        }}
                      >
                        {item}
                      </Button>
                    </Link>
                  ))}
                  <Link to="" style={{ textDecoration: "none" }}>
                    <Button
                      onClick={handleModelOpensignup}
                      className="navhover"
                      sx={{
                        color: "#fff",
                        textTransform: "capitalize",
                        fontSize: "16px",
                        height: "35px",
                      }}
                    >
                      Sign Up
                    </Button>
                  </Link>
                  {/* <Link to="" style={{ textDecoration: "none" }}> */}
                  
                  <Button
                    onClick={() => {
                      handleModelOpen();
                    }}
                    className="navhover"
                    sx={{
                      color: "#f5f5f5",
                      textTransform: "capitalize",
                      fontSize: "16px",
                      height: "35px",
                    }}
                  >
                    {logid == undefined || logid == "" ? "Sign In" : "Log Out"}
                  </Button>
                  
                  {/* </Link> */}
                </Box>
                <Box
                  sx={{
                    display: logid !== null && logid !== "" ? "flex" : "none",
                    justifyContent: "end",
                  }}
                >
                  <Button
                    onClick={() => {
                      navigate("/changepassword");
                    }}
                    className="navhover"
                    sx={{
                      color: "#fff",
                      textTransform: "capitalize",
                      fontSize: "16px",
                    }}
                  >
                    Change Password
                  </Button>
                  <Button
                    className="navhover"
                    onClick={() => {
                      // navigate("/myorderhistory");
                      navigate("/orderdetail");
                    }}
                    sx={{
                      color: "#fff",
                      textTransform: "capitalize",
                      fontSize: "16px",
                    }}
                  >
                    My Order History
                  </Button>
                </Box>
              </Box>
            </Box>
          </Toolbar>
          <div
     
            className="whitebox"
            style={{
              display: isRunning == true ? "flex" : "none",
              // backgroundColor: "#baff39",
              backgroundColor: "#fff",
              justifyContent: "end",
              alignItems: "center",
            }}
          >
           
            <span style={{ color: "#850000", fontWeight: "500" }}>
              {isRunning == true
                ? `Reservation expires in ${minutes
                    .toString()
                    .padStart(2, "0")}:${seconds
                    .toString()
                    .padStart(2, "0")} minutes`
                : ""}
            </span>
            <Button
              onClick={() => {
                // navigate("/checkout");
                // let count = localStorage.getItem("cart_count");
                // let amount = localStorage.getItem("cart_amount");
                if (Number(localStorage.getItem("cart_amount")) <= 0) {
                  let rettostmsg1 = tostmsg({
                    msg: "Your Cart Is Empty",
                    code: 100,
                  });
                  setAlertdata(rettostmsg1);
                  setAlertopen(rettostmsg1.open);
                  return;
                } else {
                  navigate("/yourorder");
                }
              }}
              sx={{
                color: "#fff",
                textTransform: "capitalize",
                fontSize: "16px",
                Padding: "10px",
              }}
            >
              <Badge
                badgeContent={
                  isRunning == true ? localStorage.getItem("cart_count") : 0
                }
                color="secondary"
              >
              {/* color: "#A555EC" */}
                <ShoppingCartIcon
                  sx={{ fontSize: "35px", color: "#baff39" }}
                 ></ShoppingCartIcon>
              </Badge>
              <span style={{ color: "#2B3467", fontWeight: "700" }}>
                {" "}
                = $
                {isRunning == true
                  ? Number(localStorage.getItem("cart_amount")).toFixed(2)
                  : "0.00"}
              </span>
            </Button>
          </div>
        </AppBar>

        <Box component="nav">
          <Drawer
            container={container}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              display: { xs: "block", sm: "none" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
              },
            }}
          >
            {drawer}
          </Drawer>
        </Box>
      </Box>

      <Modal
        open={modelOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="muicont">
          {/* <Typography id="modal-modal-title" variant="h6" component="h2">
            <CloseIcon onClick={handleClose} style={{ float: "right" }} />
          </Typography> */}

          <Typography id="modal-modal-description">
            <LoginComp handleClose={handleClose} />
          </Typography>
        </Box>
      </Modal>
      <Modal
        open={modelOpensignup}
        onClose={handleClosesignup}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="muicont">
          {/* modelbox */}
          {/* <Typography id="modal-modal-title" variant="h6" component="h2">
            <CloseIcon onClick={handleClose} style={{ float: "right" }} />
          </Typography> */}

          {/* <Typography id="modal-modal-description"> */}
          <SignUp />
          {/* </Typography> */}
        </Box>
      </Modal>
    </div>
  );
};

export default NavbarComp;
