import { BASE_URL } from "../MIS/Global";
import axios from "axios";
import React from "react";
import ReactDOM from "react-dom";
import Loader from "../Component/loader/Loader";

export const SaveLogin = async (data) => {
  // alert(JSON.stringify(data));

  if (data.emailid.length <= 0) {
    return { msg: "Please Enter Email", code: 100 };
  }
  const regex =
    /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
  if (regex.test(data.emailid) === false) {
    return { msg: "Please Enter a valid email", code: 100 };
  }
  if (data.paswd.length <= 0) {
    return { msg: "Please Enter Passwodr", code: 100 };
  }

  ReactDOM.render(<Loader></Loader>, document.getElementById("root1"));

  let url = "usersavelogin";

  let ret = {};
  var config = {
    method: "post",
    url: BASE_URL + url,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };
  await axios(config)
    .then(function (response) {
      ReactDOM.render(
        <Loader bal={false}></Loader>,
        document.getElementById("root1")
      );
      ret = response.data;
      if (ret.code == 200) {
        ret = { msg: ret.msg, code: 200, data: ret.data };
        return ret;
      } else if (ret.code == 400) {
        ret = { msg: ret.msg, code: 400 };
        return ret;
      } else {
        ret = { msg: "something went wrong! please try again1 ", code: 500 };

        return ret;
      }
    })
    .catch(function (error) {
      ReactDOM.render(
        <Loader bal={false}></Loader>,
        document.getElementById("root1")
      );
      ret = { msg: error, code: 500 };

      return ret;
    });

  return ret;
};

export const Sendotp_email = async (data) => {
  // if (data.email.length <= 0) {
  //   return { msg: "Enter valid email id", code: 100 };
  // }
  let ret = {};
  var config = {
    method: "post",
    url: BASE_URL + "signupotp_email",
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };
  ReactDOM.render(<Loader></Loader>, document.getElementById("root1"));

  await axios(config)
    .then(function (response) {
      ReactDOM.render(
        <Loader bal={false}></Loader>,
        document.getElementById("root1")
      );
      ret = response.data;
      if (ret.code == 200) {
        ret = { msg: ret.msg, code: 200, data: ret.data };
        return ret;
      } else if (ret.code == 100) {
        ret = { msg: ret.msg, code: 100 };
        return ret;
      } else if (ret.code == 400) {
        ret = { msg: ret.msg, code: 400 };
        return ret;
      } else {
        ret = { msg: "something went wrong! please try again ", code: 500 };
        return ret;
      }
    })
    .catch(function (error) {
      ReactDOM.render(
        <Loader bal={false}></Loader>,
        document.getElementById("root1")
      );
      ret = { msg: "something went wrong! please try again ", code: 500 };
      return ret;
    });
  return ret;
};

export const GetUserLogData = async (data) => {

  let ret = {};
  var config = {
    method: "post",
    url: BASE_URL + "getuserlogin",
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };
  ReactDOM.render(<Loader></Loader>, document.getElementById("root1"));

  await axios(config)
    .then(function (response) {
      ReactDOM.render(
        <Loader bal={false}></Loader>,
        document.getElementById("root1")
      );
      ret = response.data;
      if (ret.code == 200) {
        ret = { msg: ret.msg, code: 200, data: ret.data };
        return ret;
      }else if (ret.code == 300) {
        ret = { msg: ret.msg, code: 300 };
        return ret;
      }
       else if (ret.code == 100) {
        ret = { msg: ret.msg, code: 100 };
        return ret;
      } else {
        ret = { msg: "something went wrong! please try again ", code: 500 };
        return ret;
      }
    })
    .catch(function (error) {
      ReactDOM.render(
        <Loader bal={false}></Loader>,
        document.getElementById("root1")
      );
      ret = { msg: "something went wrong! please try again ", code: 500 };
      return ret;
    });
  return ret;
};

export const updatepassword = async (data) => {
  // alert(JSON.stringify(data))

  let ret = {};
  var config = {
    method: "post",
    url: BASE_URL + "userpswupdate",
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };
  ReactDOM.render(<Loader></Loader>, document.getElementById("root1"));

  await axios(config)
    .then(function (response) {
      ReactDOM.render(
        <Loader bal={false}></Loader>,
        document.getElementById("root1")
      );
      ret = response.data;
      // alert(JSON.stringify(ret))
      if (ret.code == 200) {
        ret = { msg: ret.msg, code: 200, data: ret.data };
        return ret;
      } else if (ret.code == 100) {
        ret = { msg: ret.msg, code: 100 };
        return ret;
      } else if (ret.code == 400) {
        ret = { msg: ret.msg, code: 400 };
        return ret;
      } else {
        ret = { msg: "something went wrong! please try again1 ", code: 500 };
        return ret;
      }
    })
    .catch(function (error) {
      ReactDOM.render(
        <Loader bal={false}></Loader>,
        document.getElementById("root1")
      );
      // alert(error)
      ret = { msg: "something went wrong! please try again2 ", code: 500 };
      return ret;
    });
  return ret;
};

export const GetUserLogin = async (data) => {
  
  let ret = {};
  var config = {
    method: "post",
    url: BASE_URL + "getuserlogin",
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };
  ReactDOM.render(<Loader></Loader>, document.getElementById("root1"));

  await axios(config)
    .then(function (response) {
      ReactDOM.render(
        <Loader bal={false}></Loader>,
        document.getElementById("root1")
      );
      ret = response.data;
      if (ret.code == 200) {
        ret = { msg: ret.msg, code: 200, data: ret.data };
        return ret;
      } else if (ret.code == 100) {
        ret = { msg: ret.msg, code: 100 };
        return ret;
      } else {
        ret = { msg: "something went wrong! please try again ", code: 500 };
        return ret;
      }
    })
    .catch(function (error) {
      ReactDOM.render(
        <Loader bal={false}></Loader>,
        document.getElementById("root1")
      );
      ret = { msg: "something went wrong! please try again ", code: 500 };
      return ret;
    });
  return ret;
};
