import { Button, Grid, styled } from "@mui/material";
import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import TextareaAutosize from "@mui/material/TextareaAutosize";

import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import YouTubeIcon from "@mui/icons-material/YouTube";
import "./Contact.css";
import AlertMessageComp from "../AlertMessageCom/AlertMessageComp";
import { alphanumeric, email_send, tostmsg } from "../../MIS/Global";
import { Enquiry_email, SaveEnquiry } from "../../API/EnquiryModel";
import { useNavigate } from "react-router-dom";
import "../../CSS/bg.css";
import style from "../../CSS/New.module.css";

const CssTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: "white",
  },
  "& label": {
    color: "white",
  },
  "& fontColor": {
    color: "white",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "white",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "white",
    },
    "&:hover fieldset": {
      borderColor: "white",
    },
    "&.Mui-focused fieldset": {
      borderColor: "white",
    },
  },
});

const ContactusComp = () => {
  const [enqid, setEnqid] = useState();
  const [uname, setUname] = useState("");
  const [emailid, setEmailid] = useState("");
  const [subject, setSubject] = useState("");
  const [descrp, setDescrp] = useState("");
  const [edate, setEdate] = useState(new Date());
  const [uid, setUid] = useState(0);
  const [isstatus, setIsstatus] = useState(0);
  const [alertdata, setAlertdata] = React.useState({});
  const [alertopen, setAlertopen] = React.useState(false);

  const navigate = useNavigate();
  let data = {
    enqid: enqid,
    uname: uname,
    emailid: emailid,
    subject: subject,
    descrp: descrp,
    edate: edate,
    uid: uid,
    isstatus: isstatus,
  };
  const clearAll = () => {
    setEnqid();
    setUname("");
    setEmailid("");
    setSubject("");
    setDescrp("");
  };
  const senEmail_enquiry = () => {
    const htmlEmail = `<div style="width: 100%;">
                        <div style="display:flex;flex-direction:column;padding:10px;">
                            <span>Clien Name:${data.uname}</span>
                        </div>
                        <div style="display:flex;flex-direction:column;padding:10px;">
                            <span>Email:${data.emailid}</span>
                        </div>
                        <div style="padding: 10px;">
                            <h4>Subject :- <span>${data.subject}</span> </h4> 
                        </div>
                        <div style="padding-left: 10px;">
                            <p>${data.descrp}</p>
                        </div>
                    </div>`;
    let emaildata = {
      // emailid: "durgaprasadkadam@gmail.com",
      emailid: "gearing.keith@gmail.com",
      // emailid: `"${email_send}"`,
      htmlmsg: `${htmlEmail.toString()}`,
      subject: data.subject.toString(),
    };
    const emailticket = Enquiry_email(emaildata);
  };
  return (
    <>
      <AlertMessageComp
        type={alertdata.type}
        msg={alertdata.msg}
        vertical={alertdata.vertical}
        horizontal={alertdata.horizontal}
        setOpen={setAlertopen}
        open={alertopen}
      />
      <div className="scroll">
        <Grid className="concont " container spacing={0.5}>
          <Grid item xs={12} md={12}>
            <div
              className={style.head}
              style={{ paddingLeft: "10px", paddingTop: "10px" }}
            >
              Contact Us
            </div>
            <hr />
          </Grid>
          <Grid item xs={12} md={6}>
            <div style={{ paddingLeft: "10px" }}>
              <div>
                <CssTextField
                  sx={{ input: { color: "white" } }}
                  size="small"
                  id="custom-css-outlined-input"
                  fullWidth
                  label="Name"
                  value={uname}
                  inputProps={{ maxLength: 100 }}
                  onChange={async (e) => {
                    let retdt = await alphanumeric(e);
                    setUname(retdt);
                  }}
                />
              </div>
              <div style={{ marginTop: "10px" }}>
                <CssTextField
                  sx={{ input: { color: "white" } }}
                  size="small"
                  id="custom-css-outlined-input"
                  fullWidth
                  label="Email"
                  value={emailid}
                  inputProps={{ maxLength: 100 }}
                  onChange={async (e) => {
                    setEmailid(e.target.value);
                  }}
                />
              </div>
              <div style={{ marginTop: "10px" }}>
                <CssTextField
                  sx={{ input: { color: "white" } }}
                  size="small"
                  id="custom-css-outlined-input"
                  fullWidth
                  label="Subject"
                  value={subject}
                  inputProps={{ maxLength: 500 }}
                  onChange={async (e) => {
                    let retdt = await alphanumeric(e);
                    setSubject(retdt);
                  }}
                />
              </div>
              <div style={{ marginTop: "10px" }}>
                <TextareaAutosize
                  aria-label="Message"
                  minRows={3}
                  placeholder="Message"
                  style={{
                    width: "97.7%",
                    backgroundColor: "transparent",
                    color: "white",
                    borderColor: "white",
                    fontSize: "16px",
                    paddingLeft: "10px",
                  }}
                  value={descrp}
                  // inputProps={{ maxLength: 5000 }}
                  onChange={async (e) => {
                    // let retdt = await alphanumeric(e);
                    setDescrp(e.target.value);
                  }}
                />
              </div>
              <div>
                <Button
                  variant="contained"
                  disableElevation
                  fullWidth
                  style={{
                    marginTop: "10px",
                    backgroundColor: "#BAFF39",
                    marginBottom: "20px",
                    color: "black",
                  }}
                  onClick={() => {
                    (async () => {
                      try {
                        const enq = await SaveEnquiry(data);
                        let rettostmsg = tostmsg(enq);
                        // alert(JSON.stringify(user));
                        setAlertdata(rettostmsg);
                        setAlertopen(rettostmsg.open);

                        if (enq.code == 100) {
                        }

                        if (enq.code == 200) {
                          senEmail_enquiry();
                          setTimeout(() => {
                            clearAll();
                            // navigate("/contact");
                          });
                        }
                      } catch (e) {}
                    })();
                  }}
                >
                  Submit
                </Button>
              </div>
            </div>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              display: "flex",
              justifyContent: "center",
              marginTop: "-40px",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <h1 style={{ color: "#BAFF39" }}>Contact Us</h1>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  marginTop: "-20px",
                }}
              >
                <span style={{ color: "white" }}>AceChaser.com</span>
                <span style={{ color: "white" }}>
                  Div. Of Ticket Wizard Inc.
                </span>
                <span style={{ color: "white" }}>Summerside, PE</span>
                <span style={{ color: "white" }}>Canada</span>
              </div>
              <div style={{ margin: "10px", color: "white" }}>
                (902) 360-2024
              </div>
              {/* <div style={{ display: "none" }}>
                <a href="#">Terms Of Service</a>
              </div> */}
            </div>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default ContactusComp;
